import React from "react"
import { createSearchParams, useLocation, useNavigate } from "react-router-dom"
import { routes } from "router"
import { Button } from "reactstrap"
import Calculator from "components/bookings/Calculator"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { omitBy } from "lodash"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { createBooking } from "store/bookings"

const BookingForm = ({ disabled = false, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { loggedIn } = useSelector((store) => store.auth)
  const { role } = useSelector((store) => store.user)
  const { booking } = useSelector((store) => store.bookings)
  const { trip } = useSelector((store) => store.trips)
  const { id: tripId } = trip
  const isGuide = role === "guide_user"
  const preselectedDate = booking.booked_date || location.state?.date || ""

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    {
      ...booking,
      booked_date: preselectedDate
    },
    ["booked_date", "booked_time", "adult_count", "child_count"]
  )
  const isDraft = booking.status === "draft"

  submitCallback(() => {
    const formData = serialize({ booking: omitBy({ ...form }, (value) => !value) })
    if (loggedIn)
      dispatch(createBooking(tripId, formData)).then((booking) => navigate(routes.clientBookingWizardRootPath({ uuid: booking.uuid })))
    else
      navigate({
        pathname: routes.bookingWizardPath(),
        search: createSearchParams({ trip_id: tripId, booking: createSearchParams(form) }).toString()
      })
  })

  if (!tripId) return null

  const classes = ["bg-white rounded p-15 mt-15 vstack flex-fill"]
  if (className) classes.push(className)
  if (isGuide) classes.push("pointer-events-none opacity-50")

  return (
    <form onSubmit={submitHandler} className={classes.join(" ")} disabled={!isDraft}>
      {trip.id && (
        <Calculator
          form={form}
          changeHandler={changeHandler}
          trip={trip}
          disabled={!isDraft}
          current_booked_date={booking.booked_date}
          className="vstack flex-fill"
        />
      )}
      <Button color="primary" className="w-100 mt-20 text-uppercase" disabled={!isChanged || !isDraft}>
        {t("global.book_a_trip")}
      </Button>
      <p className="text-center m-0 mt-3 fs-7">You won’t be charged yet</p>
    </form>
  )
}

export default BookingForm
