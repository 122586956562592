// export const ACTIVITIES = ["fishing", "hunting"]
export const ACTIVITIES = ["fishing"]
export const GUESTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const AVAILABLE_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
export const SKILL_LEVELS = ["beginner", "intermediate", "advanced", "professional"]
export const TRIP_LENGTH = {
  full_day: "1 day",
  half_day: "1/2 day"
}
export const ECOSYSTEMS = {
  fishing: ["fresh_water", "brackish_water", "salt_water"],
  hunting: ["fresh_water", "brackish_water", "salt_water"]
}
export const ACTIVITY_TYPES = {
  fishing: ["lake", "river", "inshore", "nearshore", "offshore"],
  hunting: ["lake", "river", "inshore", "nearshore", "offshore"]
}
export const PRICING_TYPES = ["charge_per_person", "charge_flat_rate", "set_minimum_rate"]

export const TRIP_FILTERS_HELP_ATTRIBUTES = ["lat", "lng", "address", "zoom"]
export const TRIP_FILTERS = [
  ...TRIP_FILTERS_HELP_ATTRIBUTES,
  "activity",
  "guest_count",
  "skill_level_eq",
  "fishing_ecosystem_eq",
  "hunting_ecosystem_eq",
  "price_per_person_gteq",
  "price_per_person_lteq",
  "skill_level_sort",
  "price_per_person_sort"
]
