import React from "react"
import { Button, Col, Container, Label, Row } from "reactstrap"
import { Link, NavLink, useMatch } from "react-router-dom"
import { routes } from "router"
import { toaster } from "components/common/Toast"
import Input from "components/form/Input"
import Logo from "components/common/Logo"

import { useTranslation } from "react-i18next"
import useForm from "hooks/useForm"

import { api } from "store/helpers/api"
import { useDispatch } from "react-redux"

export default function Footer({ dark = false, noPadding = false, fluid = false, className }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTripsPage = useMatch(routes.tripsPath())
  const isBecameAGuidePage = useMatch(routes.becomeAGuidePath())
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({ email: "" })
  const isBgDark = isBecameAGuidePage || dark

  submitCallback(() => {
    dispatch(
      api({
        url: "/newsletter_subscriptions",
        method: "post",
        data: form
      })
    ).then((res) => toaster.success(res.message) && reset())
  })

  const classes = ["mt-auto py-40 pb-sm-80 pt-100 z-1"]
  if (isBgDark) classes.push("bg-dark text-bg-dark")
  else classes.push("bg-white")
  if (className) classes.push(className)
  if (isTripsPage) classes.push("shadow")

  return (
    <footer className={classes.join(" ")}>
      <Container className={noPadding ? "p-0" : ""} fluid={fluid}>
        <Row className="gap-y-40 gx-20 gx-md-60">
          <Col xs={12} sm={{ size: "auto" }} className="me-auto">
            <div className="position-relative" style={{ maxWidth: 200 }}>
              <NavLink
                className="d-inline-block rounded-circle bg-gray-light-hover bg-opacity-25-hover text-current position-absolute bottom-100 start-0 p-2 mx-n2 mt-n2 mb-30"
                to={routes.rootPath()}
                state={{ scrollToTop: true }}
                title="Home"
                size={32}
              >
                <Logo className="rounded-circle" alt="Logo" width={32} height={32} />
              </NavLink>
              <h5 className="h6">{t("footer.title")}</h5>
              <p className="fs-7 mt-10 mb-0">
                <small>{t("footer.desc")}</small>
              </p>
            </div>
          </Col>
          <Col xs={12} sm={{ size: "auto" }}>
            <div className="hstack gap-20 gap-md-60 fs-7 align-items-start w-100 w-sm-auto">
              <div className="flex-grow-1 flex-grow-sm-0">
                <h6 className="fs-7 text-uppercase opacity-50">Company</h6>
                <ul className="vstack gap-3 mt-15 list-unstyled mb-0">
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      Browse Experiences
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      Community
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      Shop
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      DS Location
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex-grow-1 flex-grow-sm-0">
                <h6 className="fs-7 text-uppercase opacity-50">Help</h6>
                <ul className="vstack gap-3 mt-15 list-unstyled mb-0">
                  <li>
                    <Link to={routes.faqPath()} state={{ scrollToTop: true }} className="link text-current">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      Customer Support
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.rootPath()} state={{ scrollToTop: true }} className="link text-current">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <form onSubmit={submitHandler} className="mt-sm-n50 mt-lg-0">
              <h6 className="fs-7 text-uppercase opacity-50">Sign Up For Our Mailing List</h6>
              <Label for="title" className="fs-7 mb-1 mt-15">
                {t("user.email")}
              </Label>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder={t("user.email")}
                value={form.email || ""}
                onChange={changeHandler}
                className={["fs-6", isBgDark ? "text-bg-dark" : ""].join(" ")}
                errorClassName="mb-0"
                withError
              />
              <Button color="primary" className="btn-sm rounded-pill px-25 mt-10" disabled={!isChanged}>
                Sign Up
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
