import React from "react"
import { RouterProvider } from "react-router-dom"
import { router } from "router"
import Toast from "components/common/Toast"
import Lightbox from "components/common/Lightbox"
import Gradient from "components/Gradient.js"
import ConfirmModal from "modules/modals/ConfirmModal"
import LoadingContainer from "modules/loader-watchers/LoadingContainer"

import { useBreakpoints } from "modules/window/hooks/useBreakpoints"
import { useDisablePinchZoom } from "hooks/useDisablePinchZoom"
import useAssume from "hooks/useAssume"

import "stylesheets/bootstrap.scss"
import "stylesheets/application.scss"
import "react-lazy-load-image-component/src/effects/blur.css"

function App() {
  useAssume()
  useBreakpoints()
  useDisablePinchZoom()

  return (
    <>
      <Gradient />
      <Toast />
      <Lightbox />
      <ConfirmModal />
      <LoadingContainer spinnerProps={{ position: "fixed" }}>
        <RouterProvider router={router} />
      </LoadingContainer>
    </>
  )
}

export default App
