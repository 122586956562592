import React from "react"
import { Col, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import PhotoSelector from "components/form/PhotoSelector"
import ActionButtons from "components/wizards/ActionButtons"

import useForm, { cleanNestedAttributes } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateGuide } from "store/user"

const Step3Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "user.guide" })
  const { guide } = useSelector((store) => store.user)
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(guide, ["photos"])

  submitCallback(({ nativeEvent }) => {
    const photos_attributes = cleanNestedAttributes(form.photos, ["file", "file_type"])
    const guideAttributes = { ...updateStep(form), photos_attributes }

    const nextPath = nativeEvent?.submitter?.value
    const formData = serialize({ guide: guideAttributes }, { indices: true })
    dispatch(updateGuide(guide.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <Row tag="form" onSubmit={submitHandler}>
      <Col xs={12} sm={{ size: 10, offset: 1 }}>
        <PhotoSelector form={form} changeHandler={changeHandler} max={6} />
        <ActionButtons withSkip isChanged={isChanged} action={updateGuide} name="user.guide" className="mt-50" />
      </Col>
    </Row>
  )
}

export default Step3Form
