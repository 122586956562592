export const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm"
export const DATE_FORMAT = "YYYY-MM-DD"
export const TIME_FORMAT = "HH:mm"
export const WEEK_FORMAT = "YYYY-[W]W-[E]E"
export const MONTH_FORMAT = "YYYY-MM"
export const YEAR_FORMAT = "YYYY"

export const PICKER_FORMATS = {
  "datetime-local": DATETIME_FORMAT,
  date: DATE_FORMAT,
  time: TIME_FORMAT,
  week: WEEK_FORMAT,
  month: MONTH_FORMAT,
  year: YEAR_FORMAT
}

export const WEEK_DAY_NUMBERS = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0
}
