import React, { useCallback, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Trip from "components/trips/Trip"
import Spinner from "components/common/Spinner"

import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTrips, getClientGuideTrips } from "store/trips"

export default function Trips() {
  const dispatch = useDispatch()
  const { guide } = useSelector((state) => state.guides)
  const { items, loading, metadata } = useSelector((state) => state.trips)
  const hasTrips = !!items.length
  useTitle(`Trips | ${guide.display_name}`)

  const fetchTrips = useCallback((nextPage = false) => dispatch(getClientGuideTrips(guide.id, nextPage)), [guide.id, dispatch])

  useEffect(() => {
    fetchTrips()
    return () => dispatch(cleanTrips())
  }, [dispatch, fetchTrips])

  return (
    <Container className="py-40">
      {hasTrips ? (
        <Row className="gap-y-20 gap-y-lg-30 gx-30">
          {items.map((trip) => (
            <Col xs={12} lg={4} xl={3} key={trip.id}>
              <Trip trip={trip} className="h-100" />
            </Col>
          ))}
        </Row>
      ) : (
        !loading && (
          <div className="bg-white p-20 rounded">
            <h2 className="h3">No trips at the moment</h2>
          </div>
        )
      )}
      {loading && <Spinner className="mt-40" />}
      {!isLastPage(metadata) && !loading && hasTrips && <Waypoint onEnter={() => fetchTrips(true)} />}
    </Container>
  )
}
