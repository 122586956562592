import React, { memo, useMemo } from "react"
import { Label, TabPane } from "reactstrap"
import Input from "components/form/Input"
import CustomDatepicker from "components/form/CustomDatepicker"

import { useTranslation } from "react-i18next"
import { filter, map, pull } from "lodash"

import { useSelector } from "react-redux"

const Date = ({ form, changeHandler }) => {
  const { t } = useTranslation()
  const { unavailable_days } = useSelector((state) => state.dashboard)
  const unavailableDates = useMemo(() => filter(unavailable_days, ["unavailable_type", "date"]), [unavailable_days])
  const disabledDates = useMemo(
    () => pull(map(unavailableDates, "unavailable_date"), form.unavailable_date),
    [unavailableDates, form.unavailable_date]
  )

  return (
    <TabPane tabId="date">
      <Label className="fs-6">{t("unavailable_day.labels.date")}</Label>
      <div className="mb-20">
        <CustomDatepicker
          type="date"
          name="unavailable_date"
          value={form.unavailable_date}
          onChange={changeHandler}
          labelClassName="fs-6 fw-normal text-dark text-opacity-50 text-uppercase"
          inputClassName="fs-6"
          noIcon
          disabledDates={disabledDates}
        />
      </div>
      <Label check className="hstack gap-10 fs-7 fw-normal">
        <Input
          type="checkbox"
          name="recurring_yearly"
          checked={[true, "true"].includes(form.recurring_yearly)}
          onChange={changeHandler}
          className="mt-0"
        />
        <span className=
        {`text-dark ${[true, "true"].includes(form.recurring_yearly) ? "" : "text-opacity-50"}`}
        >{t(`unavailable_day.labels.recurring_yearly`)}</span>
      </Label>
    </TabPane>
  )
}

export default memo(Date)
