import { createAction, createReducer } from "@reduxjs/toolkit"
import { toaster } from "components/common/Toast"
import { omit } from "lodash"
import i18n from "../../i18n"

// ACTIONS
const reseted = createAction("errors/reseted")
const recived = createAction("errors/received")

// REDUCER
const initialState = {
  fields: {},
  status: null,
  message: ""
}

const errorsReducer = createReducer(initialState, {
  [reseted.type]: () => initialState,
  [recived.type]: (state, { payload, props }) => {
    const showToast = payload.showToast ?? props.showToast ?? true
    const showDetailedError = payload.showDetailedError ?? props.showDetailedError ?? false
    const fields = payload.data?.message || {}
    const hasFields = Object.keys(fields).length

    Object.assign(state, { ...omit(payload, "data"), fields })

    if (hasFields) {
      const baseError = (state.fields.base || []).join(", ")
      const otherFields = omit(state.fields, "base")
      const otherFieldsWithoutBody = omit(state.fields, "body")

      const hasOtherFields = Object.keys(otherFieldsWithoutBody).length
      const detailedError = Object.entries(otherFieldsWithoutBody)
        .map(([field, text]) => `${field}: ${text instanceof Array ? text.join(" | ") : text}`)
        .join(", <br/>")

      if (baseError && showToast) toaster.error({ title: baseError })
      if (hasOtherFields && showToast)
        toaster.error({
          title: otherFields?.body?.toString() || i18n.t("errors.not_valid"),
          ...(showDetailedError && otherFieldsWithoutBody && { text: detailedError })
        })
    } else if (showToast)
      toaster.error({
        title: payload.statusText,
        text: `${payload.method}:${payload.url}<br/>${payload.message}`
      })
  }
})

export default errorsReducer

export { recived as receiveErrors, reseted as resetErrors }
