import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { routes } from "router"
import { Button } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"
import ImagePlaceholder from "components/common/ImagePlaceholder"

import { LazyLoadImage } from "react-lazy-load-image-component"
import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { deletePost, publishPostToggle } from "store/posts"

const PhotoWithDefault = ({ photo }) => {
  const url = photo?.url || photo?.file_preview
  return url ? (
    <LazyLoadImage
      src={url}
      alt=""
      className="object-fit-cover w-100 h-100 rounded-1"
      wrapperClassName="w-100 h-100"
      effect="blur"
      placeholderSrc={photo.placeholder_url || null}
    />
  ) : (
    <ImagePlaceholder className="h-100 rounded-1" label={false} />
  )
}

const Post = ({ post, edit = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const { role } = useSelector((state) => state.user)
  const { trips_collection } = useSelector((state) => state.posts.metadata)
  const trip = useMemo(
    () => post?.trip || trips_collection?.find(({ id }) => id === +post.trip_id),
    [post.trip, post.trip_id, trips_collection]
  )
  const photos = useMemo(() => post?.photos?.filter(({ _destroy }) => !_destroy), [post.photos])
  const postStringTags = useMemo(
    () =>
      post.tags
        ?.filter(({ _destroy }) => !_destroy)
        .map(({ title }) => title)
        ?.join(" ") || "",
    [post.tags]
  )
  const isPiblished = post.id && post.status === "published"
  const isGuide = role === "guide_user"

  const publishToggler = ({ target }) => dispatch(publishPostToggle(post.id, JSON.parse(target.value)))
  const deleteHandler = confirmModal(
    {
      title: "Are you sure you want to delete this post?",
      color: "danger",
      submitText: t("global.delete"),
      cancelText: t("global.cancel")
    },
    () => dispatch(deletePost(post.id))
  )

  return (
    <article className="flex-fill bg-white rounded p-20">
      <div className="hstack gap-10 mb-3">
        {isPiblished || edit ? (
          <p className="text-gray-light mb-0 me-auto">{moment(post.published_at).fromNow()}</p>
        ) : (
          isGuide && (
            <Button color="primary" className="me-auto px-3 py-2 fs-7" onClick={publishToggler} value={true}>
              {t("global.publish")}
            </Button>
          )
        )}
        {post.id && !edit && isGuide && (
          <Dropdown>
            <DropdownItem onClick={publishToggler} value={!isPiblished}>
              {t(`global.${isPiblished ? "unpublish" : "publish"}`)}
            </DropdownItem>
            <DropdownItem tag={Link} to={routes.guideMarketingPostEditPath({ id: post.id })}>
              {t("posts.edit")}
            </DropdownItem>
            <DropdownItem className="text-danger" onClick={deleteHandler}>
              {t("posts.delete")}
            </DropdownItem>
          </Dropdown>
        )}
      </div>

      <div className="hstack gap-10 flex-column flex-lg-row">
        <h2 className="h3 fw-medium lh-sm text-start me-auto">{post.title}</h2>
        <div className="hstack gap-2 fw-medium">
          <Icon iconName="Location" className="flex-shrink-0 mb-auto" size={28} block />
          {trip?.address || "—"}
        </div>
      </div>
      <hr className="my-15" />
      <p className="mb-15">{post.content}</p>

      {photos.length > 0 && (
        <div className="grid gap-2 mb-20">
          <div className="g-col-12 g-col-sm-6 g-col-lg-7" style={{ maxHeight: 480 }}>
            <PhotoWithDefault photo={photos.at(0)} />
          </div>
          <div className="g-col-12 g-col-sm-6 g-col-lg-5 grid grid-cols-2 grid-cols-sm-1 grid-rows-4 gap-2" style={{ maxHeight: 480 }}>
            <div className="g-row-4 g-row-sm-2">
              <PhotoWithDefault photo={photos.at(1)} />
            </div>
            <div className="g-row-4 g-row-sm-2">
              <PhotoWithDefault photo={photos.at(2)} />
            </div>
          </div>
        </div>
      )}
      <div className="text-gray-light">{postStringTags}</div>
    </article>
  )
}

export default Post
