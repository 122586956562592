import React, { useCallback } from "react"
import { Link, createSearchParams, useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { routes } from "router"
import PhotoSlider from "../common/PhotoSlider"
import Icon from "components/common/Icon"

import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { deleteTrip, updateTrip } from "store/trips"
import { createBooking } from "store/bookings"
import { createFavoriteTrip, deleteFavouriteTrip } from "store/favourite-trips"
import { isGuideSelector } from "store/user"

import { TRIP_LENGTH } from "constants/trip"

const Trip = ({ trip, className, style, onClose, preview = false, withModal = false, withRating = false, guideView = false, ...rest }) => {
  const { id, title, status, trip_length, price_per_person, min_guests, max_guests, address, photos, favorite_id } = trip
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()
  const loggedIn = useSelector((store) => store.auth.loggedIn)
  const userId = useSelector((state) => state.user.id)
  const isGuide = useSelector(isGuideSelector)

  const publishTripHandler = (event) => {
    if (event.persist) event.persist()
    const id = event?.currentTarget?.value
    if (id) dispatch(updateTrip(id, { trip: { status: "published" } }))
  }
  const bookTripHandler = () => {
    if (loggedIn) dispatch(createBooking(id)).then((booking) => navigate(routes.clientBookingWizardRootPath({ uuid: booking.uuid })))
    else navigate({ pathname: routes.bookingWizardPath(), search: createSearchParams({ trip_id: id }).toString() })
  }

  const toggleFavoriteTrip = useCallback(() => {
    if (withModal && favorite_id) return removeFavoriteTrip(favorite_id)
    if (favorite_id) return dispatch(deleteFavouriteTrip(favorite_id))
    dispatch(createFavoriteTrip(id))
  }, [id, favorite_id]) //eslint-disable-line

  const removeFavoriteTrip = confirmModal({ title: "Are you sure?", color: "danger", submitText: t("global.yes") }, () =>
    dispatch(deleteFavouriteTrip(favorite_id))
  )
  const unpublishTripHandler = confirmModal(
    { title: "Are you sure you want to unpublish this trip?", color: "danger", submitText: t("global.yes") },
    ({ target }) => dispatch(updateTrip(+target.value, { trip: { status: "unpublished" } }))
  )
  const removeTripHandler = confirmModal(
    { title: "Are you sure you want to remove this trip?", color: "danger", submitText: t("global.yes") },
    ({ target }) => dispatch(deleteTrip(+target.value))
  )

  const classes = ["hstack flex-wrap bg-white rounded overflow-hidden shadow-lg position-relative w-100"]
  if (className) classes.push(className)

  const StatusForGuide = ({ status }) => (
    <div className="hstack fs-6 fw-semibold text-white">
      {status === "published" ? (
        <div className="hstack bg-primary rounded-pill p-1 pe-2">
          <Icon iconName="Checked" className="me-1" />
          {t("trip.card.published")}
        </div>
      ) : (
        <div className="hstack bg-gray-light rounded-pill py-1 px-25">{t("trip.card.draft")}</div>
      )}
    </div>
  )

  const UserActions = () => (
    <div className="hstack gap-10">
      {typeof onClose === "function" && (
        <Button color="icon" onClick={onClose} className="p-0 border-0 me-auto position-relative">
          <div
            className="bg-dark bg-opacity-50 rounded-circle position-absolute top-50 start-50 translate-middle"
            style={{ width: 18, height: 18 }}
          />
          <Icon iconName="CloseFill" className="text-white text-opacity-75-hover position-relative z-1" size={20} block />
        </Button>
      )}
      <div className="hstack gap-1">
        {userId && (
          <Button
            color="ghost"
            className={[
              "p-1",
              favorite_id ? "bg-primary bg-opacity-75-hover text-white" : "bg-white bg-opacity-25 bg-opacity-100-hover text-dark"
            ].join(" ")}
            onClick={toggleFavoriteTrip}
          >
            <Icon iconName="Like" className={["flex-shrink-0"].join(" ")} size={18} block />
          </Button>
        )}
        <Button color="ghost" className="bg-white bg-opacity-25 bg-opacity-100-hover p-1" onClick={console.log}>
          <Icon iconName="Share" className="flex-shrink-0" size={18} block />
        </Button>
      </div>
    </div>
  )

  const UserButtons = () => (
    <div className="hstack gap-10 flex-wrap mt-auto">
      {withRating && (
        <div className="mt-auto flex-fill">
          <div className="small text-dark text-opacity-50 mb-1">Top Rated Guide</div>
          <div className="hstack gap-1">
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <Icon key={index} iconName="StarNew" className="text-primary-second" size={18} block />
              ))}
          </div>{" "}
        </div>
      )}
      <div className={`${withRating ? "align-content-end" : ""} justify-content-start hstack flex-fill gap-2 flex-wrap mt-auto`}>
        <div className="hstack gap-1 align-items-end me-auto">
          <span className="fs-3 fw-medium lh-1">${price_per_person}</span>
          <span className="fs-7">
            <small>/person</small>
          </span>
        </div>
        <Button color="primary" className="px-4 py-2 fs-7" onClick={bookTripHandler}>
          {t("global.book")}
        </Button>
      </div>
    </div>
  )

  const GuideButtons = () => (
    <div className="hstack gap-10 flex-wrap flex-row-reverse mt-auto">
      <Button
        color="link"
        className="link-danger link-underline-opacity-0 link-underline-opacity-75-hover p-0 ms-auto fs-7"
        value={id}
        onClick={removeTripHandler}
      >
        <small>{t("global.delete")}</small>
      </Button>
      <div className="hstack gap-10 flex-fil flex-wrap">
        {(status === "unpublished" && (
          <Button color="primary" className="px-5 py-2 fs-7 flex-fill" value={id} onClick={publishTripHandler}>
            {t("global.publish")}
          </Button>
        )) ||
          (status === "published" && (
            <Button color="gray-light" className="px-5 py-2 fs-7 flex-fill text-white" value={id} onClick={unpublishTripHandler}>
              {t("global.unpublish")}
            </Button>
          ))}
        <Button
          tag={Link}
          color="primary-second"
          outline
          className="px-5 py-2 fs-7 flex-fill"
          to={routes.guideTripWizardRootPath({ id: id })}
        >
          {status === "draft" ? t("global.continue") : t("global.edit")}
        </Button>
      </div>
    </div>
  )

  return (
    <div className={classes.join(" ")} style={style} {...rest}>
      <div className="flex-shrink-0 align-self-stretch position-relative" style={{ flexBasis: 200, flexGrow: 1 }}>
        <div className="position-absolute top-0 start-0 w-100 p-3 z-2">
          {isGuide ? <StatusForGuide status={status} /> : <UserActions />}
        </div>
        <PhotoSlider photos={photos} preview className="w-100 h-100" />
      </div>
      <div className={["vstack", preview ? "gap-1 p-3" : "gap-3 p-4"].join(" ")} style={{ flexBasis: 200, flexGrow: 4 }}>
        <Link
          to={guideView ? routes.guideTripPath({ id }) : routes.tripPath({ id })}
          className="link-dark link-underline-opacity-0 text-primary-second-hover"
        >
          <h3 className={["lh-sm", preview ? "h5" : "h4"].join(" ")}>{title || "Draft Trip"}</h3>
        </Link>
        <div className="grid gap-y-2 gap-x-1 mb-auto lh-sm" style={{ gridTemplateColumns: "repeat(auto-fit, minmax(162px, 1fr))" }}>
          <div className="hstack gap-2 align-items-start">
            <Icon iconName="Location" className="flex-shrink-0" width={20} />
            <span className="fs-7">{address || "—"}</span>
          </div>
          {isGuide && (
            <div className="hstack gap-2 align-items-start">
              <Icon iconName="Calendar" className="flex-shrink-0" width={20} />
              <span className="fs-7">{TRIP_LENGTH[trip_length] || "—"}</span>
            </div>
          )}
          <div className="hstack gap-2 align-items-start">
            <Icon iconName="Users" className="flex-shrink-0" width={20} />
            <span className="fs-7">
              {(min_guests === max_guests
                ? t("trip.card.persons", { count: min_guests })
                : t("trip.card.persons_range", { min_guests, max_guests })) || "—"}
            </span>
          </div>
          {isGuide && (
            <div className="hstack gap-2 align-items-start">
              <Icon iconName="Coin" className="flex-shrink-0" width={20} />
              <span className="fs-7">{t("trip.card.starting_price", { price_per_person }) || "—"}</span>
            </div>
          )}
        </div>
        {isGuide ? <GuideButtons /> : <UserButtons />}
      </div>
    </div>
  )
}

export default Trip
