import React, { memo, useMemo, useState } from "react"
import { DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { DropdownMenu } from "components/common/Dropdown"
import Datepicker from "./Datepicker"
import InputError from "components/form/InputError"

import moment from "moment"

import { PICKER_FORMATS } from "constants/date"

const DatepickerDropdownToggle = memo((props) => <DropdownToggle {...props} tag="div" />)

const DatepickerDropdown = ({
  type = "date",
  name,
  value,
  onChange,
  disabled = false,
  children,
  toggleTag,
  toggleProps,
  toggleClassName,
  className,
  ...rest
}) => {
  const format = useMemo(() => (Object.keys(PICKER_FORMATS).includes(type) ? PICKER_FORMATS[type] : PICKER_FORMATS.date), [type])
  const momentValue = useMemo(() => {
    const parsedMomentValue = moment(value, format)
    return parsedMomentValue.isValid() ? parsedMomentValue : false
  }, [value, format])
  const formattedValue = useMemo(() => (momentValue ? momentValue.format(format) : ""), [momentValue, format])

  const [inputValues, setValues] = useState([formattedValue, momentValue])
  const [inputFormattedValue, inputValue] = inputValues
  const displayValue = useMemo(() => formattedValue || inputFormattedValue, [formattedValue, inputFormattedValue])

  const changeHandler = ({ target }) => {
    setValues([target.value, target.date])
    if (typeof onChange === "function") onChange({ target })
  }

  const toggleContent = useMemo(
    () => (typeof children === "function" ? children(displayValue, !!displayValue) : children),
    [children, displayValue]
  )

  const Toggle = () =>
    React.createElement(toggleTag || DropdownToggle, {
      tag: toggleTag && DatepickerDropdownToggle,
      className: toggleClassName,
      children: toggleContent,
      ...toggleProps
    })

  return (
    <UncontrolledDropdown direction="down" disabled={disabled} className={className}>
      <Toggle />
      {!disabled && (
        <DropdownMenu className="max-vw-100 w-max-content px-10">
          <Datepicker
            {...rest}
            type={type}
            name={name}
            value={typeof value !== undefined ? momentValue : inputValue}
            onChange={changeHandler}
          />
        </DropdownMenu>
      )}
      {name && <InputError field={name} />}
    </UncontrolledDropdown>
  )
}

export default memo(DatepickerDropdown)
