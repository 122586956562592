import React, { useEffect, useState, useMemo } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { Button, DropdownToggle, Input } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import { routes } from "router"
import Icon from "components/common/Icon"
import DateSwitcher, { TYPE_FORMATS } from "components/common/DateSwitcher"
import SortButton from "components/common/SortButton"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"

import { STATS_NAMES, RANGE_OPTIONS, DEFAULT_RANGE, DEFAULT_TYPE } from "components/bookings/constants"

export default function Header({ params, onChangeFilters }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [searchBy, setSearchBy] = useState("")
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const {
    loading,
    metadata: { start_date, end_date }
  } = useSelector((state) => state.bookings)
  const formattedStartDate = moment(start_date).format("MMM YYYY")
  const formattedEndDate = moment(end_date).format("MMM YYYY")
  const formattedPresentDate = moment().format("MMM YYYY")
  const date = useMemo(() => moment(params.date), [params.date])

  const sortHandler = (sort) =>
    onChangeFilters({
      target: {
        value: ["asc", "desc"].includes(sort) ? sort : !params.sort || params.sort === "desc" ? "asc" : "desc",
        name: "sort"
      }
    })

  const changeTypeHandler = ({ target }) => onChangeFilters({ target }) || sortHandler(target.value === "upcoming" ? "asc" : "desc")

  const changeDateHandler = (date, start, end, momentDate) =>
    onChangeFilters({
      target: {
        value: momentDate.format(TYPE_FORMATS.day),
        name: "date"
      }
    })

  const onSearchFieldChange = ({ target }) =>
    setSearchBy(target.value) ||
    onChangeFilters({
      target: {
        value: target.value || null,
        name: "search"
      }
    })

  useEffect(() => {
    if (!params.range) onChangeFilters({ target: { value: DEFAULT_RANGE, name: "range" } })
    if (!params.type) changeTypeHandler({ target: { value: DEFAULT_TYPE, name: "type" } })
    // eslint-disable-next-line
  }, [params.range, params.type])

  return (
    <div className="sticky-top mt-n30 mb-30" style={{ top: headerHeight }}>
      <div className="full-window-block bg-white py-10 py-sm-20 shadow">
        <div className="hstack gap-2 justify-content-between align-items-center w-100">
          <Dropdown
            className="my-n1"
            toggleButton={
              <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
                <h1 className="h3 fw-medium lh-1">{STATS_NAMES[params.type]}</h1>
                <Icon iconName="SidebarToggle" size={20} />
              </DropdownToggle>
            }
          >
            {Object.entries(STATS_NAMES).map(([type, label], index) => (
              <DropdownItem key={index} name="type" value={type} onClick={changeTypeHandler} active={params.type === type}>
                {label}
              </DropdownItem>
            ))}
          </Dropdown>

          <div className="hstack gap-2">
            {["all", "past"].includes(params.type) ? (
              <div className={["h3 lh-1 text-dark", loading ? "text-opacity-50" : ""].join(" ")}>
                {start_date ? (formattedStartDate === formattedPresentDate ? "Present" : formattedStartDate) : ""}
                {" - "}
                {end_date ? (formattedEndDate === formattedPresentDate ? "Present" : formattedEndDate) : ""}
              </div>
            ) : (
              <>
                <DateSwitcher
                  value={date}
                  name="date"
                  onChange={changeDateHandler}
                  type={params.range}
                  minDate={params.type === "upcoming" && moment().startOf(params.range === "month" ? params.range : "day")}
                />
                <Dropdown
                  className="my-n1"
                  toggleButton={
                    <DropdownToggle color="primary-second" outline className="hstack gap-2 rounded-pill py-2 px-15">
                      <span className="fs-7 fw-medium lh-1">{RANGE_OPTIONS[params.range]}</span>
                      <Icon iconName="SidebarToggle" size={14} />
                    </DropdownToggle>
                  }
                >
                  {Object.entries(RANGE_OPTIONS).map(([range, label], index) => (
                    <DropdownItem key={index} name="range" value={range} onClick={onChangeFilters} active={params.range === range}>
                      {label}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </>
            )}
            <SortButton color="ghost" className="hstack p-2 my-auto" sort={params.sort || "desc"} onClick={sortHandler} />
          </div>

          <div className="hstack gap-2">
            <Input
              id="search"
              type="text"
              placeholder="Search"
              value={searchBy}
              onChange={onSearchFieldChange}
              className="fs-6 rounded-pill"
              style={{ maxWidth: 230 }}
            />
            <Button color="primary" tag={Link} className="px-20 py-10 fs-7 text-nowrap" to={routes.guideBookingNewPath({}, searchParams)}>
              {t("bookings.add")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
