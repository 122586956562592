import React from "react"
import Step3Form from "components/trips/wizard/Step3Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"
import { Col, Row } from "reactstrap"

export default function Step3() {
  useTitle("Trip Wizard — Step 3")
  const { t } = useTranslation()
  const title = t("trip.wizard.step_3.title")

  return (
    <Row>
      <Col xs={12} xl={{ size: 10, offset: 1 }}>
        <div className="mt-1 mb-35">{title && <h1 className="h3 lh-1 mb-0">{title}</h1>}</div>
        <DefaultErrorBoundary>
          <Step3Form />
        </DefaultErrorBoundary>
      </Col>
    </Row>
  )
}
