import React, { useEffect, useMemo, useState } from "react"
import DateSwitcher from "components/common/DateSwitcher"
import Spinner from "components/common/Spinner"
import CalendarDay from "./CalendarDay"

import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { getClientGuideTrips } from "store/trips"

import { DATE_FORMAT } from "constants/date"

export default function Calendar() {
  const dispatch = useDispatch()
  const { guide } = useSelector((store) => store.guides)
  const { loading } = useSelector((state) => state.trips)

  const today = moment().startOf("day")
  const todayFormatted = today.format(DATE_FORMAT)
  const nextDay = useMemo(() => moment(today).add(1, "days"), [todayFormatted]) //eslint-disable-line
  const minDate = useMemo(() => moment(today).startOf("month"), [todayFormatted]) //eslint-disable-line

  const [monthRange, setMonthRange] = useState(() => [
    moment(today).startOf("month").format(DATE_FORMAT),
    moment(today).endOf("month").format(DATE_FORMAT)
  ])
  const [monthStartDate, monthEndDate] = monthRange

  const calendarStartDate = useMemo(() => moment(monthStartDate).startOf("isoWeek"), [monthStartDate])
  const calendarEndDate = useMemo(() => moment(monthEndDate).endOf("isoWeek"), [monthEndDate])
  const calendarDaysCount = useMemo(() => {
    const startDayNumber = moment(calendarStartDate)
    const endDayNumber = moment(calendarEndDate)
    return endDayNumber.diff(startDayNumber, "days") + 1
  }, [calendarStartDate, calendarEndDate])
  const calendarDays = useMemo(
    () => new Array(calendarDaysCount).fill().map((_, index) => moment(calendarStartDate).add(index, "days")),
    [calendarStartDate, calendarDaysCount]
  )
  const weekDayNames = useMemo(
    () => new Array(7).fill().map((_, index) => moment(calendarStartDate).add(index, "days").format("ddd")),
    [calendarStartDate]
  )

  useEffect(() => {
    const minDate = calendarStartDate > nextDay ? calendarStartDate.format(DATE_FORMAT) : nextDay.format(DATE_FORMAT)
    const maxDate = calendarEndDate.format(DATE_FORMAT)
    const params = {
      "q[with_available_days]": true,
      "q[date_between_or_unseasonal]": [minDate, maxDate]
    }
    if (guide.id) dispatch(getClientGuideTrips(guide.id, null, params))
  }, [guide.id, nextDay, calendarStartDate, calendarEndDate, dispatch])

  const classes = ["bg-white rounded"]
  return (
    <div className={classes.join(" ")}>
      <div className="hstack align-items-stretch py-3 px-15 border-top border-start border-end border-gray-lightest rounded-top">
        <DateSwitcher onChange={(date, ...range) => setMonthRange(range)} type="month" minDate={minDate} />
      </div>
      <div className="grid grid-cols-7 py-1 border border-gray-lightest">
        {weekDayNames.map((dayName) => (
          <span key={dayName} className="fs-7 fw-medium text-center">
            {dayName}
          </span>
        ))}
      </div>
      <div className="overflow-hidden border-start border-end border-bottom border-gray-lightest rounded-bottom">
        <div
          className="grid grid-cols-7 gap-0 position-relative"
          style={{ marginRight: -1, marginBottom: -1, gridTemplateColumns: "repeat(var(--bs-columns, 12), minmax(0,1fr))" }}
        >
          {calendarDays.map((day) => (
            <CalendarDay
              key={day.dayOfYear()}
              day={day}
              today={today}
              thisMonth={moment(monthStartDate) <= day && day <= moment(monthEndDate)}
            />
          ))}
          {loading && <Spinner className="bg-light bg-opacity-75 w-100 h-100" absolute />}
        </div>
      </div>
    </div>
  )
}
