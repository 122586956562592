import React, { useMemo } from "react"
import { Col, FormGroup, InputGroup, InputGroupText, Label, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import Input from "components/form/Input"
import ArrayInput from "components/form/ArrayInput"
import ActionButtons from "components/wizards/ActionButtons"
import InputError from "components/form/InputError"
import CustomDatepicker from "components/form/CustomDatepicker"
import AutofillInput from "components/form/AutofillInput"

import useForm, { useNestedFields } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { map, omit } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { updateTrip } from "store/trips"
import { metadataSelector, modelSelector } from "store/selectors"
import { guideActivityTypeSelector } from "store/user"

import { AVAILABLE_DAYS, SKILL_LEVELS, ECOSYSTEMS, ACTIVITY_TYPES, PRICING_TYPES } from "constants/trip"

const PricingInputsPerPerson = ({ form, changeHandler, type }) => {
  const { t } = useTranslation()
  return (
    form.pricing_type === type && (
      <div>
        <InputGroup>
          <InputGroupText className="fs-6 bg-white">$</InputGroupText>
          <Input
            id="price_per_person"
            type="number"
            name="pricing.price_per_person"
            placeholder={t("trip.labels.price_per_person")}
            value={form.pricing.price_per_person || ""}
            onChange={changeHandler}
            className="fs-6 border-start-0"
          />
        </InputGroup>
        <InputError field="price_per_person" className="mt-1" />
      </div>
    )
  )
}

const PricingInputsFlatRate = ({ form, changeHandler, type }) => {
  const { t } = useTranslation()
  return (
    form.pricing_type === type && (
      <div>
        <InputGroup>
          <InputGroupText className="fs-6 bg-white">$</InputGroupText>
          <Input
            id="flat_rate"
            type="number"
            name="pricing.flat_rate"
            placeholder={t("trip.labels.flat_rate")}
            value={form.pricing.flat_rate || ""}
            onChange={changeHandler}
            className="fs-6 border-start-0"
          />
        </InputGroup>
        <InputError field="flat_rate" className="mt-1" />
      </div>
    )
  )
}

const PricingInputsMinimumRate = ({ form, changeHandler, type }) => {
  const { t } = useTranslation()
  return (
    form.pricing_type === type && (
      <div className="vstack gap-10">
        <div className="hstack gap-15">
          <div className="flex-fill">
            <InputGroup>
              <InputGroupText className="fs-6 bg-white">$</InputGroupText>
              <Input
                id="minimum_rate"
                type="number"
                name="pricing.minimum_rate"
                placeholder={t("trip.labels.minimum_rate")}
                value={form.pricing.minimum_rate || ""}
                onChange={changeHandler}
                className="fs-6 border-start-0"
              />
            </InputGroup>
            <InputError field="minimum_rate" className="mt-1" />
          </div>
          <Input
            id="minimum_rate_person_count"
            type="number"
            name="pricing.minimum_rate_person_count"
            placeholder={t("trip.labels.minimum_rate_person_count")}
            value={form.pricing.minimum_rate_person_count || ""}
            onChange={changeHandler}
            className="fs-6"
            withError
            withWrapper
            wrapperClassName="flex-fill"
          />
        </div>
        <div>
          <InputGroup>
            <InputGroupText className="fs-6 bg-white">$</InputGroupText>
            <Input
              id="rate_per_additional_person"
              type="number"
              name="pricing.rate_per_additional_person"
              placeholder={t("trip.labels.rate_per_additional_person")}
              value={form.pricing.rate_per_additional_person || ""}
              onChange={changeHandler}
              className="fs-6 border-start-0"
            />
          </InputGroup>
          <InputError field="rate_per_additional_person" className="mt-1" />
        </div>
      </div>
    )
  )
}

const PRICING_INPUTS = {
  charge_per_person: PricingInputsPerPerson,
  charge_flat_rate: PricingInputsFlatRate,
  set_minimum_rate: PricingInputsMinimumRate
}

const PricingInputs = ({ form, changeHandler, type }) => PRICING_INPUTS[type]({ form, changeHandler, type })

const Step1Form = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "trips.trip" })
  const activity_type = useSelector(guideActivityTypeSelector)
  const trip = useSelector(modelSelector("trip"))
  const metadata = useSelector(metadataSelector("trips"))
  const bring_items = useMemo(() => trip.bring_items.map((bring_item, index) => ({ _id: index + 1, name: bring_item })), [trip.bring_items])
  const included_items = useMemo(
    () => trip.included_items.map((bring_item, index) => ({ _id: index + 1, name: bring_item })),
    [trip.included_items]
  )
  const target_fish_species = useMemo(
    () => trip.target_fish_species.map((target_fish_species, index) => ({ _id: index + 1, name: target_fish_species })),
    [trip.target_fish_species]
  )
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    {
      ...trip,
      bring_items,
      included_items,
      target_fish_species,
      start_time: trip.start_time || "00:00",
      end_time: trip.end_time || "23:59"
    },
    [
      "activity_type",
      `${activity_type}_activity_type`,
      "title",
      "description",
      "min_guests",
      "max_guests",
      "pricing_type",
      "pricing",
      "cash_payment_allowed",
      "deposit_required",
      "deposit_percentage",
      "seasonal",
      "start_date",
      "start_time",
      "end_time",
      "end_date",
      "recurring_yearly",
      "available_days",
      "trip_length",
      "bring_items",
      "included_items",
      "alcohol_allowed",
      "accessibility_enabled",
      "skill_level",
      "ecosystem",
      `${activity_type}_ecosystem`,
      "target_species",
      "target_fish_species"
    ]
  )

  const [, , addBringHandler, removeBringHandler] = useNestedFields(form, "bring_items", [], changeHandler)
  const [, , addIncludedHandler, removeIncludedHandler] = useNestedFields(form, "included_items", [], changeHandler)
  const [, , addFishSpeciesHandler, removeFishSpeciesHandler] = useNestedFields(form, "target_fish_species", [], changeHandler)

  const currentActivityType = form[`${activity_type}_activity_type`]
  const target_fish_species_list = useMemo(
    () => metadata.fish_species[`${currentActivityType}_fishing`] || [],
    [metadata.fish_species, currentActivityType]
  )
  const target_fish_species_selected = useMemo(() => map(form.target_fish_species, "name"), [form.target_fish_species])

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value
    const bring_items = map(form.bring_items, "name")
    const included_items = map(form.included_items, "name")

    const tripAttributes = {
      ...updateStep(omit(form, "pricing")),
      ...form.pricing,
      bring_items,
      included_items,
      target_fish_species: target_fish_species_selected
    }
    const formData = serialize({ trip: tripAttributes })
    dispatch(updateTrip(trip.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <form onSubmit={submitHandler} className="vstack gap-30">
      <div className="vstack gap-15 bg-white rounded p-20">
        <h2 className="h4">Your Details</h2>
        <Row className="gap-y-15 gx-20">
          <Col xs={12}>
            <Label for="title" className="fs-6">
              {t("trip.labels.title")}*
            </Label>
            <Input
              id="title"
              type="text"
              name="title"
              placeholder={t("trip.labels.title")}
              value={form.title || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </Col>
          <Col xs={12}>
            <Label for="description" className="fs-6">
              {t("trip.labels.description")}*
            </Label>
            <Input
              id="description"
              type="textarea"
              name="description"
              rows={10}
              placeholder={t("trip.labels.description")}
              value={form.description || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </Col>
        </Row>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.guest_details")}</h3>
        <div>
          <Label for="min_guests" className="fs-6">
            {t("trip.labels.number_of_guests")}*
          </Label>
          <div className="hstack gap-10 justify-content-start">
            <Input
              id="min_guests"
              type="number"
              name="min_guests"
              placeholder={t("global.min")}
              value={form.min_guests || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
              withWrapper
              min={1}
              style={{ width: 80 }}
            />
            <Input
              id="max_guests"
              type="number"
              name="max_guests"
              placeholder={t("global.max")}
              value={form.max_guests || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
              withWrapper
              min={1}
              style={{ width: 80 }}
            />
          </div>
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.pricing")}</h3>

        <div className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 gap-y-10 gap-x-20">
          {PRICING_TYPES.map((type) => (
            <div key={type} className="vstack gap-15">
              <Label
                check
                className={`hstack gap-10 p-10 pe-15 bg-white border ${
                  form.pricing_type === type ? "border-gray" : "border-gray-lightest"
                } rounded fs-7 fw-normal`}
              >
                <Input
                  type="radio"
                  name="pricing_type"
                  value={type}
                  checked={form.pricing_type === type}
                  onChange={changeHandler}
                  className="mt-0"
                />
                <span className={`text-dark ${form.pricing_type ? "" : "text-opacity-50"}`}>{t(`trip.pricing_types.${type}`)}</span>
              </Label>
              <PricingInputs type={type} form={form} changeHandler={changeHandler} />
            </div>
          ))}
        </div>

        <div>
          <FormGroup switch className="hstack mb-2 p-0">
            <Label check for="deposit_required" className="fs-6">
              {t("trip.labels.deposit_required")}
            </Label>
            <Input
              id="deposit_required"
              name="deposit_required"
              type="checkbox"
              checked={[true, "true"].includes(form.deposit_required)}
              onChange={changeHandler}
              className="m-0 ms-20 mb-n1"
            />
          </FormGroup>
          {[true, "true"].includes(form.deposit_required) && (
            <div>
              <InputGroup>
                <InputGroupText className="fs-6 bg-white">%</InputGroupText>
                <Input
                  id="deposit_percentage"
                  type="number"
                  name="deposit_percentage"
                  placeholder={t("trip.labels.deposit_percentage")}
                  value={form.deposit_percentage || ""}
                  onChange={changeHandler}
                  className="fs-6 w-auto flex-grow-0 border-start-0"
                />
              </InputGroup>
              <InputError field="deposit_percentage" className="mt-1" />
            </div>
          )}
        </div>

        <FormGroup switch className="hstack mb-0 p-0">
          <Label check for="cash_payment_allowed" className="fs-6">
            {t("trip.labels.cash_payment_allowed")}
          </Label>
          <Input
            id="cash_payment_allowed"
            name="cash_payment_allowed"
            type="checkbox"
            checked={[true, "true"].includes(form.cash_payment_allowed)}
            onChange={changeHandler}
            className="m-0 ms-20 mb-n1"
          />
        </FormGroup>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.dates")}</h3>
        <div>
          <FormGroup switch className="hstack mb-10 p-0">
            <Label check for="seasonal" className="fs-6">
              {t("trip.labels.seasonal")}
            </Label>
            <Input
              id="seasonal"
              name="seasonal"
              type="checkbox"
              checked={form.seasonal}
              onChange={changeHandler}
              className="m-0 ms-20 mb-n1"
            />
          </FormGroup>
          <div className="hstack gap-2 justify-content-start flex-wrap">
            <CustomDatepicker
              type="date"
              name="start_date"
              placeholder={t("global.from")}
              value={form.start_date || ""}
              onChange={changeHandler}
              disabled={!form.seasonal}
              inputClassName="fs-6"
            />
            <span className="d-none d-sm-block"> — </span>
            <CustomDatepicker
              type="date"
              name="end_date"
              placeholder={t("global.to")}
              value={form.end_date || ""}
              onChange={changeHandler}
              disabled={!form.seasonal}
              inputClassName="fs-6"
            />
          </div>
          <Label check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
            <Input
              type="checkbox"
              name="recurring_yearly"
              checked={[true, "true"].includes(form.recurring_yearly)}
              onChange={changeHandler}
              className="mt-0"
              style={{ width: 12, height: 12 }}
              disabled={!form.seasonal}
            />
            <span className={`text-dark ${[true, "true"].includes(form.recurring_yearly) ? "" : "text-opacity-50"}`}>
              {t(`trip.labels.recurring_yearly`)}
            </span>
          </Label>
        </div>
        <div>
          <Label className="fs-6">{t("trip.labels.available_days")}</Label>
          <div className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 gap-y-10 gap-x-20">
            {AVAILABLE_DAYS.map((day) => (
              <Label
                key={day}
                check
                className={`hstack gap-10 p-10 pe-15 bg-white border ${
                  [true, "true"].includes(form.available_days[day]) ? "border-gray" : "border-gray-lightest"
                } rounded fs-7 fw-normal`}
              >
                <Input
                  type="checkbox"
                  name={`available_days.${day}`}
                  checked={[true, "true"].includes(form.available_days[day])}
                  onChange={changeHandler}
                  className="mt-0"
                />
                <span className={`text-dark ${[true, "true"].includes(form.available_days[day]) ? "" : "text-opacity-50"}`}>
                  {t(`trip.days.${day}`)}
                </span>
              </Label>
            ))}
          </div>
        </div>
        <div>
          <Label className="fs-6">{t("trip.labels.available_time")}</Label>
          <div className="hstack gap-2 justify-content-start flex-wrap">
            <CustomDatepicker
              type="time"
              name="start_time"
              placeholder={t("global.from")}
              value={form.start_time || ""}
              onChange={changeHandler}
              inputClassName="fs-6"
              noIcon
              useCurrent
            />
            <span className="d-none d-sm-block"> — </span>
            <CustomDatepicker
              type="time"
              name="end_time"
              placeholder={t("global.to")}
              value={form.end_time || ""}
              onChange={changeHandler}
              inputClassName="fs-6"
              noIcon
            />
          </div>
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.bring_items")}</h3>
        <ArrayInput
          form={form}
          name="bring_items"
          changeHandler={changeHandler}
          addHandler={addBringHandler}
          removeHandler={removeBringHandler}
          placeholder={t("global.add_an_item")}
          className="grid-cols-sm-2 grid-cols-lg-3"
        />
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.included_items")}</h3>
        <ArrayInput
          form={form}
          name="included_items"
          changeHandler={changeHandler}
          addHandler={addIncludedHandler}
          removeHandler={removeIncludedHandler}
          placeholder={t("global.add_an_item")}
          className="grid-cols-sm-2 grid-cols-lg-3"
        />
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.alcohol")}</h3>
        <div>
          <Label className="fs-6">{t("trip.labels.alcohol_allowed")}</Label>
          <div className="hstack jsutify-content-start gap-10">
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                [true, "true"].includes(form.alcohol_allowed) ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="alcohol_allowed"
                value="true"
                checked={[true, "true"].includes(form.alcohol_allowed)}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${[true, "true"].includes(form.alcohol_allowed) ? "" : "text-opacity-50"}`}>
                {t("global.yes")}
              </span>
            </Label>
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                [false, "false"].includes(form.alcohol_allowed) ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="alcohol_allowed"
                value="false"
                checked={[false, "false"].includes(form.alcohol_allowed)}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${[false, "false"].includes(form.alcohol_allowed) ? "" : "text-opacity-50"}`}>
                {t("global.no")}
              </span>
            </Label>
          </div>
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.accessibility")}</h3>
        <div>
          <Label className="fs-6">{t("trip.labels.accessibility_enabled")}</Label>
          <div className="hstack jsutify-content-start gap-10">
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                [true, "true"].includes(form.accessibility_enabled) ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="accessibility_enabled"
                value="true"
                checked={[true, "true"].includes(form.accessibility_enabled)}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${[true, "true"].includes(form.accessibility_enabled) ? "" : "text-opacity-50"}`}>
                {t("global.yes")}
              </span>
            </Label>
            <Label
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                [false, "false"].includes(form.accessibility_enabled) ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="accessibility_enabled"
                value="false"
                checked={[false, "false"].includes(form.accessibility_enabled)}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${[false, "false"].includes(form.accessibility_enabled) ? "" : "text-opacity-50"}`}>
                {t("global.no")}
              </span>
            </Label>
          </div>
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.skill_level")}</h3>
        <div className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 gap-y-10 gap-x-20">
          {SKILL_LEVELS.map((level) => (
            <Label
              key={level}
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form.skill_level === level ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="skill_level"
                value={level}
                checked={form.skill_level === level}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form.skill_level === level ? "" : "text-opacity-50"}`}>{t(`trip.skill_levels.${level}`)}</span>
            </Label>
          ))}
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.ecosystem")}</h3>
        <div className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 gap-y-10 gap-x-20">
          {ECOSYSTEMS[activity_type].map((ecosystem) => (
            <Label
              key={ecosystem}
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form[`${activity_type}_ecosystem`] === ecosystem ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name={`${activity_type}_ecosystem`}
                value={ecosystem}
                checked={form[`${activity_type}_ecosystem`] === ecosystem}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form[`${activity_type}_ecosystem`] === ecosystem ? "" : "text-opacity-50"}`}>
                {t(`trip.ecosystems.${ecosystem}`)}
              </span>
            </Label>
          ))}
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t(`trip.labels.${activity_type}_type`)}</h3>
        <div className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 gap-y-10 gap-x-20">
          {ACTIVITY_TYPES[activity_type].map((type) => (
            <Label
              key={type}
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                currentActivityType === type ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name={`${activity_type}_activity_type`}
                value={type}
                checked={currentActivityType === type}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${currentActivityType === type ? "" : "text-opacity-50"}`}>
                {t(`trip.${activity_type}_activity_types.${type}`)}
              </span>
            </Label>
          ))}
        </div>
      </div>

      <div className="vstack gap-15 bg-white rounded p-20">
        <h3 className="h5">{t("trip.labels.target_fish_species")}</h3>
        <ArrayInput
          ipnutTag={AutofillInput}
          form={form}
          name="target_fish_species"
          placeholder={t("global.add_an_item")}
          changeHandler={changeHandler}
          addHandler={addFishSpeciesHandler}
          removeHandler={removeFishSpeciesHandler}
          className="grid-cols-sm-2 grid-cols-lg-3"
          suggestions={{
            list: target_fish_species_list,
            selected: target_fish_species_selected,
            valueKey: "name",
            withSearch: true
          }}
        />
      </div>

      <ActionButtons isChanged={isChanged} action={updateTrip} name="trips.trip" className="mt-20" />
    </form>
  )
}

export default Step1Form
