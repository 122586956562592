import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"
import { api } from "./helpers/api"

import i18n from "../i18n"
import { toaster } from "components/common/Toast"
import { serialize } from "object-to-formdata"
import { pick } from "lodash"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("user/requested")
const received = createAction("user/received")
const updated = createAction("user/updated")
const statusUpdated = createAction("user/statusUpdated")
const forceStatusUpdated = createAction("user/forceStatusUpdated")
const cleaned = createAction("user/cleaned")
const failed = createAction("user/failed")
const guideUpdated = createAction("user/guideUpdated")
const guideUnavailableWeekdaysUpdated = createAction("user/guideUnavailableWeekdaysUpdated")
const passwordUpdated = createAction("user/passwordUpdated")

const userVerified = createAction("user/verifiedPhone")
const userVerificationError = createAction("user/verificationError")

const initialState = {
  ...LOADING_INITIAL_STATE,
  id: null,
  first_name: "",
  last_name: "",
  email: "",
  avatar: "",
  phone_number: "",
  birthday: "",
  settings: "",
  save_stories_to_profile: false,
  save_stories_to_computer: false,
  hide_stories_from: [],
  codeSent: false,
  forceStatus: false,
  role: "",
  guide: {
    ...LOADING_INITIAL_STATE,
    id: null,
    activity_type: "",
    user: {
      first_name: "",
      last_name: ""
    },
    display_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    website: "",
    description: "",
    street_address: "",
    address_line_2: "",
    state: "",
    country: "",
    city: "",
    zip_code: "",
    expertises: [],
    photos: [],
    upload_client_database: false,
    operating_location: "",
    documents: [],
    wizard_step: null,
    wizard_completed: null,
    unavailable_weekdays: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false
    },
    stripe_account_data: null
  }
}

// REDUCER
const userReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
    if (state.guide) setRequested(state.guide)
  },
  [received.type]: (state, action) => {
    Object.assign(state, action.payload)
    setReceived(state)
    if (state.guide) setReceived(state.guide)
  },
  [updated.type]: (state, action) => {
    Object.assign(state, action.payload)
    setReceived(state)
    if (state.guide) setReceived(state.guide)
  },
  [failed.type]: (state) => {
    setFailed(state)
    setFailed(state.guide)
  },
  [cleaned.type]: () => initialState,
  [passwordUpdated.type]: () => {},
  [guideUpdated.type]: (state, action) => {
    const { first_name, last_name } = action.payload.user
    Object.assign(state, { first_name, last_name })
    Object.assign(state.guide, action.payload)
    setReceived(state)
    setReceived(state.guide)
  },
  [guideUnavailableWeekdaysUpdated.type]: (state, action) => {
    Object.assign(state.guide, action.payload)
    setReceived(state)
    setReceived(state.guide)
  },
  [statusUpdated.type]: (state, action) => {
    Object.assign(state, action.payload)
    setReceived(state)
    setReceived(state.guide)
  },
  [forceStatusUpdated.type]: (state, action) => {
    Object.assign(state, action.payload)
    state.forceStatus = ["offline", "away"].includes(action.payload.status)
    setReceived(state)
    setReceived(state.guide)
  },
  [userVerified.type]: (state, action) => {
    Object.assign(state, action.payload)
    state.codeSent = true
  },
  [userVerificationError.type]: (action) => {
    toaster.error(i18n.t(action.payload.message))
  }
})

export default userReducer

// PUBLIC ACTIONS
export const getUser = () =>
  api({
    url: "/user",
    method: "get",
    onStart: requested.type,
    onSuccess: received.type,
    onError: failed.type,
    params: { include_metadata: true }
  })

export const cleanUser = () => cleaned()

export const updateUser = (data) =>
  api({
    url: "/user",
    method: "put",
    data: data,
    onStart: requested.type,
    onSuccess: updated.type,
    onError: failed.type
  })

export const updateUserStatus =
  (data, force = false) =>
  (dispatch, getState) => {
    const state = getState()
    const { forceStatus } = state.user
    if (forceStatus && !force) return

    dispatch(
      api({
        url: "/user/update_status",
        method: "put",
        data: data,
        onStart: requested.type,
        onSuccess: force ? forceStatusUpdated.type : statusUpdated.type,
        onError: failed.type
      })
    )
  }

export const updateGuide = (guideId, data) =>
  api({
    url: `/guides/${guideId}`,
    method: "put",
    data: data,
    onStart: requested.type,
    onSuccess: guideUpdated.type,
    onError: failed.type
  })

export const updateUnavailableWeekdays = (guideId, data) => {
  const formData = serialize({ guide: pick(data, "unavailable_weekdays") })
  return api({
    url: `/guides/${guideId}`,
    method: "put",
    data: formData,
    onStart: requested.type,
    onSuccess: guideUnavailableWeekdaysUpdated.type,
    onError: failed.type
  })
}

export const updatePassword = (data) =>
  api({
    url: "/user/update_password",
    method: "put",
    data: data,
    onStart: requested.type,
    onSuccess: passwordUpdated.type,
    onError: failed.type
  })

export const verifyPhone = (phoneNumber) =>
  api({
    url: "/user/verify_phone",
    method: "post",
    data: phoneNumber,
    onSuccess: userVerified.type,
    onError: userVerificationError.type
  })

export const verifyCode = (code) =>
  api({
    url: "/user/verify_code",
    method: "post",
    data: code,
    onSuccess: userVerified.type,
    onError: userVerificationError.type
  })

export const stripeConnect = (dispatch) =>
  dispatch(
    api({
      url: "/guide/stripe_accounts/connect",
      method: "post",
      onStart: requested.type,
      onError: failed.type
    })
  ).then((res) => window.location.replace(res.url))

// SELECTORS
export const guideSelector = createSelector(
  (state) => state.user,
  (user) => user.guide
)

export const guideActivityTypeSelector = createSelector(
  (state) => state.user.guide,
  (guide) => guide.activity_type
)

export const isGuideCompletedSelector = createSelector(
  (state) => state.user.guide,
  (guide) => guide.wizard_completed
)

export const isGuideSelector = createSelector(
  (state) => state.user.role,
  (role) => role === "guide_user"
)

export const isUserSelector = createSelector(
  (state) => state.user.role,
  (role) => role === "user"
)

export const stripeConnectedSelector = createSelector(
  (state) => state.user.guide,
  (guide) => guide.stripe_account_data || {}
)
export const isStripeConnectedSelector = createSelector(
  stripeConnectedSelector,
  (stripe_account_data) => !!Object.keys(stripe_account_data).length
)
export const isStripeChargesEnabledSelector = createSelector(
  stripeConnectedSelector,
  (stripe_account_data) => stripe_account_data.charges_enabled
)
export const isStripePayoutsEnabledSelector = createSelector(
  stripeConnectedSelector,
  (stripe_account_data) => stripe_account_data.payouts_enabled
)

export { received as receivedUser, guideUnavailableWeekdaysUpdated }
