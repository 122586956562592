import React from "react"
import Step3Form from "components/bookings/wizard/Step3Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTitle } from "hooks/useTitle"

import { Elements } from "@stripe/react-stripe-js"

const options = { appearance: { theme: "flat" } }

export default function Step3() {
  useTitle("Booking Wizard — Step 3")
  const stripePromise = import("@stripe/stripe-js").then(({ loadStripe }) => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))

  return (
    <DefaultErrorBoundary>
      <Elements stripe={stripePromise} options={options}>
        <Step3Form />
      </Elements>
    </DefaultErrorBoundary>
  )
}
