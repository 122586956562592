import React, { useEffect, useMemo } from "react"
import { Button, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import Input from "components/form/Input"
import PhotoSelector from "components/form/PhotoSelector"
import Spinner from "components/common/Spinner"
import ArrayInput from "components/form/ArrayInput"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { cleanNestedAttributes } from "hooks/useForm"
import useForm, { useNestedFields } from "hooks/useForm"
import { map } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { saveBoat } from "store/boats"
import { routes } from "router"

const Form = ({ onChanged, edit = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { boat } = useSelector((store) => store.boats)

  const isBoatPersisted = !!boat?.id

  const custom_info = useMemo(() => boat.custom_info.map((bring_item, index) => ({ _id: index + 1, name: bring_item })), [boat.custom_info])

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...boat, photos: boat.properties, custom_info }, [
    "name",
    "description",
    "custom_info",
    "photos"
  ])
  const [, , addCustomInfoHandler, removeCustomInfoHandler] = useNestedFields(form, "custom_info", [], changeHandler)

  submitCallback(() => {
    const properties = form.photos.map((file) => ({ ...file, file: file.file }))
    const properties_attributes = cleanNestedAttributes(properties, ["file"])

    const custom_info = map(form.custom_info, "name")

    const boatAttributes = { ...form, custom_info }
    delete boatAttributes.photos

    let formData = serialize({ boat: boatAttributes })
    formData = serialize({ boat: { properties_attributes } }, { indices: true }, formData)

    dispatch(saveBoat(boat.id, formData)).then(() => navigate(routes.guideBoatsPath(), { state: { reload: true } }))
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  return edit && boat.loading ? (
    <Spinner className="flex-fill" />
  ) : (
    <form onSubmit={submitHandler}>
      <div className="vstack gap-20">
        <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
          <h1 className="h3 fw-medium lh-1">{t(`boat.${isBoatPersisted ? "edit" : "add"}`)}</h1>
          <div className="hstack gap-10 my-n1 ms-auto">
            <Button color="primary" className="fs-7" type={"submit"} disabled={!isChanged}>
              {t("global.save")}
            </Button>
          </div>
        </div>
        <div className="vstack gap-30 bg-white rounded p-20 ">
          <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
            <div>
              <Label for="name" className="fs-6">
                {t("boat.name")}
              </Label>
              <Input
                id="name"
                type="text"
                name="name"
                placeholder={t("boat.name_placeholder")}
                value={form.name || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
          </div>
          <div>
            <Label for="description" className="fs-6">
              {t("boat.description")}
            </Label>
            <Input
              id="description"
              type="textarea"
              name="description"
              rows={10}
              placeholder={t("boat.description_placeholder")}
              value={form.description || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label className="fs-6">{t("boat.images")}</Label>
            <PhotoSelector form={form} changeHandler={changeHandler} max={4} small start label={false} />
          </div>
          <div >
            <h3 className="h5 mb-1">{t("boat.custom_info")}</h3>
            <ArrayInput
              form={form}
              name="custom_info"
              changeHandler={changeHandler}
              addHandler={addCustomInfoHandler}
              removeHandler={removeCustomInfoHandler}
              placeholder={""}
              className="grid-cols-sm-2 grid-cols-lg-3"
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default Form
