import React, { useEffect, useState } from "react"
import { Offcanvas } from "react-bootstrap"
import { useMatch, useNavigate } from "react-router-dom"
import { routes } from "router"
import Form from "components/boats/Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanBoat, getGuideBoat } from "store/boats"

export default function BoatWindow() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const isPersisted = useMatch(routes.guideEditBoatPath())
  const boatID = isPersisted?.params?.id
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const [isOpen, setOpen] = useState(false)
  const [isChanged, setChanged] = useState(false)

  const closeHandler = isChanged
    ? confirmModal(
        {
          title: "Are you sure you want to discard all changes?",
          color: "danger",
          submitText: t("global.discard"),
          cancelText: t("global.cancel")
        },
        () => setOpen(false)
      )
    : () => setOpen(false)

  const exitHandler = () => navigate(routes.guideBoatsPath())

  useEffect(() => {
    if (boatID) dispatch(getGuideBoat(boatID))
    return () => dispatch(cleanBoat())
  }, [boatID, dispatch])

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Offcanvas
      placement="end"
      scroll
      show={isOpen}
      onHide={closeHandler}
      onExited={exitHandler}
      container={document.getElementById("boats")}
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      style={{
        top: headerHeight,
        width: 850
      }}
    >
      <Offcanvas.Body className="vstack bg-light p-20 ">
        <DefaultErrorBoundary>
          <Form onChanged={setChanged} edit={!!boatID} />
        </DefaultErrorBoundary>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
