import React, { memo, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import JournalNote from "./JournalNote"
import Booking from "./Booking"
import UnavailableDay from "./UnavailableDay"

import moment from "moment"
import { filter, find } from "lodash"
import { filterUnavailableDates, reduceUnavailableRanges } from "./Day"
import { weatherImage } from "helpers/weather"

import { useSelector } from "react-redux"

import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date"

const CELL_HEIGHT = 60

const bookingPosition = (start_time, end_time, cellHeight = 80) => ({
  top: (moment(start_time, TIME_FORMAT).diff(moment("00:00", TIME_FORMAT), "minutes") / 60) * cellHeight || 0,
  bottom: -(moment(end_time, TIME_FORMAT).diff(moment("24:00", TIME_FORMAT), "minutes") / 60) * cellHeight || 0
})

const DayWithTime = ({ day, today, thisMonth = true, rounded = false, first = false, last = false, top = 30 }) => {
  const [searchParams] = useSearchParams()
  const showWeather = searchParams.get("weather") === "true"

  const formattedDate = day.format(DATE_FORMAT)
  const isToday = today.isSame(formattedDate, "day")
  const isDisabled = day.isBefore(today, "day")
  const {
    bookings,
    journal_notes,
    unavailable_days,
    metadata: { unavailable_weekdays }
  } = useSelector((state) => state.dashboard)
  const forecast = useSelector((state) => find(state.weather.forecast, ["forecast_date", formattedDate])?.forecast_json?.daily) || {}
  const [iconCodeDay, iconCodeNight] = forecast?.daypart?.iconCode || []
  const iconCode = iconCodeDay || iconCodeNight

  const dayBookings = useMemo(() => filter(bookings, ["booked_date", formattedDate]) || {}, [bookings, formattedDate])
  const journalNote = useMemo(() => find(journal_notes, ["date", formattedDate]) || {}, [journal_notes, formattedDate])

  const dayIsUnavailableWeekday = useMemo(() => unavailable_weekdays[day.format("dddd").toLowerCase()], [unavailable_weekdays, day])
  const dayUnavailableDates = useMemo(() => filterUnavailableDates(unavailable_days, day), [unavailable_days, day])
  const dayIsUnavailableDate = !!dayUnavailableDates.length

  const { wholeDayInRange, partialDayRanges } = useMemo(() => reduceUnavailableRanges(unavailable_days, day), [unavailable_days, day])
  const isUnavailableWholeDay = dayIsUnavailableWeekday || dayIsUnavailableDate || wholeDayInRange

  const classes = ["border-gray-lightest show-on-hover"]
  if (!first) classes.push("border-start")
  if (last && rounded) classes.push("rounded-bottom rounded-start-0")
  if (first && rounded) classes.push("rounded-bottom rounded-end-0")
  if (isUnavailableWholeDay) classes.push("bg-light position-relative")
  else if (!thisMonth || isDisabled) classes.push("bg-light")
  else classes.push("bg-primary-second bg-opacity-0 bg-opacity-5-hover")

  const numberClasses = ["fs-7 fw-medium mw-100"]
  if (!thisMonth) numberClasses.push("opacity-50")
  if (showWeather && !iconCode) numberClasses.push("py-2 my-n1")

  return (
    <div className={classes.join(" ")}>
      <div className="hstack justify-content-between border-bottom border-gray-lightest bg-white py-1 px-2 sticky-top">
        <span className={numberClasses.join(" ")}>
          {isToday && <span className="text-primary-second">Today </span>}
          {day.format("ddd, DD")}
        </span>
        {showWeather && iconCode ? (
          <img src={weatherImage(iconCode)} alt="" height="32" className="mx-n20 my-n1" />
        ) : (
          <JournalNote journalNote={journalNote} showOnHover={!journalNote?.id} date={formattedDate} className="my-n1" />
        )}
      </div>
      <div className="grid grid-cols-1 gap-0 position-relative z-1" style={{ gridTemplateRows: `repeat(24, ${CELL_HEIGHT}px)` }}>
        {new Array(24).fill().map((_, index) => (
          <div
            key={index}
            className={[
              index === 23 ? last && "rounded-bottom rounded-start-0" : "border-bottom border-gray-lightest border-opacity-50",
              index === 0 ? first && "rounded-bottom rounded-end-0" : "border-bottom border-gray-lightest border-opacity-50"
            ].join(" ")}
          />
        ))}
        {dayBookings.map((booking) => (
          <div
            key={booking.id}
            className="p-1 position-absolute start-0 end-0 z-2 z-3-hover"
            style={bookingPosition(booking.trip.start_time, booking.trip.end_time, CELL_HEIGHT)}
          >
            <Booking key={booking.id} booking={booking} isDisabled={isDisabled} inTimeline />
          </div>
        ))}
        {partialDayRanges.map((range) => {
          const startDate = moment(range.start_datetime, DATETIME_FORMAT)
          const endDate = moment(range.end_datetime, DATETIME_FORMAT)
          const startDateIsCurrent = startDate.isSame(day, "day")
          const endDateIsCurrent = endDate.isSame(day, "day")
          return (
            <div
              key={range.id}
              className="p-1 position-absolute start-0 end-0 z-1 z-3-hover"
              style={bookingPosition(
                startDateIsCurrent && !range.all_day ? moment(range.start_datetime, DATETIME_FORMAT).format(TIME_FORMAT) : "00:00",
                endDateIsCurrent && !range.all_day ? moment(range.end_datetime, DATETIME_FORMAT).format(TIME_FORMAT) : "24:00",
                CELL_HEIGHT
              )}
            >
              <UnavailableDay
                unavailableDay={range}
                wholeDay={!startDateIsCurrent && !endDateIsCurrent}
                startDateIsCurrent={startDateIsCurrent}
                endDateIsCurrent={endDateIsCurrent}
                showOnHover={isUnavailableWholeDay}
                inTimeline
              />
            </div>
          )
        })}
        {dayUnavailableDates.map((date) => (
          <div
            key={date.id}
            className="p-1 position-absolute start-0 end-0 z-1 z-3-hover"
            style={bookingPosition("00:00", "24:00", CELL_HEIGHT)}
          >
            <UnavailableDay unavailableDay={date} showOnHover={isUnavailableWholeDay} inTimeline />
          </div>
        ))}
      </div>
      {isUnavailableWholeDay && (
        <div
          className="position-absolute w-100 h-100 top-0 start-0"
          style={{ background: "linear-gradient(to top left, rgba(0,0,0,.01) 49.5%, rgba(0,0,0,.2),transparent 50.5%)" }}
        />
      )}
    </div>
  )
}

export default memo(DayWithTime)
