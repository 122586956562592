import React, { forwardRef, useEffect, useMemo, useRef } from "react"
import { Dropdown, DropdownToggle } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"
import Input from "components/form/Input"

import usePredictions from "hooks/usePredictions"

const GooglePlacesInput = (
  {
    tag,
    onInput,
    onChange,
    dropdownClassName,
    wrapperProps = null,
    dropDownMenuClassName,
    dropDownMenuProps = {},
    resetIfChanged = false,
    innerRef,
    name,
    ...rest
  },
  ref
) => {
  const inputRef = useRef(null)
  const [predictions, place, getPredictions, selectPlace] = usePredictions()

  const coordinates = useMemo(() => {
    if (!place) return
    const location = place?.geometry?.location
    if (!location) return
    const lat = location.lat()
    const lng = location.lng()
    return { lat, lng }
  }, [place])

  const handleKeyPress = (event) => event.key === "Enter" && event.preventDefault()
  const changeHandler = (event) => (event.persit && event.persit()) || getPredictions(event.target.value)
  const clickHandler = (placeId) => (event) => {
    if (event.persit) event.persit()
    selectPlace(placeId, (place) => {
      inputRef.current.value = resetIfChanged ? "" : place.formatted_address
    })
  }

  useEffect(() => {
    if (typeof onInput === "function") onInput({ target: { name, value: inputRef.current.value, predictions } })
  }, [predictions, onInput]) //eslint-disable-line

  useEffect(() => {
    if (!coordinates) return
    if (typeof onChange === "function") onChange({ target: { name, value: place.formatted_address, place, coordinates } })
  }, [coordinates?.lat, coordinates?.lng]) //eslint-disable-line

  useEffect(() => {
    if (ref) ref.current = inputRef.current
  }, [ref])

  const Wrapper = wrapperProps ? "div" : React.Fragment
  const PlaceInput = tag || Input

  return (
    <Wrapper {...wrapperProps}>
      <PlaceInput {...rest} type="text" onKeyPress={handleKeyPress} onChange={changeHandler} ref={inputRef} />
      <Dropdown direction="down" isOpen={!!predictions?.length} toggle={() => {}}>
        <DropdownToggle className="position-absolute p-0 border-0 opacity-0 pointer-events-none" />
        <DropdownMenu {...dropDownMenuProps} container="body">
          <div className="vstack">
            {predictions?.map((prediction) => (
              <DropdownItem className="text-wrap" onClick={clickHandler(prediction.place_id)} key={prediction.place_id}>
                {prediction.description}
              </DropdownItem>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    </Wrapper>
  )
}

export default forwardRef(GooglePlacesInput)
