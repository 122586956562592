import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Label, TabContent } from "reactstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Weekdays from "./form/Weekdays"
import Range from "./form/Range"
import Date from "./form/Date"
import ExistingUnavailableDays from "./ExistingUnavailableDays"
import Input from "components/form/Input"
import Spinner from "components/common/Spinner"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { omit, find } from "lodash"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { deleteGuideUnavailableDay, saveUnavailableDay } from "store/unavailable-days"
import { updateUnavailableWeekdays } from "store/user"

const UNAVAILABLE_TYPES = ["weekday", "range", "date"]

const Form = ({ onChanged }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()
  const [searchParams, setSearchParams] = useSearchParams()
  const editUnavailableId = +searchParams.get("unavailable_id")
  const [editId, setEditId] = useState(editUnavailableId || null)
  const [activeForm, activateForm] = useState("weekday")

  const { guide } = useSelector((store) => store.user)
  const { unavailableDay } = useSelector((store) => store.unavailableDays)
  const {
    loading,
    unavailable_days,
    metadata: { unavailable_weekdays }
  } = useSelector((store) => store.dashboard)

  const editUnavailableDay = useMemo(() => find(unavailable_days, ["id", editId]), [unavailable_days, editId])

  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({ ...unavailableDay, unavailable_weekdays }, [
    "unavailable_type",
    "unavailable_date",
    "unavailable_weekdays",
    "start_datetime",
    "end_datetime",
    "all_day",
    "recurring_yearly"
  ])

  const [editForm, editChangeHandler, editSubmitHandler, editSubmitCallback, , editIsChanged] = useForm(editUnavailableDay, [
    "unavailable_type",
    "unavailable_date",
    "start_datetime",
    "end_datetime",
    "all_day",
    "recurring_yearly"
  ])

  const cancelEditHandler = (e) =>
    (e.preventDefault && e.preventDefault()) || setEditId(null) || setSearchParams(searchParams.delete("unavailable_id") || searchParams)

  const editHandler = useCallback(
    ({ target }) =>
      setEditId(+target.value || null) || setSearchParams(searchParams.set("unavailable_id", +target.value || null) || searchParams),
    [searchParams, setSearchParams]
  )

  const deleteHandler = confirmModal(
    { title: "Are you sure you want to delete this Unavailable Day?", color: "danger", submitText: t("global.delete") },
    ({ target }) => {
      dispatch(deleteGuideUnavailableDay(target.value)).then(cancelEditHandler)
    }
  )

  submitCallback(() => {
    if (activeForm === "weekday" && guide.id) {
      dispatch(updateUnavailableWeekdays(guide.id, form)).then(() => navigate(routes.guideDashboardPath({}, searchParams)))
      return
    }
    const unavailable_day = omit(form, "unavailable_weekdays")
    unavailable_day.unavailable_type = activeForm
    if (activeForm === "date") unavailable_day.all_day = true
    const formData = serialize({ unavailable_day })
    dispatch(saveUnavailableDay(unavailableDay.id, formData)).then(reset)
  })

  editSubmitCallback(() => {
    const formData = serialize({ unavailable_day: editForm })
    dispatch(saveUnavailableDay(editId, formData)).then(cancelEditHandler)
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  useEffect(() => {
    setEditId(editUnavailableId || null)
    //eslint-disable-next-line
  }, [editUnavailableId])

  return loading ? (
    <Spinner />
  ) : editId ? (
    <form onSubmit={editSubmitHandler}>
      <div className="vstack gap-20">
        <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
          <h1 className="h3 fw-medium lh-1">{t("unavailable_days.edit")}</h1>
          <div className="hstack gap-10 my-n1 ms-auto">
            <Button color="light" className="fs-7" type="button" onClick={cancelEditHandler}>
              {t("global.cancel")}
            </Button>
            <Button color="primary" className="fs-7" disabled={!editIsChanged}>
              {t("global.save")}
            </Button>
          </div>
        </div>
        <div className="bg-white rounded p-20">
          <TabContent activeTab={editForm.unavailable_type} className="w-100">
            <Range form={editForm} changeHandler={editChangeHandler} />
            <Date form={editForm} changeHandler={editChangeHandler} />
          </TabContent>
        </div>
        {editId && (
          <div className="vstack">
            <button type="button" className="link link-danger fs-6 fw-medium ms-auto" value={editId} onClick={deleteHandler}>
              {t("global.delete")}
            </button>
          </div>
        )}
      </div>
    </form>
  ) : (
    <form onSubmit={submitHandler}>
      <div className="vstack gap-20">
        <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
          <h1 className="h3 fw-medium lh-1">{t("unavailable_days.manage")}</h1>
          <div className="hstack gap-10 my-n1 ms-auto">
            <Button color="primary" className="fs-7" disabled={!isChanged}>
              {t("global.save")}
            </Button>
          </div>
        </div>
        <div className="bg-white rounded p-20">
          <div className="mb-30">
            <Label>{t("unavailable_day.labels.select")}</Label>
            <div className="hstack gap-30">
              {UNAVAILABLE_TYPES.map((type) => (
                <Label key={type} check className="hstack gap-10 fs-7 fw-normal">
                  <Input type="radio" value={type} checked={activeForm === type} onChange={() => activateForm(type)} className="mt-0" />
                  <span className="text-dark text-opacity-50">{t(`unavailable_day.labels.${type}`)}</span>
                </Label>
              ))}
            </div>
          </div>
          <TabContent activeTab={activeForm} className="w-100">
            <Weekdays form={form} changeHandler={changeHandler} />
            <Range form={form} changeHandler={changeHandler} />
            <Date form={form} changeHandler={changeHandler} />
          </TabContent>
        </div>
        <ExistingUnavailableDays unavailableDays={unavailable_days} type={activeForm} onEdit={editHandler} onDelete={deleteHandler} />
      </div>
    </form>
  )
}

export default memo(Form)
