import React, { useEffect, useMemo, useRef } from "react"
import { get, invert, mapValues } from "lodash"
import { dynamicRoute } from "router/routes"

import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom"

import { serialize } from "object-to-formdata"

import { STEP_PATHS, STEP_NUMBERS } from "constants/wizard"

export const useWizardSteps = ({ name = "", stepsKey }) => {
  if (!name) throw new Error("useWizardSteps require name!")
  const params = useParams()
  const currentStepPath = useLocation().pathname
  const item = useSelector((state) => get(state, name))
  const itemName = name?.split(".").at(-1)
  const pathsKey = stepsKey?.split(".").at(-1) || itemName
  const { wizard_step, wizard_completed } = item

  const stepNumbers = STEP_NUMBERS[pathsKey]
  const stepNumbersInverted = useMemo(() => invert(stepNumbers), [stepNumbers])
  const stepsCount = useMemo(() => Object.keys(stepNumbers).length, [stepNumbers])

  const stepPaths = STEP_PATHS[pathsKey]
  const stepPathsWithParams = mapValues(stepPaths, (path) => dynamicRoute(path)(params))
  const stepPathsInverted = useMemo(() => invert(stepPathsWithParams), [stepPathsWithParams])

  const currentStepName = stepPathsInverted[currentStepPath]
  const currentStepNumber = stepNumbers[currentStepName]
  const isCurrentWizardStep = wizard_step === currentStepName

  const nextStepNumber = Math.min(currentStepNumber + 1, stepsCount)
  const nextStepName = stepNumbersInverted[nextStepNumber]

  const updateStep = (object) => {
    const form = { ...object }
    if (isCurrentWizardStep && !wizard_completed) form.wizard_step = nextStepName
    return form
  }

  return updateStep
}

export const useWizardStep = ({ name = "", stepsKey, stepNumber }) => {
  if (!name) throw new Error("useWizardSteps require name!")
  const params = useParams()
  const [search] = useSearchParams()
  const item = useSelector((state) => get(state, name))
  const itemName = name?.split(".").at(-1)
  const pathsKey = stepsKey?.split(".").at(-1) || itemName
  const { wizard_step, wizard_completed } = item

  const stepNumbers = STEP_NUMBERS[pathsKey]
  const stepNumbersInverted = useMemo(() => invert(stepNumbers), [stepNumbers])
  const stepName = stepNumbersInverted[stepNumber]
  const to = dynamicRoute(STEP_PATHS[pathsKey][stepName])(params, search)

  const disabled = useMemo(() => {
    if (wizard_completed) return false
    if (typeof stepNumber === "number") return stepNumber > (stepNumbers[wizard_step] || 0)
    return true
  }, [stepNumber, wizard_completed, wizard_step, stepNumbers])

  return [to, disabled]
}

export const useWizardRedirects = ({
  name = "",
  stepsKey,
  rootPath,
  exitPath,
  loadingFallback = null,
  skipLastStepAfterCompleted = false
}) => {
  if (!name) throw new Error("useWizardSteps require name!")
  const params = useParams()
  const [search] = useSearchParams()
  const item = useSelector((state) => get(state, name))
  const itemName = name?.split(".").at(-1)
  const pathsKey = stepsKey?.split(".").at(-1) || itemName
  const { loading, firstLoaded, id, wizard_step, wizard_completed } = item

  const wizardCompletedPrevState = useRef(wizard_completed)

  const stepPaths = STEP_PATHS[pathsKey]
  const stepPathsWithParams = mapValues(stepPaths, (path) => dynamicRoute(path)(params))
  const stepPathsValues = Object.values(stepPathsWithParams)

  const firstStepPath = dynamicRoute(stepPathsValues.at(0))({}, search)
  const lastStepPath = dynamicRoute(stepPathsValues.at(-1))({}, search)

  const cuurentPageIsRootWizard = useMatch(rootPath)
  const cuurentPageIsSubmitWizard = useMatch(lastStepPath)
  const currentPagePath = useLocation().pathname

  const lastAvailableStepPath = useMemo(() => stepPathsWithParams[wizard_step], [stepPathsWithParams, wizard_step])
  const lastAvailableStepPathWithSearch = useMemo(
    () => lastAvailableStepPath && dynamicRoute(lastAvailableStepPath)({}, search),
    [lastAvailableStepPath, search]
  )
  const availableSteps = useMemo(
    () => stepPathsValues.slice(0, stepPathsValues.indexOf(lastAvailableStepPath) + 1),
    [stepPathsValues, lastAvailableStepPath]
  )

  useEffect(() => {
    wizardCompletedPrevState.current = wizard_completed
  }, [wizard_completed])

  if (firstLoaded) {
    if (!id) return <Navigate to={exitPath} replace />
    if (wizard_completed) {
      if (cuurentPageIsRootWizard) return <Navigate to={firstStepPath} replace />
      if (cuurentPageIsSubmitWizard) {
        if (wizardCompletedPrevState.current === false) return <Navigate to={exitPath} replace />
        else if (skipLastStepAfterCompleted) return <Navigate to={firstStepPath} replace />
      }
    } else if (!availableSteps.includes(currentPagePath)) return <Navigate to={lastAvailableStepPathWithSearch} replace />
  } else if (loading) return loadingFallback
}

export const useWizardNavigation = ({ name, stepsKey, action }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [search] = useSearchParams()
  const item = useSelector((state) => get(state, name))
  const itemName = name?.split(".").at(-1)
  const pathsKey = stepsKey?.split(".").at(-1) || itemName
  const { id, uuid, wizard_completed, wizard_step } = item
  const stepPaths = STEP_PATHS[pathsKey]
  const stepNames = Object.keys(stepPaths)
  const stepPathsWithParams = mapValues(stepPaths, (path) => dynamicRoute(path)(params))

  const currentStepPath = useLocation().pathname
  const currentStepName = invert(stepPathsWithParams)[currentStepPath]
  const currentStepIndex = stepNames.indexOf(currentStepName)
  const isCurrentWizardStep = wizard_step === currentStepName

  const prevStepName = stepNames[Math.max(0, currentStepIndex - 1)]
  const prevStepPath = currentStepIndex > 0 ? dynamicRoute(stepPathsWithParams[prevStepName])({}, search) : null

  const nextStepName = stepNames[Math.min(stepNames.length - 1, currentStepIndex + 1)]
  const nextStepPath =
    currentStepPath !== stepPathsWithParams[nextStepName] ? dynamicRoute(stepPathsWithParams[nextStepName])({}, search) : null

  const skipStep = (event) => {
    if (event.persist) event.persist()
    if (wizard_completed) return
    const form = serialize({ [itemName]: { wizard_step: nextStepName } })
    dispatch(action(id || uuid, form)).then(() => navigate(nextStepPath))
  }

  const submitWizard = (event) => {
    if (event.persist) event.persist()
    if (wizard_completed) return
    const form = serialize({ [itemName]: { wizard_completed: true } })
    dispatch(action(id || uuid, form))
  }

  return [isCurrentWizardStep, prevStepPath, nextStepPath, skipStep, submitWizard, wizard_completed]
}
