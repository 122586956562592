export const ROUTES_LIST = {
  rootPath: "/",
  becomeAGuidePath: "/become-a-guide",
  faqPath: "/faq",
  tripsPath: "/trips",
  tripPath: "/trips/:id",

  // Devise routes
  signInPath: "/sign-in",
  signUpPath: "/sign-up",
  forgotPasswordPath: "/forgot-password",
  resetPasswordRequestSentPath: "/reset-password/request-sent",
  resetPasswordPath: "/reset-password",
  resetPasswordSuccessPath: "/reset-password/success",
  verifyAccountPath: "/verify-account",
  verifyCodePath: "/verify-code",
  splashPath: "/splash",

  // Booking routes
  bookingWizardPath: "/booking_trips/wizard",

  guestBookingWizardRootPath: "/guest/booking_trips/:uuid/wizard",
  guestBookingWizardStep1Path: "/guest/booking_trips/:uuid/wizard/booking",
  guestBookingWizardStep2Path: "/guest/booking_trips/:uuid/wizard/accessibility",
  guestBookingWizardStep3Path: "/guest/booking_trips/:uuid/wizard/payment",
  guestBookingWizardStep4Path: "/guest/booking_trips/:uuid/wizard/confirmation",

  // Client routes
  clientRootPath: "/client",
  clientFavouritesPath: "/client/favourites",
  clientMessagesPath: "/client/messages",
  clientProfileRootPath: "/client/profile/:id",
  clientSettingsRootPath: "/client/settings",
  clientSettingsPaymentPath: "/client/settings/payment",
  clientSettingsPasswordPath: "/client/settings/password",
  clientSettingsNotificationsPath: "/client/settings/notifications",

  clientBookingsPath: "/client/booking_trips",
  clientBookingsPastTripsPath: "/client/booking_trips/past",
  clientBookingPath: "/client/booking_trips/:uuid",
  clientBookingWizardRootPath: "/client/booking_trips/:uuid/wizard",
  clientBookingWizardStep1Path: "/client/booking_trips/:uuid/wizard/booking",
  clientBookingWizardStep2Path: "/client/booking_trips/:uuid/wizard/accessibility",
  clientBookingWizardStep3Path: "/client/booking_trips/:uuid/wizard/payment",
  clientBookingWizardStep4Path: "/client/booking_trips/:uuid/wizard/confirmation",

  publicGuidePath: "/guides/:id",
  publicGuideTripsPath: "/guides/:id/trips",
  publicGuideLocationPath: "/guides/:id/location",
  publicGuideBoatsPath: "/guides/:id/boats",
  publicGuidePostsPath: "/guides/:id/posts",
  publicGuideReviewsPath: "/guides/:id/reviews",

  // Guide routes
  guideRootPath: "/guide",
  guideDashboardPath: "/guide/dashboard",
  guideDashboardBookingNewPath: "/guide/dashboard/bookings/new",
  guideDashboardBookingEditPath: "/guide/dashboard/bookings/:id/edit",
  guideDashboardJournalNewPath: "/guide/dashboard/journal_notes/new",
  guideDashboardJournalEditPath: "/guide/dashboard/journal_notes/:id/edit",

  guideDashboardUnavailableDayManagePath: "/guide/dashboard/unavailable_days/manage",

  guideWizardRootPath: "/guide/wizard",
  guideWizardStep1Path: "/guide/wizard/choose-your-activities",
  guideWizardStep2Path: "/guide/wizard/your-profile",
  guideWizardStep3Path: "/guide/wizard/photos",
  guideWizardStep4Path: "/guide/wizard/extras",
  guideWizardStep5Path: "/guide/wizard/documents",
  guideWizardStep6Path: "/guide/wizard/submit",

  guideBookingsPath: "/guide/bookings",
  guideBookingNewPath: "/guide/bookings/new",
  guideBookingEditPath: "/guide/bookings/:id/edit",

  guideClientsPath: "/guide/clients",
  guideAddNewClientPath: "/guide/clients/new",
  guideClientPath: "/guide/clients/:id/show",
  guideEditClientPath: "/guide/clients/:id/edit",
  guideClientEmailActionPath: "/guide/clients/email_action",
  guideClientTextActionPath: "/guide/clients/text_action",
  guideClientMessageActionPath: "/guide/clients/message_action",

  guideTripsPath: "/guide/trips",
  guideTripPath: "/guide/trips/:id",
  guideTripWizardRootPath: "/guide/trips/:id/wizard",
  guideTripWizardStep1Path: "/guide/trips/:id/wizard/trip-details",
  guideTripWizardStep2Path: "/guide/trips/:id/wizard/photos",
  guideTripWizardStep3Path: "/guide/trips/:id/wizard/boats",
  guideTripWizardStep4Path: "/guide/trips/:id/wizard/location",
  guideTripWizardStep5Path: "/guide/trips/:id/wizard/preview",

  guideMessagesPath: "/guide/messages",
  guideAccountingPath: "/guide/accounting",
  guideAccountingAddExpancePath: "/guide/accounting/new",
  guideAccountingEditExpancePath: "/guide/accounting/:id/edit",

  guideBoatsPath: "/guide/boats",
  guideAddNewBoatPath: "/guide/boats/new",
  guideEditBoatPath: "/guide/boats/:id/edit",

  guideMarketingPath: "/guide/marketing",
  guideMarketingPostNewPath: "/guide/marketing/new",
  guideMarketingPostEditPath: "/guide/marketing/:id/edit",
  guideMarketingTemplatesPath: "/guide/marketing/templates",
  guideMarketingTemplatesParentsPath: "/guide/marketing/templates/parents",
  guideMarketingTemplateNewPath: "/guide/marketing/templates/new",
  guideMarketingTemplateEditPath: "/guide/marketing/templates/:id/edit",

  guideSettingsRootPath: "/guide/settings",
  guideSettingsPaymentPath: "/guide/settings/payment",
  guideSettingsPasswordPath: "/guide/settings/password",
  guideSettingsNotificationsPath: "/guide/settings/notifications",

  guideWeatherPath: "/guide/weather",

  guidePostPath: "/guide/posts/:id",
  guideProfileRootPath: "/guide/profile/:id",
  guideProfileContactsPath: "/guide/profile/:id/contacts",
  guideProfileMediaPath: "/guide/profile/:id/media",
  guideProfileTaggedPath: "/guide/profile/:id/tagged",
  guideProfileSettingsPath: "/guide/profile/:id/settings",
  guideContactsPath: "/guide/contacts",
  guideCommunitiesPath: "/guide/communities",
  guideCommunityPath: "/guide/communities/:id",
  guideNotificationsPath: "/guide/notifications",
  guideContactUsPath: "/guide/contact-us",
  guideChangePasswordPath: "/guide/change-password"
  // Common routes
}

export const dynamicRoute = (path) => {
  const requiredParams = path.match(/:\w+/g) || []
  const route = (params = {}, search = {}) => {
    const searchString = new URLSearchParams(search).toString()
    if (!Object.keys(params).length) return searchString ? `${path}?${searchString}` : path

    const errors = []
    const dynamicPath = requiredParams.reduce((link, param) => {
      const receivedParam = params[param.replace(":", "")]
      if (receivedParam !== undefined) return link.replace(param, receivedParam)
      else errors.push(`"${param}" param is required`)
      return link
    }, path)

    if (errors.length) {
      console.error(`For path "${path}" ${errors.join(", ")}`)
    }

    return searchString ? `${dynamicPath}?${searchString}` : dynamicPath
  }
  return route
}

export const routes = Object.keys(ROUTES_LIST).reduce(
  (acc, routeAlias) => ({ ...acc, [routeAlias]: dynamicRoute(ROUTES_LIST[routeAlias]) }),
  {}
)
