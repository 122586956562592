import React, { useEffect } from "react"
import { Label } from "reactstrap"
import { useNavigate } from "react-router-dom"

import Input from "components/form/Input"
import ActionButtons from "components/wizards/ActionButtons"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useForm from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateTrip } from "store/trips"
import { getGuideBoats } from "store/boats"
import { collectionSelector, metadataSelector, modelSelector } from "store/selectors"

const Step3Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "trips.trip" })
  const trip = useSelector(modelSelector("trip"))
  const metadata = useSelector(metadataSelector("trips"))
  const boats = useSelector(collectionSelector("boats"))
  const renderBoats = metadata.boats.length ? metadata.boats : boats

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...trip }, ["boat_id"])

  useEffect(() => {
    if (!metadata.boats.length) dispatch(getGuideBoats())
  }, []) //eslint-disable-line

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value

    const tripAttributes = { ...updateStep(form) }
    const formData = serialize({ trip: tripAttributes })

    dispatch(updateTrip(trip.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <form onSubmit={submitHandler} className="vstack gap-30">
      <div className="vstack gap-15 bg-white rounded p-20">
        <FirstLoading name="boats">
          {renderBoats.map((boat) => (
            <Label key={boat.id} check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
              <Input
                type="radio"
                name={`boat_id`}
                value={boat.id}
                checked={+form.boat_id === +boat.id}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${+form.boat_id === +boat.id ? "" : "text-opacity-50"}`}>{boat.name}</span>
            </Label>
          ))}
        </FirstLoading>
      </div>
      <ActionButtons isChanged={isChanged} action={updateTrip} withSkip name="trips.trip" className="mt-20" />
    </form>
  )
}

export default Step3Form
