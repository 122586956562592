import React, { memo, useEffect } from "react"
import { Label, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { routes } from "router"
import Spinner from "components/common/Spinner"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { cleanPastBooking, getGuidePastBookingsForClient } from "store/bookings"

const PastTrips = ({ className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    pastLoading,
    booking: { client },
    metadata: { past_trips }
  } = useSelector((store) => store.bookings)
  const hasPastTrips = !!past_trips?.length

  useEffect(() => {
    if (!hasPastTrips && client.id) dispatch(getGuidePastBookingsForClient(client.id))
    return () => dispatch(cleanPastBooking())
  }, []) //eslint-disable-line

  if (!pastLoading && !hasPastTrips) return null

  const classes = ["bg-white rounded p-20"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <Label className="fs-6">{t("booking.labels.past_trips")}</Label>
      {pastLoading ? (
        <Spinner />
      ) : (
        <Table hover>
          <thead>
            <tr className="border-bottom border-gray-lightest">
              <th className="text-dark text-opacity-50 fw-normal ps-0" width="1">
                Date
              </th>
              <th className="text-dark text-opacity-50 fw-normal">Trip</th>
              <th className="text-dark text-opacity-50 fw-normal pe-0">Amount Spent</th>
            </tr>
          </thead>
          <tbody>
            {past_trips.map((trip, index) => (
              <tr key={index} className="border-bottom border-gray-lightest fs-5 fw-normal">
                <td className="text-dark text-opacity-75 ps-0 text-nowrap">{trip.formatted_date || "—"}</td>
                <td className="text-dark text-opacity-75">
                  <Link to={routes.guideTripPath({ id: trip.id })} className="link link-dark">
                    {trip.title || "—"}
                  </Link>
                </td>
                <td className="text-dark text-opacity-75 pe-0">{trip.booking_total_amount || "—"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default memo(PastTrips)
