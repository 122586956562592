import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"
import { Table } from "react-bootstrap"
import Avatar from "components/common/Avatar"

import { useTranslation } from "react-i18next"
import { colorClasses } from "helpers/color"
import moment from "moment"

import { PAYMENT_STATUS_COLOR } from "components/bookings/constants"

export default function BookingsDetails({ date, bookings = [], isGroupByMonth }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const today = moment().startOf("day")
  const isToday = moment(date).diff(today, "days") === 0
  const isTomorrow = moment(date).diff(today, "days") === 1
  const isTodayOrTomorrow = isToday || isTomorrow
  const monthFormat = "MMM YYYY"
  const dayFormat = "MMM, DD"
  const dayLongFormat = `${(isToday && "[Today]") || (isTomorrow && "[Tomorrow]") || "dddd"}, MMM DD, YYYY`
  const headFormat = isGroupByMonth ? monthFormat : dayLongFormat

  return (
    <div className="bg-white rounded overflow-hidden">
      {date && (
        <div className="hstack justify-content-between px-15 py-10 border-bottom border-gray-lightest">
          <p className={["fs-16 fw-medium m-0", isTodayOrTomorrow ? "text-primary-second" : ""].join(" ")}>
            {moment(date).format(headFormat)}
          </p>
        </div>
      )}
      {bookings.length > 0 && (
        <Table responsive hover borderless className="m-0">
          <tbody>
            {bookings.map((booking) => (
              <tr
                key={booking.id}
                className={["link-target", booking.status === "canceled" ? "table-danger text-decoration-line-through" : ""].join(" ")}
                valign="middle"
              >
                <td width="100" className="ps-15 pe-20 lh-sm">
                  {isGroupByMonth && date && (
                    <div className="text-dark text-opacity-50">{moment(booking.booked_date).format(dayFormat)}</div>
                  )}
                  <div>{booking.trip.start_time}</div>
                </td>
                <td width="25%">
                  <Link
                    className="link link-dark fw-medium hstack gap-10 my-1"
                    to={routes.guideBookingEditPath({ id: booking.id }, searchParams)}
                  >
                    <Avatar user={booking.client.avatar} size={32} border={false} />
                    <span>
                      {booking.client.first_name} {booking.client.last_name}
                    </span>
                  </Link>
                </td>
                <td width="180" className="text-center">
                  <div
                    className={[
                      "d-inline-block fs-7 fw-semibold text-center bg-opacity-25 rounded-pill py-1 px-3",
                      colorClasses(booking.payment_status, PAYMENT_STATUS_COLOR)
                    ].join(" ")}
                    style={{ minWidth: 100 }}
                  >
                    {t(`booking.payment.statuses.${booking.payment_status}`)}
                  </div>
                </td>
                <td>{booking.trip.title}</td>
                <td width="15%" className="pe-15 text-nowrap">
                  {t("booking.labels.adult_count", { count: `${booking.adult_count} ` })}
                  {", "}
                  {t("booking.labels.child_count", { count: `${booking.child_count} ` })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}
