import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import PhotoSlider from "components/common/PhotoSlider"
import TripsCalendar from "components/guides/Calendar"
import Trip from "components/trips/Trip"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { cleanTrips } from "store/trips"

export default function Guide() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { guide } = useSelector((store) => store.guides)
  const { description, expertises, popular_trips, activity_type } = guide
  useTitle(guide.display_name)

  const hasPopularTrips = !!popular_trips?.length

  useEffect(() => {
    return () => dispatch(cleanTrips())
  })

  return (
    <>
      {guide.photos?.length ? <PhotoSlider photos={guide.photos} ratio="21x9" className="w-100 h-100" /> : null}
      <Container>
        <section className="my-80">
          <h2 className="fw-semibold mb-30">{t("global.about")}</h2>
          {description}
        </section>
        <section className="my-80">
          <h2 className="fw-semibold mb-30">{t("guide.labels.experience_expertise")}</h2>
          <ul className="grid grid-cols-1 grid-cols-sm-2 grid-cols-md-3 grid-cols-lg-4 gap-15 list-unstyled m-0 mt-15">
            {expertises.length ? expertises.map((item) => <li key={item.id}>— {item.name}</li>) : "—"}
          </ul>
        </section>
        <section className="my-80">
          <h2 className="fw-semibold mb-30">{t(`guide.labels.outdoor_${activity_type}s_calendar`)}</h2>
          <TripsCalendar />
        </section>
        {hasPopularTrips ? (
          <section className="my-80">
            <h2 className="fw-semibold mb-30">{t("guides.popular_trips")}</h2>
            <Row className="gap-y-20 gx-25 gx-xxl-90 mt-35">
              {popular_trips.map((trip) => (
                <Col xs={12} lg={4} key={trip.id}>
                  <Trip trip={trip} className="h-100" />
                </Col>
              ))}
            </Row>
          </section>
        ) : null}
      </Container>
    </>
  )
}
