import { routes } from "router/routes"

export const STEP_NUMBERS = {
  guide: {
    activity: 1,
    profile: 2,
    photos: 3,
    extras: 4,
    documents: 5,
    submit: 6
  },
  trip: {
    trip_details: 1,
    photos: 2,
    boats: 3,
    location: 4,
    preview: 5
  },
  bookingClient: {
    booking_details: 1,
    accessibility: 2,
    payment: 3,
    confirm: 4
  },
  bookingGuest: {
    booking_details: 1,
    accessibility: 2,
    payment: 3,
    confirm: 4
  }
}

export const STEP_PATHS = {
  guide: {
    activity: routes.guideWizardStep1Path(),
    profile: routes.guideWizardStep2Path(),
    photos: routes.guideWizardStep3Path(),
    extras: routes.guideWizardStep4Path(),
    documents: routes.guideWizardStep5Path(),
    submit: routes.guideWizardStep6Path()
  },
  trip: {
    trip_details: routes.guideTripWizardStep1Path(),
    photos: routes.guideTripWizardStep2Path(),
    boats: routes.guideTripWizardStep3Path(),
    location: routes.guideTripWizardStep4Path(),
    preview: routes.guideTripWizardStep5Path()
  },
  bookingClient: {
    booking_details: routes.clientBookingWizardStep1Path(),
    accessibility: routes.clientBookingWizardStep2Path(),
    payment: routes.clientBookingWizardStep3Path(),
    confirm: routes.clientBookingWizardStep4Path()
  },
  bookingGuest: {
    booking_details: routes.guestBookingWizardStep1Path(),
    accessibility: routes.guestBookingWizardStep2Path(),
    payment: routes.guestBookingWizardStep3Path(),
    confirm: routes.guestBookingWizardStep4Path()
  }
}
