import React, { forwardRef, useRef } from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"
import Input from "components/form/Input"
import DynamicTag from "components/DynamicTag"

import { get } from "lodash"

const getPreviousMapValue = (element, map) => {
  let previousElement = []
  if (!map instanceof Map) return previousElement
  for (let [currentKey, currentValue] of map.entries()) {
    if (element === currentKey) break
    previousElement = [currentKey, currentValue]
  }
  return previousElement
}

const getLastMapValue = (map) => (map instanceof Map ? [...map?.entries()].at(-1) : [])

const ArrayInput = ({
  form,
  changeHandler,
  addHandler,
  removeHandler,
  name,
  placeholder,
  className,
  type = "text",
  field = "name",
  ipnutTag,
  controlled = true,
  ...rest
}) => {
  const refs = useRef(new Map())
  const items = form?.[name] || []

  const setRef = (key) => (node) => node && refs.current?.set(key, node)
  const removeRef = (key, map) => refs.current?.delete(key)

  const onRemove = (item) => () => {
    const prevInput = getPreviousMapValue(item._id, refs.current).at(1)
    removeRef(item._id, refs.current)
    removeHandler(item)
    if (prevInput) prevInput.focus()
  }

  const onAdd = () => {
    addHandler()
    setTimeout(() => getLastMapValue(refs.current)?.at(1)?.focus(), 0)
  }

  const keyDownHandler = (event) => event.key === "Enter" && !event.shiftKey && event.preventDefault()
  const keyUpHandler = (item) => (event) => {
    switch (event.key) {
      case "Enter":
        if (event.shiftKey) break
        onAdd()
        break
      case "Backspace":
        if (event.shiftKey) onRemove(item)()
        break
      default:
        break
    }
  }

  const classes = ["grid grid-cols-1 gap-y-10 gap-x-20"]
  if (className) classes.push(className)

  const renderItem = (item, index) => {
    if (!item._id) return null
    return (
      <ArrayInputItem
        key={index}
        className="fs-6"
        {...rest}
        onChange={changeHandler}
        onKeyDown={keyDownHandler}
        onKeyUp={keyUpHandler(item)}
        onRemove={onRemove(item)}
        tag={ipnutTag || Input}
        id={`${name}_${index}`}
        type={type}
        name={`${name}[${index}]${field}`}
        placeholder={placeholder}
        {...(controlled ? { value: get(item, field) || "" } : { defaultValue: get(item, field) || "" })}
        ref={setRef(item._id)}
      />
    )
  }

  return (
    <div className={classes.join(" ")}>
      {items.length > 0 && items.map(renderItem)}
      <Button color="primary-second" className="p-0 border-0 me-auto align-self-center" onClick={onAdd}>
        <Icon iconName="Add" className="d-block" />
      </Button>
    </div>
  )
}

const ArrayInputItem = forwardRef(({ onRemove, ...rest }, ref) => {
  return (
    <div className="vstack position-relative">
      <DynamicTag {...rest} ref={ref} />
      <Button
        color="link link-dark"
        className="p-1 border-0 bg-dark-hover bg-opacity-25-hover position-absolute end-0 top-50 translate-middle-y me-2"
        onClick={onRemove}
      >
        <Icon iconName="Remove" className="d-block" />
      </Button>
    </div>
  )
})

export default ArrayInput
