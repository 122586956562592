import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { findIndex, reject, find } from "lodash"
import { formatPaginationParams } from "helpers/pagination"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("boats/requested")
const received = createAction("boats/received")
const failed = createAction("boats/failed")
const cleaned = createAction("boats/cleaned")

const requestedBoat = createAction("boats/requestedBoat")
const receivedBoat = createAction("boats/receivedBoat")
const failedBoat = createAction("boats/failedBoat")

const createdBoat = createAction("boats/createdBoat")
const updatedBoat = createAction("boats/updatedBoat")
const deletedBoat = createAction("boats/deletedBoat")
const cleanedBoat = createAction("boats/cleanedBoat")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  metadata: {
    page: null,
    total_pages: null,
    total_results: null,
    next_page: null,
    prev_page: null
  },
  boat: {
    ...LOADING_INITIAL_STATE,
    id: null,
    guide_id: null,
    name: "",
    description: "",
    custom_info: [],
    properties: []
  }
}

const boatsReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.payload.data
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedBoat.type]: (state) => {
    setRequested(state.boat)
  },
  [receivedBoat.type]: (state, action) => {
    setReceived(state.boat)
    state.boat = action.payload
  },
  [createdBoat.type]: (state, action) => {
    setReceived(state.boat)
    state.items = [action.payload, ...state.items]
    Object.assign(state.boat, action.payload)
  },
  [updatedBoat.type]: (state, action) => {
    setReceived(state.boat)
    const postIndex = findIndex(state.items, ["id", action.payload.id])

    if (postIndex >= 0) Object.assign(state.items[postIndex], action.payload)
    Object.assign(state.boat, action.payload)
  },
  [deletedBoat.type]: (state, action) => {
    setReceived(state.boat)
    state.items = reject(state.items, ["id", action.payload.id])
  },
  [failedBoat.type]: (state) => {
    setFailed(state.boat)
  },
  [cleanedBoat.type]: (state) => {
    state.boat = initialState.boat
  }
})
export default boatsReducer

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = formatPaginationParams(state.boats.metadata, nextPage)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: Object.assign({}, paginationParams, params),
      props: { reset: !nextPage }
    })
  )
}

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const boat = find(state.boats.items, ["id", +id])

  if (boat) {
    dispatch(receivedBoat(boat))
    return new Promise((resolve) => resolve(boat))
  }
  return dispatch(
    api({
      url,
      onStart: requestedBoat.type,
      onSuccess: receivedBoat.type,
      onError: failedBoat.type
    })
  )
}

export const getGuideBoats = (nextPage, params) => (dispatch, getState) => getItems("/guide/boats", nextPage, params, dispatch, getState)

export const getGuideBoat = (id) => (dispatch, getState) => getItem(`/guide/boats/${id}`, id, dispatch, getState)

export const getClientGuideBoats = (id, nextPage, params) => (dispatch, getState) =>
  getItems(`/client/guides/${id}/boats`, nextPage, params, dispatch, getState)

export const createBoat = (data) =>
  api({
    url: "/guide/boats",
    method: "post",
    onSuccess: createdBoat.type,
    data
  })

export const updateBoat = (id, data) =>
  api({
    url: `/guide/boats/${id}`,
    method: "put",
    data: data,
    onSuccess: updatedBoat.type
  })

export const saveBoat = (id, data) => (id ? updateBoat(id, data) : createBoat(data))

export const deleteBoat = (id) =>
  api({
    url: `/guide/boats/${id}`,
    method: "delete",
    onSuccess: deletedBoat.type
  })

export const cleanBoats = () => (dispatch) => dispatch(cleaned())
export const cleanBoat = () => (dispatch) => dispatch(cleanedBoat())
