import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import Icon from "components/common/Icon"

import heroImage from "assets/images/landing/hero.jpg"
import fishingImage from "assets/images/landing/fishing.jpg"
import huntingImage from "assets/images/landing/hunting.jpg"
import guideImage from "assets/images/landing/guide.jpg"

import { useTitle } from "hooks/useTitle"
import { routes } from "router"

export default function Landing() {
  useTitle("Home")

  return (
    <>
      <div className="position-relative overflow-hidden py-60 py-sm-100">
        <img src={heroImage} className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover" alt="DS Hero" />
        <Container className="py-60 py-sm-100">
          <div className="position-relative z-1 pb-md-100" style={{ maxWidth: 620 }}>
            <h1 className="lh-1 text-white text-center text-sm-start">The Ultimate Outdoor Experiences at Your Fingertips</h1>
            <div className="hstack gap-20 mt-45 flex-wrap">
              <Button tag={Link} color="white" to="#" className="w-100 w-sm-auto">
                Find Trips
              </Button>
              {/* <Button
                tag={Link}
                color="white"
                outline
                to={routes.becomeAGuidePath()}
                state={{ scrollToTop: true }}
                className="w-100 w-sm-auto"
              >
                Become a DS Guide
              </Button> */}
            </div>
          </div>
        </Container>
      </div>
      <Container className="py-100">
        <Row>
          <Col xs={12} md={{ size: 10, offset: 1 }}>
            <h2 className="h2 fw-semibold lh-1 text-center mb-40">Hunt or Fish. Choose Your Adventure!</h2>
            <div className="hstack gap-40">
              <div className="card border-0 rounded">
                <img src={fishingImage} className="card-img w-100" alt="Fishing" />
                <div className="card-img-overlay hstack bg-dark bg-opacity-75 opacity-0 opacity-100-hover">
                  <Button className="m-auto" tag={Link} color="white" outline to={routes.rootPath()}>
                    Fishing
                  </Button>
                </div>
              </div>
              <div className="card border-0 rounded">
                <img src={huntingImage} className="card-img w-100" alt="Fishing" />
                <div className="card-img-overlay hstack bg-dark bg-opacity-75 opacity-0 opacity-100-hover">
                  <Button className="m-auto" tag={Link} color="white" outline to={routes.rootPath()}>
                    Hunting
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="py-50 bg-dark text-white text-center">
        <Container className="vstack align-items-center">
          <h2 className="h2 fw-normal lh-sm mb-4" style={{ maxWidth: 670 }}>
            Better Experience with DS Location Weather Dashboard
          </h2>
          <p className="mb-50" style={{ maxWidth: 540 }}>
            Digital Sportsman is your gateway to a world of adventure, connecting you with expert hunting and fishing guides in breathtaking
            locations. With our integrated weather dashboard, you can plan your next trip with confidence, ensuring optimal conditions for
            your outdoor pursuits.
          </p>
          <div className="hstack gap-y-30 gap-50 gap-md-50 gap-lg-60 justify-content-center flex-wrap mb-50">
            <div className="">
              <Icon iconName="Temperature" className="border border-white border-opacity-25 rounded" />
              <p className="mt-3 mb-0">Temperature</p>
            </div>
            <div className="">
              <Icon iconName="Wind" className="border border-white border-opacity-25 rounded" />
              <p className="mt-3 mb-0">Wind Speed</p>
            </div>
            <div className="">
              <Icon iconName="Precipitation" className="border border-white border-opacity-25 rounded" />
              <p className="mt-3 mb-0">Precipitation</p>
            </div>
            <div className="">
              <Icon iconName="Tides" className="border border-white border-opacity-25 rounded" />
              <p className="mt-3 mb-0">Tides</p>
            </div>
            <div className="">
              <Icon iconName="Predictions" className="border border-white border-opacity-25 rounded" />
              <p className="mt-3 mb-0">Predictions</p>
            </div>
          </div>
          <Button tag={Link} color="white" to="#" className="w-100 w-sm-auto">
            Check it Out
          </Button>
        </Container>
      </div>
      <Container className="my-100">
        <div className="position-relative rounded overflow-hidden">
          <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark">
            <img src={guideImage} className="w-100 h-100 object-fit-cover opacity-75" alt="Become a Guide" />
          </div>
          <Row className="py-90 position-relative z-1">
            <Col xs={{ size: 10, offset: 1 }}>
              <div className="text-center text-sm-start" style={{ maxWidth: 430 }}>
                <h2 className="text-white mb-3">Become a Guide</h2>
                <p className="text-white">
                  By signing up as a guide, you gain access to a powerful management CRM tool, simplifying the process of handling bookings,
                  schedules, and payments. Curate exceptional fishing trips or hunting excursions, and connect with a community of
                  like-minded individuals.
                </p>
                {/* <div className="mt-30">
                  <Button tag={Link} color="white" to={routes.becomeAGuidePath()} state={{ scrollToTop: true }} className="w-100 w-sm-auto">
                    Become a DS Guide
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}
