import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { DatePicker } from "react-tempusdominus-bootstrap"

import moment from "moment"

import { DATE_FORMAT, PICKER_FORMATS } from "constants/date"

const momentIncludes = (dates, momentValue) => dates.every((date) => moment(date).format(DATE_FORMAT) === momentValue.format(DATE_FORMAT))

const Datepicker = ({ type = "date", name, value, onChange, ...rest }) => {
  const pickerRef = useRef()
  const [isVisibleDatepicker, setVisibleDatepicker] = useState(false)
  const format = useMemo(() => (Object.keys(PICKER_FORMATS).includes(type) ? PICKER_FORMATS[type] : PICKER_FORMATS.date), [type])
  const momentValue = useMemo(() => {
    const parsedMomentValue = moment(value, format)
    return parsedMomentValue.isValid() ? parsedMomentValue : false
  }, [value, format])

  const pickerType = useMemo(() => (type === "week" ? "day" : type), [type])
  const pickerFormat = useMemo(
    () => (Object.keys(PICKER_FORMATS).includes(pickerType) ? PICKER_FORMATS[pickerType] : PICKER_FORMATS.date),
    [pickerType]
  )

  const validateValue = useCallback(
    (momentValue) => {
      if (
        !momentValue ||
        !momentValue.isValid() ||
        (rest.minDate && +moment(rest.minDate) > +momentValue) ||
        (rest.maxDate && +moment(rest.maxDate) < +momentValue) ||
        (rest.enabledDates?.length && !momentIncludes(rest.enabledDates, momentValue)) ||
        (rest.disabledDates?.length && momentIncludes(rest.disabledDates, momentValue)) ||
        (rest.daysOfWeekDisabled?.length && rest.daysOfWeekDisabled.includes(momentValue.day()))
      )
        return null
      return momentValue
    },
    [rest.minDate, rest.maxDate, rest.enabledDates, rest.disabledDates, rest.daysOfWeekDisabled]
  )

  const [inputMomentValue, setInputMomentValue] = useState(momentValue)
  const validValue = useMemo(
    () => validateValue(typeof value !== undefined ? momentValue : inputMomentValue) || null,
    [value, momentValue, inputMomentValue, validateValue]
  )

  const changeHandler = ({ date }) => {
    const value = date ? date.format(format) : ""
    setInputMomentValue(date)
    if (typeof onChange === "function") onChange({ target: { name, value, date } })
  }

  useEffect(() => {
    setTimeout(() => setVisibleDatepicker(true), 100)
  }, [])

  useEffect(() => {
    if (pickerRef.current) pickerRef.current.datetimepicker("date", validValue)
  }, [validValue])

  if (!isVisibleDatepicker) return null
  return (
    <DatePicker
      {...rest}
      pickerRef={pickerRef}
      date={validValue || null}
      locale="en-gb"
      inline
      useCurrent={false}
      onChange={changeHandler}
      format={pickerFormat}
      icons={{ type: "feather", today: "crosshair", clear: "trash", ...rest.icons }}
    />
  )
}

export default memo(Datepicker)
