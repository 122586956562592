import React, { useEffect, useState } from "react"
import { useMatch } from "react-router-dom"
import { routes } from "router"
import { Button, Container } from "reactstrap"
import NavigationLink from "./NavigationLink"
import Sort from "components/trips/filters/Sort"
import Price from "components/trips/filters/Price"
import Guests from "components/trips/filters/Guests"
import SkillLevel from "components/trips/filters/SkillLevel"
import Ecosystem from "components/trips/filters/Ecosystem"

import { useTranslation } from "react-i18next"
import { useScrollToActive } from "hooks/useScrollToActive"
import { scrollToActive } from "helpers/DOM"

export default function TripFiltersNavigation({ className }) {
  const { t } = useTranslation()
  const navigationRef = useScrollToActive()
  const isTripsPage = useMatch(routes.tripsPath())
  const [isScrolled, setScrolled] = useState(false)

  const classes = ["bg-white shadow"]
  if (className) classes.push(className)

  const clickHandler = ({ currentTarget }) => scrollToActive(currentTarget)

  const scrollHandler = () => {
    const scrollElement = navigationRef.current
    const { scrollWidth, clientWidth, scrollLeft } = scrollElement
    const diff = scrollWidth - clientWidth
    if (diff && diff - scrollLeft) setScrolled(true)
    else setScrolled(false)
  }

  useEffect(() => {
    const scrollElement = navigationRef.current
    if (!scrollElement) return
    window.addEventListener("load", scrollHandler)
    window.addEventListener("resize", scrollHandler)
    scrollElement.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("load", scrollHandler)
      window.removeEventListener("resize", scrollHandler)
      scrollElement.removeEventListener("scroll", scrollHandler)
    }
    //eslint-disable-next-line
  }, [])

  return isTripsPage ? (
    <nav className={classes.join(" ")}>
      <Container fluid>
        <div className="full-window-scroll" ref={navigationRef}>
          <div className="flex-fill">
            <div className="hstack gap-20 py-10 py-sm-20">
              <Guests onToggleClick={clickHandler} />
              <NavigationLink tag={Button} iconName="Calendar" label={t("trips.filters.dates")} onClick={clickHandler} disabled />
              <Price onToggleClick={clickHandler} />
              <SkillLevel onToggleClick={clickHandler} />
              <Ecosystem onToggleClick={clickHandler} />
              <NavigationLink tag={Button} iconName="FishingRod" label={t("trips.filters.technique")} onClick={clickHandler} disabled />
              <NavigationLink tag={Button} iconName="Ship" label={t("trips.filters.boat_details")} onClick={clickHandler} disabled />
              <NavigationLink tag={Button} iconName="Anchor" label={t("trips.filters.boat_amenities")} onClick={clickHandler} disabled />
              <NavigationLink tag={Button} iconName="Fish" label={t("trips.filters.species")} onClick={clickHandler} disabled />
              <div className="sticky-end ms-auto">
                <div
                  className={[
                    "bg-white px-10 pe-20 my-n15 me-n20 border-gray-lightest transition",
                    ...(isScrolled ? ["border-start scroll-shadow scroll-shadow-start"] : [])
                  ].join(" ")}
                >
                  <Sort />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  ) : null
}
