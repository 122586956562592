import moment from "moment"

import { DATETIME_FORMAT } from "constants/date"

export const isDateInRange = (start, end, day) => {
  const momentDay = moment(day)
  const startOfDay = momentDay.clone().startOf("day")
  const endOfDay = momentDay.clone().endOf("day")

  const momentStart = moment(start, DATETIME_FORMAT)
  const momentEnd = moment(end, DATETIME_FORMAT)

  const includes = momentStart.isSameOrBefore(momentDay, "day") && momentEnd.isSameOrAfter(momentDay, "day")
  const wholeDayInRange = momentStart.isSameOrBefore(startOfDay) && momentEnd.isSameOrAfter(endOfDay)

  return { includes, wholeDayInRange }
}
