import React, { useCallback, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { routes } from "router"
import { Button, DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"
import DateSwitcher from "components/common/DateSwitcher"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { api } from "store/helpers/api"
import { useDispatch, useSelector } from "react-redux"
import { cleanExpenses, getGuideAccounting, getGuideAccountingOverview } from "store/accounting"

import { DEFAULT_RANGE, DEFAULT_TYPE, RANGE_OPTIONS, SCOUP_LIST, TYPE_OPTIONS_ENUM, TYPE_WITH_DATESWITCHER } from "constants/accounting"
import { DATETIME_FORMAT, DATE_FORMAT } from "constants/date"

export default function Header({ params, onChangeFilters }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const showDateSwitcher = TYPE_WITH_DATESWITCHER.includes(params.type)
  const needReload = location.state?.reload || false

  const changeTypeHandler = useCallback(
    (type) => {
      const showDateSwitcher = TYPE_WITH_DATESWITCHER.includes(type)
      const date = showDateSwitcher ? params.date : null
      onChangeFilters({ target: { value: type, name: "type" } })
      onChangeFilters({ target: { value: date, name: "date" } })
      if (!date) onChangeFilters({ target: { value: null, name: "range" } })
    },
    [params.date, onChangeFilters]
  )

  const changeDateHandler = (date, start, end, momentDate) =>
    onChangeFilters({
      target: {
        value: momentDate.format(DATE_FORMAT),
        name: "date"
      }
    })

  useEffect(() => {
    if (showDateSwitcher && !params.range) onChangeFilters({ target: { value: DEFAULT_RANGE, name: "range" } })
    else if (!showDateSwitcher && params.range) onChangeFilters({ target: { value: null, name: "range" } })
    //eslint-disable-next-line
  }, [params.range, showDateSwitcher])

  useEffect(() => {
    if (!params.type) changeTypeHandler(DEFAULT_TYPE)
  }, [params.type, changeTypeHandler])

  const fetchAll = () => {
    if (!params.type || !params.date || !params.range) return
    const q = {}
    const range = params.range === "week" ? "isoWeek" : params.range
    const date = params.date

    if (params.range === "month") {
      q.date_gteq = moment(date).startOf(range).startOf("isoWeek").format(DATE_FORMAT)
      q.date_lteq = moment(date).endOf(range).endOf("isoWeek").format(DATE_FORMAT)
    } else {
      q.date_gteq = moment(date).startOf(range).format(DATE_FORMAT)
      q.date_lteq = moment(date).endOf(range).format(DATE_FORMAT)
    }

    const searchParams = { include_stats: true, q }
    if (SCOUP_LIST.includes(params.type)) searchParams.scope = params.type.toLowerCase()
    dispatch(getGuideAccounting(searchParams))
  }

  const fetchOverview = () => {
    if (!params.type) return
    const searchParams = {}
    if (params.date_gteq && params.date_lteq) searchParams.q = { date_gteq: params.date_gteq, date_lteq: params.date_lteq }
    dispatch(getGuideAccountingOverview(searchParams))
  }

  const getAccountingDataHandler = async () => {
    try {
      const response = await api({ url: "/guide/accounting/export" })
      const blob = new Blob([response.data], { type: "text/csv" })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "report.csv")
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url) //
    } catch (error) {}
  }

  const fetchData = () => {
    if (!params.type) return
    if (params.type === "business_overview") fetchOverview()
    else fetchAll()
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [params.type, params.range, params.date, params.date_gteq, params.date_lteq])

  // eslint-disable-next-line
  useEffect(() => () => dispatch(cleanExpenses()), [])

  useEffect(() => {
    if (!needReload) return
    window.history.replaceState({}, document.title)
    fetchData()
    // eslint-disable-next-line
  }, [needReload])

  return (
    <div className="sticky-top mt-n30 mb-30" style={{ top: headerHeight }}>
      <div className="full-window-block bg-white py-10 py-sm-20 shadow">
        <div className={`grid grid-cols-${showDateSwitcher ? 3 : 2} gap-2 justify-content-between align-items-center w-100`}>
          <Dropdown
            className="my-n1"
            toggleButton={
              <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
                <h1 className="h3 fw-medium lh-1">{TYPE_OPTIONS_ENUM[params.type]}</h1>
                <Icon iconName="SidebarToggle" size={20} />
              </DropdownToggle>
            }
          >
            {Object.entries(TYPE_OPTIONS_ENUM).map(([type, label], index) => (
              <DropdownItem key={index} onClick={() => changeTypeHandler(type)} active={params.type === type}>
                {label}
              </DropdownItem>
            ))}
          </Dropdown>
          {showDateSwitcher && (
            <div className="hstack gap-3 mx-auto my-n1">
              <DateSwitcher
                type={params.range}
                name="date"
                value={moment(params.date)}
                onChange={changeDateHandler}
                maxDate={moment().endOf("month").format(DATETIME_FORMAT)}
              />
              <Dropdown
                className="my-n1"
                toggleButton={
                  <DropdownToggle color="primary-second" outline className="hstack gap-2 rounded-pill py-2 px-15">
                    <span className="fs-7 fw-medium lh-1">{RANGE_OPTIONS[params.range]}</span>
                    <Icon iconName="SidebarToggle" size={14} />
                  </DropdownToggle>
                }
              >
                {Object.entries(RANGE_OPTIONS).map(([range, label], index) => (
                  <DropdownItem key={index} name="range" value={range} onClick={onChangeFilters} active={params.range === range}>
                    {label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          )}
          <div className="hstack gap-2 justify-content-end my-n1">
            <Button color="primary-second" tag={Link} className="px-20 py-10 fs-7" to={routes.guideAccountingAddExpancePath({}, params)}>
              {t("expense.add")}
            </Button>
            <Button color="primary-second" outline className="p-2">
              <Icon iconName="ShareArrow" size={22} block onClick={getAccountingDataHandler} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
