import React, { useEffect, useState } from "react"
import { Offcanvas } from "react-bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { routes } from "router"
import Form from "components/clients/Form"
import Details from "components/clients/Details"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanClient, getGuideClient } from "store/clients"

export default function ClientWindow() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const location = useLocation()
  const params = useParams()
  const clientId = params?.id
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const [isOpen, setOpen] = useState(false)
  const [isChanged, setChanged] = useState(false)

  const closeHandler = isChanged
    ? confirmModal(
        {
          title: "Are you sure you want to discard all changes?",
          color: "danger",
          submitText: t("global.discard"),
          cancelText: t("global.cancel")
        },
        () => setOpen(false)
      )
    : () => setOpen(false)

  const exitHandler = () => navigate(routes.guideClientsPath())

  useEffect(() => {
    if (clientId) dispatch(getGuideClient(clientId))
    return () => dispatch(cleanClient())
  }, [clientId, dispatch])

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Offcanvas
      placement="end"
      scroll
      show={isOpen}
      onHide={closeHandler}
      onExited={exitHandler}
      container={document.getElementById("client")}
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      style={{
        top: headerHeight,
        width: 850
      }}
    >
      <Offcanvas.Body className="vstack bg-light p-20 ">
        <DefaultErrorBoundary>
          {location?.pathname.includes("show") ? <Details /> : <Form onChanged={setChanged} edit={!!clientId} />}
        </DefaultErrorBoundary>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
