import React, { memo, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import UnavailableDay from "./UnavailableDay"
import Booking from "./Booking"
import JournalNote from "./JournalNote"

import { filter, find } from "lodash"
import { isDateInRange } from "helpers/time"
import { weatherImage } from "helpers/weather"
import moment from "moment"

import { useSelector } from "react-redux"

import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date"

export const filterUnavailableDates = (unavailableDays, day) =>
  filter(
    unavailableDays,
    (unavailableDate) => unavailableDate.unavailable_type === "date" && moment(unavailableDate.unavailable_date).isSame(day, "day")
  )

export const reduceUnavailableRanges = (unavailableDays, day) =>
  unavailableDays.reduce(
    (acc, unavailableRange) => {
      if (unavailableRange.unavailable_type !== "range") return acc
      const { includes, wholeDayInRange } = isDateInRange(
        unavailableRange.all_day ? moment(unavailableRange.start_datetime).startOf("day") : unavailableRange.start_datetime,
        unavailableRange.all_day ? moment(unavailableRange.end_datetime).endOf("day") : unavailableRange.end_datetime,
        day
      )
      if (wholeDayInRange) acc.wholeDayInRange = true
      if (includes) acc.partialDayRanges.push(unavailableRange)
      return acc
    },
    { wholeDayInRange: false, partialDayRanges: [] }
  )

const Day = ({ day, today, thisMonth = true, lastCol = false, lastRow = false }) => {
  const [searchParams] = useSearchParams()
  const showWeather = searchParams.get("weather") === "true"

  const formattedDate = day.format(DATE_FORMAT)
  const isToday = today.isSame(formattedDate, "day")
  const isDisabled = day.isBefore(today, "day")
  const {
    bookings,
    journal_notes,
    unavailable_days,
    metadata: { unavailable_weekdays }
  } = useSelector((state) => state.dashboard)
  const forecast = useSelector((state) => find(state.weather.forecast, ["forecast_date", formattedDate])?.forecast_json?.daily) || {}
  const [iconCodeDay, iconCodeNight] = forecast?.daypart?.iconCode || []
  const iconCode = iconCodeDay || iconCodeNight

  const dayBookings = useMemo(() => filter(bookings, ["booked_date", formattedDate]) || {}, [bookings, formattedDate])
  const journalNote = useMemo(() => find(journal_notes, ["date", formattedDate]) || {}, [journal_notes, formattedDate])

  const dayIsUnavailableWeekday = useMemo(() => unavailable_weekdays[day.format("dddd").toLowerCase()], [unavailable_weekdays, day])
  const dayUnavailableDates = useMemo(() => filterUnavailableDates(unavailable_days, day), [unavailable_days, day])
  const dayIsUnavailableDate = !!dayUnavailableDates.length

  const { wholeDayInRange, partialDayRanges } = useMemo(() => reduceUnavailableRanges(unavailable_days, day), [unavailable_days, day])
  const isUnavailableWholeDay = dayIsUnavailableWeekday || dayIsUnavailableDate || wholeDayInRange

  const classes = ["vstack border-gray-lightest p-2 show-on-hover"]
  if (isUnavailableWholeDay) classes.push("bg-light position-relative")
  else if (!thisMonth || isDisabled) classes.push("bg-light")
  else classes.push("bg-primary-second bg-opacity-0 bg-opacity-5-hover")
  if (!lastCol) classes.push("border-end")
  if (!lastRow) classes.push("border-bottom")

  const numberClasses = ["rounded-circle px-1 m-n1 me-auto fs-7 fw-medium text-center mw-100"]
  if (isToday) numberClasses.push("bg-primary-second text-white")
  if (!thisMonth) numberClasses.push("opacity-50")

  const bookingsHTMLMap = dayBookings.map((booking) => ({
    time: booking.trip.start_time || "",
    HTML: <Booking key={booking.id} booking={booking} isDisabled={isDisabled} badge />
  }))
  const unavailableDaysHTMLMap = dayUnavailableDates.map((date) => ({
    time: "00:00",
    HTML: <UnavailableDay key={date.id} unavailableDay={date} showOnHover={isUnavailableWholeDay} badge />
  }))
  const unavailableRangeDaysHTMLMap = partialDayRanges.map((range) => {
    const startDate = moment(range.start_datetime, DATETIME_FORMAT)
    const endDate = moment(range.end_datetime, DATETIME_FORMAT)
    const startDateIsCurrent = startDate.isSame(day, "day")
    const endDateIsCurrent = endDate.isSame(day, "day")
    return {
      time: (startDateIsCurrent && startDate.format(TIME_FORMAT)) || (endDateIsCurrent && endDate.format(TIME_FORMAT)) || "",
      HTML: (
        <UnavailableDay
          key={range.id}
          unavailableDay={range}
          wholeDay={!startDateIsCurrent && !endDateIsCurrent}
          startDateIsCurrent={startDateIsCurrent}
          endDateIsCurrent={endDateIsCurrent}
          showOnHover={isUnavailableWholeDay}
          badge
        />
      )
    }
  })
  const renderList = [...bookingsHTMLMap, ...unavailableDaysHTMLMap, ...unavailableRangeDaysHTMLMap]
    .sort((a, b) => (a.time > b.time ? 1 : -1))
    .map((item) => item.HTML)

  return (
    <div className={classes.join(" ")} style={{ minHeight: 130 }}>
      <div className="hstack justify-content-between z-1">
        <div className={numberClasses.join(" ")} style={{ width: 30, lineHeight: "30px" }}>
          {day.format("DD")}
        </div>
        {showWeather ? (
          <img src={weatherImage(iconCode)} alt="" height="32" className="mx-n20 my-n1" />
        ) : (
          <JournalNote journalNote={journalNote} showOnHover={!journalNote?.id} date={formattedDate} />
        )}
      </div>
      {renderList.length ? <div className="vstack gap-1 justify-content-end mt-2 z-1">{renderList}</div> : null}
      {isUnavailableWholeDay && (
        <div
          className="position-absolute w-100 h-100 top-0 start-0"
          style={{ background: "linear-gradient(to top left, rgba(0,0,0,.01) 49.5%, rgba(0,0,0,.2),transparent 50.5%)" }}
        />
      )}
    </div>
  )
}

export default memo(Day)
