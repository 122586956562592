import React, { useEffect } from "react"
import { Link, useMatch, useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import { Button, Col, Row } from "reactstrap"
import TemplateNameModal from "components/marketing/TemplateNameModal"
import TemplateForm from "components/marketing/TemplateForm"
import TemplatePreview from "components/marketing/TemplatePreview"
import Icon from "components/common/Icon"
import StickyBox from "react-sticky-box"
import Loading from "modules/loader-watchers/Loading"
import Disable from "modules/loader-watchers/Disable"
import TextGeneratorWindow from "components/common/TextGeneratorWindow"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import useForm, { cleanNestedAttributes } from "hooks/useForm"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTemplate, getGuideTemplate, saveTemplate, setGuideTemplateFromParent } from "store/templates"
import { modelSelector } from "store/selectors"

function Template() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()

  const [searchParams] = useSearchParams()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const template = useSelector(modelSelector("template"))

  const isPersisted = useMatch(routes.guideMarketingTemplateEditPath())
  const templateID = isPersisted?.params?.id
  const isEdit = !!templateID
  const parentTemplateID = searchParams.get("parent_id")

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    {
      ...template,
      name: template.parent_id ? template.name : "",
      photos: template?.images,
      parent_id: template.parent_id || parentTemplateID
    },
    [
      "heading",
      "paragraph_text",
      "photos",
      "tags",
      "template_id",
      "signature",
      "parent_id",
      "template_type",
      "name",
      "button_url",
      "button_text",
      "social_media"
    ]
  )
  const isFirstBasic = template.category === "first_basic"

  const goBack = () => navigate(isEdit ? routes.guideMarketingTemplatesPath() : routes.guideMarketingTemplatesParentsPath())
  const goBackWithConfirm = isChanged
    ? confirmModal(
        {
          title: "Are you sure? Changed data will be lost!",
          color: "danger",
          submitText: t("global.confirm"),
          cancelText: t("global.cancel")
        },
        goBack
      )
    : goBack

  useEffect(() => {
    if (isEdit) dispatch(getGuideTemplate(templateID))
    else dispatch(setGuideTemplateFromParent(parentTemplateID))
  }, [parentTemplateID, templateID]) // eslint-disable-line

  useEffect(() => () => dispatch(cleanTemplate()), []) // eslint-disable-line

  submitCallback(() => {
    const templateAttributes = { ...form }

    if (isFirstBasic) delete templateAttributes.heading

    if (form.template_type === "email") {
      const photos = form.photos?.map((file) => ({ ...file, file: file.file }))
      templateAttributes.images_attributes = cleanNestedAttributes(photos, ["file"])
      delete templateAttributes.photos
    }

    const formData = serialize({ email_template: templateAttributes }, { indices: true })
    dispatch(saveTemplate(templateID, formData)).then(() => navigate(routes.guideMarketingTemplatesPath()))
  })

  if (!template.id) return null

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="sticky-top mt-n30 mb-30" style={{ top: headerHeight }}>
          <div className="full-window-block align-items-center bg-white py-10 py-sm-20 shadow">
            <div className="hstack gap-20 justify-content-end">
              <Button color="ghost" className="p-2 my-n1" onClick={goBackWithConfirm}>
                <Icon iconName="ArrowLeft" size={18} block />
              </Button>
              <h1 className="h3 fw-medium lh-1">{t("marketing.template_name", { name: form.name })}</h1>
            </div>
            <Button
              color="primary"
              className="px-20 py-10 fs-7 my-n1 ms-auto"
              {...(isEdit && !isChanged ? { tag: Link, to: routes.guideMarketingTemplatesPath() } : { type: "submit" })}
            >
              {t("marketing.save_template")}
            </Button>
          </div>
        </div>

        <Row className="gx-0 align-content-start position-relative">
          <Col xs={12} md={{ size: 5, order: 1 }} lg={4}>
            <StickyBox offsetTop={headerHeight + 70 + 30} offsetBottom={30}>
              <Loading name="templates.template">
                <TemplateForm form={form} changeHandler={changeHandler} template={template} isEdit={isEdit} />
              </Loading>
            </StickyBox>
          </Col>

          <Col xs={12} md={7} lg={8}>
            <StickyBox offsetTop={headerHeight + 70 + 30} offsetBottom={30}>
              <Disable name="templates.template">
                {template.id && <TemplatePreview form={form} template={template} isEdit={isEdit} />}
              </Disable>
            </StickyBox>
          </Col>
        </Row>

        {!isEdit && <TemplateNameModal form={form} changeHandler={changeHandler} isChanged={isChanged} isEdit={isEdit} />}
      </form>

      <TextGeneratorWindow name="paragraph_text" onSubmit={changeHandler} />
    </>
  )
}

export default Template
