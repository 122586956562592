import React from "react"
import { useNavigate } from "react-router-dom"
import { Col, Label, Row } from "reactstrap"
import Input from "components/form/Input"
import ActionButtons from "components/wizards/ActionButtons"
import Icon from "components/common/Icon"

import useForm, { cleanNestedAttributes, useNestedFields } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { find } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { updateGuide } from "store/user"

const Step5Form = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "user.guide" })
  const { guide } = useSelector((store) => store.user)
  const { activity_type } = guide
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(guide, ["documents"])
  const [, , addDocumentHandler, , , updateDocumentHandler] = useNestedFields(guide, "documents", ["file", "file_type"], changeHandler)
  const FIELDS = ["driver_license", "guide_license", "capitan_license", `${activity_type}_permit`, "waiver", "other"]

  const onChangeHandler =
    ({ id, _id } = {}, file_type) =>
    (event) => {
      const [file] = event.target.files
      if (!file) return

      if (id || _id) updateDocumentHandler(null, { id, _id, file, file_type })
      else addDocumentHandler(null, { file, file_type })
    }

  submitCallback(({ nativeEvent }) => {
    const documents_attributes = cleanNestedAttributes(form.documents, ["file", "file_type"])
    const guideAttributes = { ...updateStep(form), documents_attributes }

    const nextPath = nativeEvent?.submitter?.value
    const formData = serialize({ guide: guideAttributes }, { indices: true })
    dispatch(updateGuide(guide.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <form onSubmit={submitHandler}>
      <Row className="text-start">
        <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} xxl={{ size: 6, offset: 3 }}>
          <div className="vstack gap-10 bg-white rounded p-20">
            {FIELDS.map((field) => {
              const document = find(form.documents, { file_type: field })
              const isPersisted = !!document?.url
              return (
                <div key={field} className="d-flex flex-wrap">
                  <Label for={field} className="fs-6">
                    {t(`guide.documents.${field}`)}
                  </Label>
                  <div className="d-block position-relative w-100">
                    <Input
                      placeholder={t(`guide.documents.${field}`)}
                      value={document?.file?.name || document?.filename || ""}
                      className="fs-6"
                      valid={isPersisted}
                      readOnly
                    />
                    {!isPersisted && (
                      <Icon iconName="Upload" className="text-primary-second position-absolute top-50 end-0 translate-middle-y me-2" />
                    )}
                    <Input id={field} type="file" onChange={onChangeHandler(document, field)} className="cover opacity-0" />
                  </div>
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
      <ActionButtons withSkip isChanged={isChanged} action={updateGuide} name="user.guide" className="mt-50" />
    </form>
  )
}

export default Step5Form
