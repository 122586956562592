import { createSelector } from "@reduxjs/toolkit"

// HELPERS
const getTable = (name) => (state) => state[name]
const getModel = (name) => (table) => table[name] || {}
const getMetadata = (table) => table.metadata || {}

const getCollection = (table) => table.items || []
const getCollectionFirst = (collection) => collection.at(0)
const getCollectionAny = (collection) => !!collection.length

// SELECTORS

export const collectionSelector = (name) => createSelector(getTable(name), getCollection)
export const modelSelector = (name) => createSelector(getTable(`${name}s`), getModel(name))
export const metadataSelector = (name) => createSelector(getTable(name), getMetadata)

const selectCollectionFirst = createSelector(getCollection, getCollectionFirst)
export const collectionFirstSelector = (name) => createSelector(getTable(name), selectCollectionFirst)

const selectCollectionAny = createSelector(getCollection, getCollectionAny)
export const collectionAnySelector = (name) => createSelector(getTable(name), selectCollectionAny)
