import React from "react"
import { Label, Table } from "reactstrap"

import { numberToCurrency } from "helpers/string"
import { useTranslation } from "react-i18next"
import pluralize from "pluralize"

const CalculationTable = ({ calculation, noPading = false }) => {
  const { t } = useTranslation()

  const classes = ["bg-white rounded"]
  if (!noPading) classes.push("p-20 pt-10")

  return (
    <div className={classes.join(" ")}>
      <Label className="fs-6 gap-20">{t("booking.calculation.title")}</Label>
      <Table className="fs-6 mb-0 text-start border-gray-lightest" bordered style={{ maxWidth: 470 }}>
        <tbody>
          <tr>
            <td>{t("booking.calculation.prev_total")}</td>
            <td>{numberToCurrency(calculation.prevTotalPrice)}</td>
          </tr>
          {calculation.minimumRate > 0 && (
            <tr>
              <td>Minimum rate per {pluralize("person", calculation.minimumPersons, true)}</td>
              <td>{numberToCurrency(calculation.minimumRate)}</td>
            </tr>
          )}
          {calculation.adultCountDiff !== 0 && (
            <tr>
              <td>
                {pluralize("adult", calculation.adultCountDiff, true)}
                {": "}
                {numberToCurrency(calculation.pricePerPerson)}
                {calculation.minimumPersons ? ` per additional person` : ""}
              </td>
              <td>{numberToCurrency(calculation.adultPriceDiff)}</td>
            </tr>
          )}
          {calculation.childCountDiff !== 0 && (
            <tr>
              <td>
                {pluralize("child", calculation.childCountDiff, true)}
                {": "}
                {numberToCurrency(calculation.pricePerPerson)}
                {calculation.minimumPersons ? ` per additional person` : ""}
              </td>
              <td>{numberToCurrency(calculation.childPriceDiff)}</td>
            </tr>
          )}
          <tr className="fw-bold">
            <td>{t("booking.calculation.new_total")}</td>
            <td>{numberToCurrency(calculation.totalPrice)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default CalculationTable
