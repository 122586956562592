import moment from "moment"

const LOAD_TIMEOUT = 10

const nowDateTime = () => moment().format("YYYY-MM-DD HH:mm:ss")

export const LOADING_INITIAL_STATE = {
  requested_at: false,
  loading: false,
  loaded: false,
  firstLoaded: false
}
export const isTimeout = (state) => {
  if (!state) throw new Error("'state' must be provided")
  return state.requested_at && moment().isBefore(moment(state.requested_at).add(LOAD_TIMEOUT, "seconds"))
}

export const setRequested = (state, first = false) => {
  if (!state) throw new Error("'state' must be provided")
  state.loading = true
  state.loaded = false
  state.requested_at = nowDateTime()
  if (first) state.firstLoaded = false
}

export const setReceived = (state) => {
  if (!state) throw new Error("'state' must be provided")
  state.loading = false
  state.loaded = true
  state.firstLoaded = true
}

export const setFailed = (state) => {
  if (!state) throw new Error("'state' must be provided")
  state.loading = false
}
