import React, { memo, useEffect, useMemo, useState } from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { sunRiseAndSetCycles } from "helpers/weather"

import { useSelector } from "react-redux"
import { firstForecastSelector } from "store/weather"

import { TIME_FORMAT } from "constants/date"

function Sun({ className, onNextDays, onlyBody = false, maxWidth = 350 }) {
  const { t } = useTranslation()
  const firstForecast = useSelector(firstForecastSelector)
  const [sunCycles, setSunCycles] = useState([])

  const currentTime = moment(firstForecast.forecast_date).hours(moment().hours()).minutes(moment().minutes())
  const {
    start,
    end,
    overHorizon = false
  } = useMemo(() => sunCycles.find((cycle) => currentTime.isBetween(cycle.start, cycle.end)) || {}, [sunCycles, currentTime])

  useEffect(() => {
    sunRiseAndSetCycles({ lat: +firstForecast.latitude, lon: +firstForecast.longitude }, firstForecast.forecast_date).then(setSunCycles)
  }, [firstForecast.latitude, firstForecast.longitude, firstForecast.forecast_date])

  if (!start || !end) return null

  const timeToEndCycle = end.diff(currentTime, "hour")
  const sunwayDuration = end.diff(start, "minutes")
  const currentDuration = currentTime.diff(start, "minutes")

  const sunPositionPercent = currentDuration / sunwayDuration
  const sunPositionAngle = sunPositionPercent * 180 * (overHorizon ? 1 : -1) || 0

  const classes = []
  if (!onlyBody) classes.push("bg-white rounded p-25 overflow-hidden")
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      {!onlyBody && (
        <div className="hstack justify-content-between">
          <h3>{t("weather.sun")}</h3>
          <Button value="sun" onClick={onNextDays} color="primary-second" className="py-2 px-20">
            {t("weather.next_days")}
          </Button>
        </div>
      )}
      <div className={`hstack gap-30 align-items-${overHorizon ? "end" : "start"} w-100 mt-10 mb-10 ${overHorizon ? "pb-40" : "pt-20"}`}>
        <div className="text-center fs-3 mt-n20 mb-n30">
          <Icon iconName={`Sun${overHorizon ? "rise" : "set"}`} block className="mx-auto mb-1" />
          {start.format(TIME_FORMAT)}
          <div className="fs-5 text-dark text-opacity-50">{t(`weather.${overHorizon ? "sunrise" : "sunset"}`)}</div>
        </div>
        <div className="flex-fill mx-auto position-relative" style={{ maxWidth }}>
          <div
            className={`w-100 border-${overHorizon ? "bottom" : "top"} border-5 border-light position-relative`}
            style={{ paddingTop: "50%" }}
          >
            <div
              className={`border border-5 border-warning border-${overHorizon ? "bottom" : "top"}-0 h-100 w-100 position-absolute start-0 ${
                overHorizon ? "bottom" : "top"
              }-0`}
              style={{ borderRadius: overHorizon ? "1000px 1000px 0 0" : "0 0 1000px 1000px" }}
            />
            <div
              className={`position-absolute ${overHorizon ? "bottom" : "top"}-0 start-0 w-100 px-1 pointer-events-none`}
              style={{ rotate: `${sunPositionAngle}deg` }}
            >
              <div className="border border-2 border-warning rounded-circle bg-white m-n10" style={{ width: 20, height: 20 }} />
            </div>
          </div>
          <span
            className={`fs-3 text-dark text-opacity-50 text-center text-nowrap pb-2 position-absolute ${
              overHorizon ? "bottom" : "top"
            }-0 start-50 translate-middle-x`}
          >
            {t(`weather.${overHorizon ? "sunset" : "sunrise"}_in`, { hours: timeToEndCycle })}
          </span>
        </div>
        <div className="text-center fs-3 mt-n20 mb-n30">
          <Icon iconName={`Sun${overHorizon ? "set" : "rise"}`} block className="mx-auto mb-1" />
          {end.format(TIME_FORMAT)}
          <div className="fs-5 text-dark text-opacity-50">{t(`weather.${overHorizon ? "sunset" : "sunrise"}`)}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(Sun)
