import React, { useCallback, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Trip from "components/trips/Trip"
import Spinner from "components/common/Spinner"

import { isLastPage } from "helpers/pagination"
import { useTitle } from "hooks/useTitle"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTrips } from "store/trips"
import { getFavoriteTrips } from "store/favourite-trips"

export default function FavouritesPage() {
  useTitle("Favourites Trips")
  const dispatch = useDispatch()
  const { items, loading, metadata } = useSelector((state) => state.favouriteTrips)
  const hasTrips = !!items?.length

  const fetchTrips = useCallback(() => dispatch(getFavoriteTrips()), [dispatch])

  useEffect(() => {
    fetchTrips()
    return () => dispatch(cleanTrips())
    //eslint-disable-next-line
  }, [])

  return (
    <Container className="py-40">
      <h2>Favourites</h2>
      {hasTrips && <h4 className="fw-normal">Click the heart if you would like to remove a trip from favorites.</h4>}
      <Row className="gap-y-20 gx-20 gap-y-xl-35 gx-xl-35 mt-25">
        {hasTrips
          ? items.map((favoriteTrip) => (
              <Col xs={12} lg={6} key={favoriteTrip.id}>
                <Trip trip={{ ...favoriteTrip.trip, favorite_id: favoriteTrip.id }} className="h-100" withRating withModal/>
              </Col>
            ))
          : !loading && (
              <div className="bg-light p-20 rounded">
                <h2 className="h3">No Favourite Trips</h2>
              </div>
            )}
        {loading && <Spinner className="flex-fill my-20" />}
        {!isLastPage(metadata) && !loading && hasTrips && <Waypoint onEnter={() => fetchTrips(true)} />}
      </Row>
    </Container>
  )
}
