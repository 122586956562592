import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import Header from "components/marketing/Header"
import Post from "components/posts/Post"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import WithEmptyState from "components/common/WithEmptyState"

import { useTitle } from "hooks/useTitle"
import { Waypoint } from "react-waypoint"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanPosts, getGuidePosts } from "store/posts"
import { collectionAnySelector, collectionSelector, metadataSelector } from "store/selectors"

export default function Feeder() {
  useTitle("Feeder")
  const metadata = useSelector(metadataSelector("posts"))
  const posts = useSelector(collectionSelector("posts"))
  const hasPosts = useSelector(collectionAnySelector("posts"))
  const dispatch = useDispatch()

  const fetchPosts = (nextPage = false) => dispatch(getGuidePosts(nextPage))

  useEffect(() => {
    fetchPosts()
    return () => dispatch(cleanPosts())
  }, []) // eslint-disable-line

  return (
    <>
      <Header />
      <FirstLoading name="posts">
        <WithEmptyState name="post" hasItems={hasPosts} className="vstack gap-20">
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchPosts(true)} />}
          {posts.map((post) => (
            <Post post={post} key={post.id} />
          ))}
        </WithEmptyState>

        <DefaultErrorBoundary>
          <Outlet />
        </DefaultErrorBoundary>
      </FirstLoading>
    </>
  )
}
