import React from "react"
import { Button, Col, Label, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import Icon from "components/common/Icon"
import Input from "components/form/Input"
import ActionButtons from "components/wizards/ActionButtons"

import useForm, { useNestedFields, cleanNestedAttributes } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateGuide } from "store/user"
import { generateCountryOptions } from "components/bookings/helpers"

const Step2Form = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "user.guide" })
  const { guide } = useSelector((store) => store.user)
  const { first_name, last_name } = useSelector((store) => store.user)
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    {
      ...guide,
      user: {
        first_name,
        last_name
      }
    },
    [
      "display_name",
      "email",
      "phone_number",
      "company_name",
      "website",
      "description",
      "street_address",
      "address_line_2",
      "state",
      "country",
      "city",
      "zip_code",
      "expertises",
      "user",
      "facebook_url",
      "instagram_url",
      "twitter_url"
    ]
  )
  const [, , addExpertiseHandler, removeExpertiseHandler] = useNestedFields(form, "expertises", [], changeHandler)

  submitCallback(({ nativeEvent }) => {
    const expertises_attributes = cleanNestedAttributes(form.expertises, ["name"])
    const guideAttributes = { ...updateStep(form), expertises_attributes, user_attributes: form.user }
    delete guideAttributes.user

    const nextPath = nativeEvent?.submitter?.value
    const formData = serialize({ guide: guideAttributes }, { indices: true })
    dispatch(updateGuide(guide.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <Row tag="form" onSubmit={submitHandler}>
      <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} xxl={{ size: 6, offset: 3 }}>
        <div className="vstack gap-15 bg-white rounded p-20">
          <h2 className="h4">Your Details</h2>
          <Row className="gap-y-15 gx-10">
            <Col xs={12} sm={6}>
              <Label for="first_name" className="fs-6">
                {t("sign_up.first_name")}*
              </Label>
              <Input
                id="first_name"
                type="text"
                name="user.first_name"
                placeholder={t("sign_up.first_name")}
                value={form.user.first_name || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </Col>
            <Col xs={12} sm={6}>
              <Label for="last_name" className="fs-6">
                {t("sign_up.last_name")}*
              </Label>
              <Input
                id="last_name"
                type="text"
                name="user.last_name"
                placeholder={t("sign_up.last_name")}
                value={form.user.last_name || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </Col>
          </Row>
          <div>
            <Label for="display_name" className="fs-6">
              {t("guide.display_name")}*
            </Label>
            <Input
              id="display_name"
              type="text"
              name="display_name"
              placeholder={t("guide.display_name")}
              value={form.display_name || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="email" className="fs-6">
              {t("guide.email")}*
            </Label>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder={t("guide.email")}
              value={form.email || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="phone_number" className="fs-6">
              {t("guide.phone_number")}*
            </Label>
            <Input
              id="phone_number"
              type="tel"
              name="phone_number"
              placeholder={t("guide.phone_number")}
              value={form.phone_number || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="company_name" className="fs-6">
              {t("guide.company_name")}*
            </Label>
            <Input
              id="company_name"
              type="text"
              name="company_name"
              placeholder={t("guide.company_name")}
              value={form.company_name || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="website" className="fs-6">
              {t("guide.website")}
            </Label>
            <Input
              id="website"
              type="url"
              name="website"
              placeholder={t("guide.website")}
              value={form.website || ""}
              onChange={changeHandler}
              className="fs-6"
            />
          </div>
        </div>
        <div className="vstack gap-15 bg-white rounded p-20 mt-20">
          <h2 className="h4">Guide Details</h2>
          <h3 className="h5">Address</h3>
          <div>
            <Label for="street_address" className="fs-6">
              {t("guide.address.street_address")}
            </Label>
            <Input
              id="street_address"
              type="text"
              name="street_address"
              placeholder={t("guide.address.street_address")}
              value={form.street_address || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="address_line_2" className="fs-6">
              {t("guide.address.address_line_2")}
            </Label>
            <Input
              id="address_line_2"
              type="text"
              name="address_line_2"
              placeholder={t("guide.address.address_line_2")}
              value={form.address_line_2 || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="state" className="fs-6">
              {t("guide.address.state")}
            </Label>
            <Input
              id="state"
              type="text"
              name="state"
              placeholder={t("guide.address.state")}
              value={form.state || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="city" className="fs-6">
              {t("guide.address.city")}
            </Label>
            <Input
              id="city"
              type="text"
              name="city"
              placeholder={t("guide.address.city")}
              value={form.city || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <Row className="gap-y-15 gx-10">
            <Col xs={12} sm={6}>
              <Label for="country" className="fs-6">
                {t("guide.address.country")}
              </Label>
              <Input
                id="country"
                type="select"
                name="country"
                placeholder={t("guide.address.country")}
                value={form.country || ""}
                onChange={changeHandler}
                className={["fs-6", form.country ? "" : "text-gray-light"].join(" ")}
                withError
              >
                {generateCountryOptions()}
              </Input>
            </Col>
            <Col xs={12} sm={6}>
              <Label for="zip_code" className="fs-6">
                {t("guide.address.zip_code")}
              </Label>
              <Input
                id="zip_code"
                type="text"
                name="zip_code"
                placeholder={t("guide.address.zip_code")}
                value={form.zip_code || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </Col>
          </Row>
          <h3 className="h5 mt-2">About</h3>
          <div>
            <Label for="description" className="fs-6">
              {t("guide.description")}
            </Label>
            <Input
              id="description"
              type="textarea"
              rows={10}
              name="description"
              placeholder={t("guide.description")}
              value={form.description || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <h3 className="h5 mt-2">{t("guide.expertises.title")}</h3>
            <p className="fs-6 text-dark text-opacity-50 mb-2">{t("guide.expertises.subtitle")}</p>
            <div className="vstack gap-2">
              {form.expertises?.map(
                (expertise, index) =>
                  (expertise.id || expertise._id) &&
                  !expertise._destroy && (
                    <div key={expertise.id || expertise._id} className="hstack gap-2">
                      <Input
                        id="expertise"
                        type="text"
                        name={`expertises[${index}]name`}
                        placeholder={t("guide.expertises.title")}
                        value={expertise.name || ""}
                        onChange={changeHandler}
                        className="fs-6"
                      />
                      <Button
                        color="link link-dark"
                        className="p-1 m-n1 border-0 bg-dark-hover bg-opacity-25-hover"
                        onClick={() => removeExpertiseHandler(expertise)}
                      >
                        <Icon iconName="Remove" className="d-block rounded-circle border border-dark" />
                      </Button>
                    </div>
                  )
              )}
              <Button
                color="link link-dark text-decoration-none"
                className="p-1 pe-3 m-n1 border-0 bg-dark-hover bg-opacity-25-hover me-auto d-flex"
                onClick={addExpertiseHandler}
              >
                <Icon iconName="Add" className="d-block rounded-circle border border-dark" />
                <span className="fs-7 ms-2 my-auto">Add</span>
              </Button>
            </div>
          </div>
          <h3 className="h5 mt-2">Social Networks</h3>
          <div>
            <Label for="facebook_url" className="fs-6">
              {t("guide.facebook_url")}
            </Label>
            <Input
              id="facebook_url"
              type="url"
              rows={10}
              name="facebook_url"
              placeholder={t("guide.facebook_url")}
              value={form.facebook_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="instagram_url" className="fs-6">
              {t("guide.instagram_url")}
            </Label>
            <Input
              id="instagram_url"
              type="url"
              rows={10}
              name="instagram_url"
              placeholder={t("guide.instagram_url")}
              value={form.instagram_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
          <div>
            <Label for="twitter_url" className="fs-6">
              {t("guide.twitter_url")}
            </Label>
            <Input
              id="twitter_url"
              type="url"
              rows={10}
              name="twitter_url"
              placeholder={t("guide.twitter_url")}
              value={form.twitter_url || ""}
              onChange={changeHandler}
              className="fs-6"
              withError
            />
          </div>
        </div>
        <ActionButtons isChanged={isChanged} action={updateGuide} name="user.guide" className="mt-50" />
      </Col>
    </Row>
  )
}

export default Step2Form
