import React from "react"
import { useSearchParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
import Widget from "components/accounting/Widget"
import DayDetails from "components/accounting/DayDetails"
import StickyBox from "react-sticky-box"

import { useSelector } from "react-redux"
import { collectionAnySelector, collectionSelector } from "store/selectors"

import { STATS_NAMES } from "constants/accounting"

export default function Details() {
  const [searchParams] = useSearchParams()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const items = useSelector(collectionSelector("accounting"))
  const hasItems = useSelector(collectionAnySelector("accounting"))

  const type = searchParams.get("type")
  const stats_name = STATS_NAMES[type]

  return (
    <>
      <Row className="justify-content-start gap-y-30 position-relative">
        <Col md={5} lg={4} xl={3}>
          <StickyBox offsetTop={headerHeight + 70 + 30} offsetBottom={30}>
            <div className="vstack gap-15">
              <Widget stats_name={stats_name} isCurrency />
              <Widget stats_name="booking" />
            </div>
          </StickyBox>
        </Col>
        <Col md={7} lg={8} xl={7}>
          {hasItems ? (
            <div className="vstack gap-20">
              {items.map((accounting) =>
                Object.entries(accounting).map(([date, details]) => <DayDetails key={date} date={date} details={details} />)
              )}
            </div>
          ) : (
            <div className="bg-white p-20 rounded">
              <h2 className="h3 fw-normal">No operations at the moment</h2>
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}
