import React, { memo } from "react"
import { Label, TabPane } from "reactstrap"
import Input from "components/form/Input"
import CustomDatepicker from "components/form/CustomDatepicker"

import { useTranslation } from "react-i18next"

const Range = ({ form, changeHandler }) => {
  const { t } = useTranslation()

  return (
    <TabPane tabId="range">
      <Label className="fs-6">{t("unavailable_day.labels.range")}</Label>
      <div className="grid grid-cols-1 grid-cols-sm-2 mb-20">
        <CustomDatepicker
          type={form.all_day ? "date" : "datetime-local"}
          sideBySide
          name="start_datetime"
          label={t("global.from")}
          value={form.start_datetime || ""}
          onChange={changeHandler}
          labelClassName="fs-6 fw-normal text-dark text-opacity-50 text-uppercase"
          inputClassName="fs-6"
          noIcon
        />
        <CustomDatepicker
          type={form.all_day ? "date" : "datetime-local"}
          sideBySide
          name="end_datetime"
          label={t("global.to")}
          value={form.end_datetime || ""}
          onChange={changeHandler}
          labelClassName="fs-6 fw-normal text-dark text-opacity-50 text-uppercase"
          inputClassName="fs-6"
          noIcon
        />
      </div>
      <Label check className="hstack gap-10 fs-7 fw-normal mb-10">
        <Input type="checkbox" name="all_day" checked={[true, "true"].includes(form.all_day)} onChange={changeHandler} className="mt-0" />
        <span className=
        {`text-dark ${[true, "true"].includes(form.all_day) ? "" : "text-opacity-50"}`}
        >{t(`unavailable_day.labels.all_day`)}</span>
      </Label>
      <Label check className="hstack gap-10 fs-7 fw-normal">
        <Input
          type="checkbox"
          name="recurring_yearly"
          checked={[true, "true"].includes(form.recurring_yearly)}
          onChange={changeHandler}
          className="mt-0"
        />
        <span className=
        {`text-dark ${[true, "true"].includes(form.recurring_yearly) ? "" : "text-opacity-50"}`}
        >{t(`unavailable_day.labels.recurring_yearly`)}</span>
      </Label>
    </TabPane>
  )
}

export default memo(Range)
