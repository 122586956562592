import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { useTranslation } from "react-i18next"
import { useWizardNavigation } from "hooks/wizard"

const ActionButtons = ({
  isChanged,
  action,
  name = "",
  stepsKey = name,
  withBack = true,
  withSkip = false,
  nextText = "",
  backText = "",
  skipText = "",
  submitText = "",
  showSubmitAfterCompleted = true,
  className,
  btnColor = "primary-second",
  nextClassName,
  backClassName,
  skipClassName,
  children
}) => {
  const { t } = useTranslation()
  const [isCurrentWizardStep, prevStepPath, nextStepPath, skipStep, submitWizard, wizardCompleted] = useWizardNavigation({
    name,
    stepsKey,
    action
  })
  const classes = ["hstack gap-10 justify-content-center"]
  if (className) classes.push(className)
  const style = { minWidth: 100 }

  return nextStepPath ? (
    <>
      <div className={classes.join(" ")}>
        {withBack && prevStepPath && (
          <Button color={btnColor} className={backClassName} outline tag={Link} to={prevStepPath}>
            {backText || t("global.go_back")}
          </Button>
        )}
        {nextStepPath && (isChanged || isCurrentWizardStep) ? (
          <Button color={btnColor} className={nextClassName} type="submit" disabled={!isChanged} value={nextStepPath} style={style}>
            {nextText || t("global.next")}
          </Button>
        ) : (
          <Button color={btnColor} className={nextClassName} tag={Link} to={nextStepPath} style={style}>
            {nextText || t("global.next")}
          </Button>
        )}
        {children}
      </div>
      {withSkip && nextStepPath && !wizardCompleted && (
        <div className="text-center mt-2">
          <Button color="link link-dark fs-6" className={skipClassName} onClick={skipStep}>
            {skipText || t("global.skip_step")}
          </Button>
        </div>
      )}
    </>
  ) : (
    <div className={classes.join(" ")}>
      {!(!showSubmitAfterCompleted && wizardCompleted) && (
        <Button color={btnColor} className={nextClassName} onClick={submitWizard} style={style}>
          {submitText || t("global.submit")}
        </Button>
      )}
      {children}
    </div>
  )
}

export default ActionButtons
