import React, { useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Button, TabContent, TabPane } from "reactstrap"
import PaymentOptionList from "../form/PaymentOptionList"
import PaymentMethodList from "../form/PaymentMethodList"
import PaymentMethodNew from "../form/PaymentMethodNew"
import Spinner from "components/common/Spinner"
import InputError from "components/form/InputError"
import { toaster } from "components/common/Toast"

import { useWizardSteps } from "hooks/wizard"
import { numberToCurrency } from "helpers/string"
import { calcPayToday, calculateBookingTotal } from "components/bookings/helpers"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { dynamicRoute } from "router/routes"

import { useDispatch, useSelector } from "react-redux"
import { createBookingPaymentIntent, recievedPay, requestedPay, updateBooking } from "store/bookings"

import { useStripe, useElements } from "@stripe/react-stripe-js"
import { receiveErrors, resetErrors } from "modules/errors/reducer"
import { modelSelector } from "store/selectors"

import { PAYMENT_FULL_CASH_ON_DAY, PAYMENT_TYPES } from "components/bookings/constants"
import { STEP_PATHS } from "constants/wizard"

// const withFakePM = (stripeCards) => [{ id: "pm_card_visa_chargeDeclined", brand: "visa", last4: "<<DECLINE>>" }, ...stripeCards]
const withFakePM = (stripeCards) => [...stripeCards]

const Step3Form = () => {
  const { t } = useTranslation()
  const { name, stepsKey } = useOutletContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name, stepsKey })
  const stripe = useStripe()
  const elements = useElements()

  const [clientSecret, setClientSecret] = useState(null)
  const activeTab = clientSecret ? "checkout" : "payment_type"
  const title = t(`booking.wizard.step_3_${activeTab}.title`)
  const subtitle = t(`booking.wizard.step_3_${activeTab}.subtitle`)

  const booking = useSelector(modelSelector("booking"))
  const { trip, payment_status, wizard_completed, stripe_cards = [], payLoading } = booking
  const { stripe_account_data = {} } = trip.guide
  const isStripeConnected = !!stripe_account_data
  const isStripeChargeEnabled = stripe_account_data.charges_enabled
  const isNotPendingAndCompleted = payment_status !== "pending" && wizard_completed

  const paymentOptionsListName =
    (trip.cash_payment_allowed && trip.deposit_required && "cash_and_deposit") ||
    (trip.cash_payment_allowed && "cash") ||
    (trip.deposit_required && "deposit") ||
    "default"
  const paymentOptions = PAYMENT_TYPES[paymentOptionsListName]

  const [paymentOption, setPaymentOption] = useState(
    isStripeConnected && isStripeChargeEnabled
      ? booking.payment_option
      : (paymentOptions.includes(PAYMENT_FULL_CASH_ON_DAY) && PAYMENT_FULL_CASH_ON_DAY) || null
  )
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [saveCard, setSaveCard] = useState(false)

  const isPaymentMethodBlank = !paymentMethod || (paymentMethod instanceof Object && !Object.keys(paymentMethod).length)
  const disabledSubmit = !stripe || isPaymentMethodBlank

  const isFullAmountOnDay = paymentOption === "full_amount_in_cash_on_day"
  const selectedGuests = +booking.adult_count + +booking.child_count
  const totalPrice = calculateBookingTotal(trip, selectedGuests)
  const payToday = calcPayToday(paymentOption, totalPrice, trip.deposit_percentage)
  const payLater = totalPrice - payToday
  const platformFee = +payToday * 0.2
  const payTodayWithFee = payToday + platformFee

  const getPaymentIntent = () =>
    dispatch(resetErrors()) &&
    dispatch(createBookingPaymentIntent(booking.id, { payment_option: paymentOption })).then(
      ({ client_secret, payment_status }) =>
        setClientSecret(client_secret) ||
        (payment_status === "succeeded" && toaster.success({ text: `Payment is already <b>"${payment_status}"</b>!` }))
    )

  const confirmPayment = async (clientSecret) => {
    if (!stripe || !elements || isPaymentMethodBlank) return false
    dispatch(requestedPay())
    dispatch(resetErrors())
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
        ...(saveCard && { setup_future_usage: "on_session" })
      })
      dispatch(recievedPay())

      if (error) {
        if (error.type === "card_error")
          dispatch(
            receiveErrors({
              data: { message: { card_error: error.message } },
              showToast: false
            })
          )
        else toaster.error(error.message)
        return false
      }

      return paymentIntent.status === "succeeded"
    } catch (error) {
      toaster.error({ title: error.name, text: error.message })
      dispatch(recievedPay())
      return false
    }
  }

  const nextStep = async () => {
    const nextPath = STEP_PATHS[stepsKey]?.confirm || ""
    const nextPathWithParams = nextPath ? dynamicRoute(nextPath)({ uuid: booking.uuid }) : null
    const attributes = {
      status: "booked",
      wizard_completed: true
    }
    const formData = serialize({ booking: updateStep(attributes) })
    dispatch(updateBooking(booking.uuid, formData)).then(() => nextPathWithParams && navigate(nextPathWithParams))
  }

  const submit = async (event) => {
    event && event.preventDefault()
    const isConfirmed = await confirmPayment(clientSecret)
    if (!isConfirmed) return
    nextStep()
  }

  return (
    <form onSubmit={submit} className="vstack position-relative">
      <div>
        {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
        <div className="hstack justify-content-between gap-20">
          {subtitle && <p className="fs-4 mt-15 mb-20 text-dark">{subtitle}</p>}
          {activeTab === "checkout" && (
            <Button color="link" className="link link-primary-second fw-semibold" onClick={() => setClientSecret("")}>
              Change payment option
            </Button>
          )}
        </div>
      </div>
      <TabContent activeTab={activeTab} className="w-100">
        <TabPane tabId="payment_type">
          <div className="vstack gap-15 mt-30">
            <PaymentOptionList
              paymentOptions={paymentOptions}
              active={paymentOption}
              onChange={setPaymentOption}
              totalPrice={totalPrice}
              depositPercentage={trip.deposit_percentage}
              disabled={isNotPendingAndCompleted}
              canCharge={isStripeConnected && isStripeChargeEnabled}
            />
            {paymentOption && (
              <>
                <div className="border-bottom border-gray-lightest py-10">
                  <div className="hstack gap-20 justify-content-between fs-3 fw-semibold">
                    <span className="text-dark text-opacity-50">{t("booking.wizard.step_3_payment_type.amount_paid_today")}</span>
                    <span>{numberToCurrency(payToday)}</span>
                  </div>
                  {payLater > 0 && (
                    <div className="hstack gap-20 justify-content-between fs-7 fw-medium">
                      <span className="text-dark text-opacity-50">{t("booking.wizard.step_3_payment_type.amount_paid_later")}</span>
                      <span>{numberToCurrency(payLater)}</span>
                    </div>
                  )}
                </div>
                {isFullAmountOnDay ? (
                  <Button color="primary" className="w-100 text-uppercase" onClick={nextStep} disabled={isNotPendingAndCompleted}>
                    {t("global.book")}
                  </Button>
                ) : (
                  <Button color="primary" className="w-100 text-uppercase" onClick={getPaymentIntent} disabled={isNotPendingAndCompleted}>
                    {t("global.next")}
                  </Button>
                )}
              </>
            )}
          </div>
        </TabPane>

        <TabPane tabId="checkout">
          <div className="vstack gap-15 mb-30">
            <PaymentMethodList paymentMethods={withFakePM(stripe_cards)} active={paymentMethod} onChange={setPaymentMethod} />
            <PaymentMethodNew
              active={paymentMethod}
              saveCard={saveCard}
              onChange={(paymentMethod) => setPaymentMethod(paymentMethod)}
              onSaveCard={setSaveCard}
            />
            <InputError field="card_error" />
          </div>

          <div className="mt-25">
            <h4 className="fw-bold hstack justify-content-between mb-15">
              Trip Total <span>{numberToCurrency(payToday)}</span>
            </h4>
            <h4 className="fw-bold hstack justify-content-between mb-15">
              Platform Fee <span>{numberToCurrency(platformFee)}</span>
            </h4>
            <h3 className="fw-bold hstack justify-content-between py-15 border-top border-bottom border-gray-lightest">
              Total Amount <span>{numberToCurrency(payTodayWithFee)}</span>
            </h3>
          </div>
          <Button color="primary" className="w-100 mt-20 text-uppercase" disabled={disabledSubmit || isNotPendingAndCompleted}>
            {t("global.book")}
          </Button>
        </TabPane>
      </TabContent>

      {payLoading && <Spinner absolute className="vw-100 vh-100 bg-white bg-opacity-0 backdrop-blur-3" />}
    </form>
  )
}

export default Step3Form
