import React from "react"
import BookingDetails from "components/bookings/BookingDetails"

import moment from "moment"
import { groupBy } from "lodash"

import { useSelector } from "react-redux"
import { collectionSelector } from "store/selectors"

import { DATE_FORMAT, MONTH_FORMAT } from "constants/date"

export default function Details({ params }) {
  const bookings = useSelector(collectionSelector("bookings"))
  const isGroupByMonth = ["all", "past"].includes(params.type)
  const format = isGroupByMonth ? MONTH_FORMAT : DATE_FORMAT
  const groupedItems = groupBy(bookings, ({ booked_date }) => moment(booked_date).format(format))
  const groups = Object.keys(groupedItems).sort((a, b) => ((params.sort === "asc" ? a < b : a > b) ? -1 : 1))

  return (
    <div className="vstack gap-20">
      {groups.map((groupedDate, index) => (
        <BookingDetails key={index} date={groupedDate} bookings={groupedItems[groupedDate]} isGroupByMonth={isGroupByMonth} />
      ))}
    </div>
  )
}
