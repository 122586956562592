import React from "react"
import { Button, Label, FormGroup, Table, DropdownToggle, Input } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "router"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Spinner from "components/common/Spinner"
import Icon from "components/common/Icon"
import ArrayInput from "components/form/ArrayInput"
import Avatar from "components/common/Avatar"
import GroupSelector from "./GroupSelector"

import useForm, { useNestedFields } from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"

import { updateClient, editClientNoteTrigger, saveClient } from "store/clients"
import { createConversation } from "store/conversations"

import { TYPE_OPTIONS_ENUM } from "components/bookings/constants"

const Details = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { client } = useSelector((store) => store.clients)
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...client }, ["notes", "client_groups"])
  const [, , addNoteHandler, removeNoteHandler] = useNestedFields(client, "notes", [], changeHandler)

  submitCallback(() => {
    const guide_client = {
      notes_attributes: form.notes
    }
    delete guide_client.user
    const formData = serialize({ guide_client }, { indices: true })
    dispatch(saveClient(client.id, formData))
  })

  function deleteNoteHandler(noteID) {
    const note = client.notes.find((i) => i.id === noteID)
    dispatch(updateClient(client.id, { notes_attributes: { ...note, _destroy: true } }))
  }

  function cashPaymentHandler(value) {
    dispatch(updateClient(client.id, { cash_payment: value }))
  }

  function editNoteHandler(note) {
    dispatch(editClientNoteTrigger(note))
  }

  function updateNoteHandler(event, note) {
    event.persist()
    dispatch(updateClient(client.id, { notes_attributes: { ...note, content: event.target.value } }))
  }

  const contactHandler = (type) => {
    if (type === "message")
      dispatch(createConversation({ participant_id: client.user_id })).then((conversation) =>
        navigate(routes.guideMessagesPath({}, { conversation_id: conversation.id }))
      )
    else if (type === "email") navigate(routes.guideClientEmailActionPath({}, { user_ids: [client.id] }))
    else if (type === "text") navigate(routes.guideClientTextActionPath({}, { user_ids: [client.id] }))
  }

  const addGroupHandler = (client) => (group_id) => {
    const group_clients_attributes = [...client.client_groups, { group_id }]
    const data = serialize({ guide_client: { group_clients_attributes } }, { indices: true })
    dispatch(updateClient(client.id, data))
  }

  const removeGroupHandler = (client) => (group_id) => {
    const group_clients_attributes = client.client_groups.map((group) => ({
      ...group,
      ...(group_id === group.group_id && { _destroy: true })
    }))
    const data = serialize({ guide_client: { group_clients_attributes } }, { indices: true })
    dispatch(updateClient(client.id, data))
  }

  return client.loading ? (
    <Spinner className="flex-fill" />
  ) : (
    <form onSubmit={submitHandler}>
      <div className="vstack gap-20">
        <div className="vstack gap-30 bg-white rounded p-20 ">
          <div className="hstack gap-20 align-items-center">
            <Avatar user={client.user} size={64} />
            <div className="vstack gap-2 justify-content-center">
              <Label className="m-0">{client.user.first_name + " " + client.user.last_name}</Label>
              {client.user.last_trip_date && <div className="opacity-50">{client.user.last_trip_date}</div>}
              <GroupSelector
                value={form.client_groups.map((group) => group.group_id)}
                onAdd={addGroupHandler(client)}
                onRemove={removeGroupHandler(client)}
                className="me-auto"
              />
            </div>
            <div className="hstack justify-content-end my-n1">
              {client.invited_by_guide && (
                <Link className="link link-dark fw-medium ms-10 mx-10" to={routes.guideEditClientPath({ id: client.id })}>
                  <Icon iconName={"EditForm"} />
                </Link>
              )}
              <Dropdown
                className="my-n1"
                toggleButton={
                  <DropdownToggle color="primary" className="fs-7">
                    {t("global.contact")}
                  </DropdownToggle>
                }
              >
                {Object.entries(TYPE_OPTIONS_ENUM).map(([type, label], index) => (
                  <DropdownItem key={index} onClick={() => contactHandler(type)}>
                    {label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          </div>
          <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
            <div>
              <Label className="fs-6">{"Contact details"}</Label>
              <div className="opacity-50">{client.user.email}</div>
              <div className="opacity-50">{client.user.phone_number}</div>
            </div>
            <div>
              <Label className="fs-6">{"Address"}</Label>
              <div className="opacity-50">{(client.user.address_line_1 || "—") + " " + (client.user.address_line_2 || "—")}</div>
              <div className="opacity-50">{(client.user.city || "—") + " " + (client.user.state || "—")}</div>
              <div className="opacity-50">{client.user.zip_code || "—"}</div>
            </div>
            <div>
              <Label className="fs-6">{"Preferences"}</Label>
              {client?.booking_preferences?.total_guests_count && (
                <div className="opacity-50">{"Usual group size: " + client?.booking_preferences?.total_guests_count}</div>
              )}
              {client.booking_preferences?.target_species.length ? (
                <div className="opacity-50">
                  {"Target species: "} {client.booking_preferences?.target_species.length.map((i) => i + ",")}
                </div>
              ) : null}
              <div className="opacity-50">
                <FormGroup switch className="hstack justify-content-between m-0 p-0">
                  <Label>
                    <div className="fs-5 fw-normal">{t("client.allow_cash_payment")}</div>
                  </Label>
                  <Input
                    id={"cash_payment"}
                    name={"cash_payment"}
                    type="checkbox"
                    checked={client.cash_payment || false}
                    onChange={() => cashPaymentHandler(!client.cash_payment)}
                    className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
                  />
                </FormGroup>
              </div>
            </div>
            <div>
              <Label className="fs-6">{"Birthday"}</Label>
              <div className="opacity-50">{client.user.birthday}</div>
              <Label className="fs-6">{"Total amount spent"}</Label>
              <div className="opacity-50">{client.total_booking_amount}</div>
            </div>
          </div>
          <div>
            <Label for="notes" className="fs-6">
              {t("client.notes")}
            </Label>
            {client.notes.length ? (
              <Table hover>
                <thead>
                  <tr className="text-uppercase border-bottom border-gray-lightest h6 opacity-50">
                    <th className="w-25">Date</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {client.notes.map((note, index) => (
                    <React.Fragment key={note.id}>
                      <tr className="border-bottom border-gray-lightest fs-5 fw-normal">
                        <td>{note.date}</td>
                        <td style={{ width: 670 }}>{note.content}</td>
                        <td>
                          <Dropdown
                            className="my-n1"
                            toggleButton={
                              <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
                                <Icon iconName={"Points"} size={15} />
                              </DropdownToggle>
                            }
                          >
                            <DropdownItem onClick={() => editNoteHandler(note)}>{"Edit"}</DropdownItem>{" "}
                            <DropdownItem onClick={() => deleteNoteHandler(note.id)}>{"Delete"}</DropdownItem>
                          </Dropdown>
                        </td>
                      </tr>
                      {note.isEdit && (
                        <tr className="border-bottom border-gray-lightest fs-5 fw-normal">
                          <td colSpan={3}>
                            <Input onBlur={(event) => updateNoteHandler(event, note)} defaultValue={note.content} type="textarea" />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            ) : null}
            <ArrayInput
              form={form}
              name="notes"
              field="content"
              onBlur={"submit"}
              type="textarea"
              changeHandler={changeHandler}
              addHandler={addNoteHandler}
              removeHandler={removeNoteHandler}
              placeholder={t("global.add_an_item")}
              className="grid-cols-1"
            />
            <div className="d-flex justify-content-end ">
              <Button className={"fs-7 "} type={"submit"} disabled={!isChanged}>
                {"Add note"}
              </Button>
            </div>
          </div>
          {client.last_bookings?.length ? (
            <div>
              <Label className="fs-6">{"Past trips"}</Label>
              <Table hover>
                <thead>
                  <tr className="text-uppercase border-bottom border-gray-lightest h6 opacity-50">
                    <th className="w-25">Date</th>
                    <th>Trip</th>
                    <th>Amount Spent</th>
                  </tr>
                </thead>
                <tbody>
                  {client.last_bookings.map((booking) => (
                    <tr key={booking.id} className="border-bottom border-gray-lightest fs-5 fw-normal">
                      <td>{booking.booked_date}</td>
                      <td>{booking.title}</td>
                      <td>{booking.booking_total_amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
    </form>
  )
}

export default Details
