import React, { memo } from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import { NavLink as ReactNavLink } from "react-router-dom"
import { routes } from "router"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

function Navigation() {
  const { t } = useTranslation()
  const userRole = useSelector((store) => store.user.role)
  const role = userRole === "guide_user" ? "guide" : "client"

  return (
    <Nav className="mx-n15 mt-40 mb-50">
      <NavItem>
        <NavLink tag={ReactNavLink} to={routes[`${role}SettingsRootPath`]()} end>
          {t("user.settings.details")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={routes[`${role}SettingsPaymentPath`]()}>
          {t("user.settings.payment")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={routes[`${role}SettingsPasswordPath`]()}>
          {t("user.settings.security")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={routes[`${role}SettingsNotificationsPath`]()}>
          {t("user.settings.notifications")}
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default memo(Navigation)
