import React from "react"
import { Button, Modal, ModalBody } from "reactstrap"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { resetModal, updateModal } from "./reducer"
import { confirmModalSelector } from "./selectors"

export default function ConfirmModal() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useSelector(confirmModalSelector)
  const {
    isOpen = false,
    title = "Confirm modal",
    color = "primary-second",
    submitText = t("global.submit"),
    cancelText = t("global.cancel"),
    props
  } = confirmModal
  const style = { minWidth: 120 }

  const submitModal = (submit) => dispatch(updateModal("confirmModal", { submit, isOpen: false }))
  const keyUpHandler = (e) => e.code === "Enter" && isOpen && submitModal(true)
  const cancelHandler = () => submitModal(false)
  const submitHandler = () => submitModal(true)
  const onOpenedHandler = () => document.addEventListener("keyup", keyUpHandler)
  const onClosedHandler = () => document.removeEventListener("keyup", keyUpHandler) || dispatch(resetModal("confirmModal"))

  return (
    <Modal
      size="sm"
      centered
      scrollable
      backdropClassName="backdrop-blur-3"
      contentClassName="rounded-sm-3"
      {...props}
      isOpen={isOpen}
      toggle={cancelHandler}
      onClosed={onClosedHandler}
      onOpened={onOpenedHandler}
    >
      <ModalBody className="px-25 py-20 text-center">
        <div className="fs-3 fw-medium lh-sm">
          <p className="small m-0" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className="hstack gap-2 justify-content-center mt-30">
          <Button color={color} outline className="px-5 text-white-hover" onClick={cancelHandler} style={style}>
            {cancelText}
          </Button>
          <Button color={color} className="px-5 text-white" onClick={submitHandler} style={style}>
            {submitText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
