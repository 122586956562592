import React, { memo } from "react"
import { Label } from "reactstrap"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
import { brandImage } from "components/bookings/helpers"

const PaymentMethodList = ({ paymentMethods, active, onChange: changeHandler }) => {
  const { t } = useTranslation()

  return paymentMethods.map((card) => (
    <Label key={card.id} check className="bg-white rounded shadow overflow-hidden">
      <div
        className={[
          "hstack gap-10 fs-7 p-20 rounded bg-opacity-5 border",
          active === card.id ? "bg-primary-second border-primary-second" : "bg-white border-gray-lightest"
        ].join(" ")}
      >
        <img src={brandImage(card.brand)} alt={card.brand} className="d-block" />
        <div>
          <div className="fw-medium">
            {t("booking.wizard.step_3_checkout.labels.last4", { brand: card.brand.toUpperCase(), last4: card.last4 })}
          </div>
          <div className="text-dark text-opacity-50 fw-normal">
            {t("booking.wizard.step_3_checkout.labels.expiry", { exp_month: card.exp_month, exp_year: card.exp_year })}
          </div>
        </div>
        <Input
          type="radio"
          name="payment_method_id"
          value={card.id}
          checked={active === card.id}
          onChange={() => changeHandler(card.id)}
          className="border-primary-second mt-0 ms-auto"
          style={{ width: 20, height: 20 }}
        />
      </div>
    </Label>
  ))
}

export default memo(PaymentMethodList)
