import React, { useEffect, useMemo, useState } from "react"
import { Outlet } from "react-router-dom"
import Sidebar from "components/dashboard/Sidebar"
import Header from "components/dashboard/Header"
import Calendar from "components/dashboard/Calendar"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { isEqual, omit } from "lodash"
import { useTitle } from "hooks/useTitle"
import useSearchFilter from "hooks/useSearchFilter"
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { getDashboardData } from "store/dashboard"
import { guideForecast } from "store/weather"

import { DATE_FORMAT } from "constants/date"

function Dashboard() {
  useTitle("Dashboard")
  const dispatch = useDispatch()
  const { headerHeight, height } = useSelector((store) => store.window)
  const [params, updateSearchFilters] = useSearchFilter({
    accessAttributes: ["range", "date", "weather", "trip_id", "journal_date", "unavailable_id"]
  })
  const [paramsState, setParamsState] = useState(params)

  const dates = useMemo(() => {
    if (!params.date || !params.range) return {}
    const range = params.range === "week" ? "isoWeek" : params.range
    const date = params.date
    const dates = {}

    if (params.range === "month") {
      dates.start = moment(date).startOf(range).startOf("isoWeek").format(DATE_FORMAT)
      dates.end = moment(date).endOf(range).endOf("isoWeek").format(DATE_FORMAT)
    } else {
      dates.start = moment(date).startOf(range).format(DATE_FORMAT)
      dates.end = moment(date).endOf(range).format(DATE_FORMAT)
    }

    return dates
  }, [params.date, params.range])

  const changeHandler = ({ target }) =>
    setParamsState((prevParamsState) => ({
      ...prevParamsState,
      [target.name]: target.value
    }))

  useEffect(() => {
    if (isEqual(paramsState, omit(params, ["journal_date", "unavailable_id"]))) return
    updateSearchFilters(paramsState)
    //eslint-disable-next-line
  }, [paramsState])

  useEffect(() => {
    if (!dates.start || !dates.end) return
    const data = {}

    data.include_stats = true
    data["q[date_gteq]"] = dates.start
    data["q[date_lteq]"] = dates.end

    dispatch(getDashboardData(data))
    //eslint-disable-next-line
  }, [dates.start, dates.end])

  useEffect(() => {
    if (!dates.start || !dates.end) return
    const data = {}
    const startMoment = moment(dates.start)
    const endMoment = moment(dates.end)

    if (params.trip_id) data.trip_id = params.trip_id

    data.forecast_date = []
    while (startMoment.isSameOrBefore(endMoment)) {
      data.forecast_date.push(startMoment.format(DATE_FORMAT))
      startMoment.add(1, "day")
    }

    dispatch(guideForecast(data))
    // eslint-disable-next-line
  }, [dates.start, dates.end, params.trip_id])

  return (
    <div className="hstack flex-fill z-0" id="dashboard" style={{ maxHeight: height - headerHeight - 60 }}>
      <Sidebar
        className="border-top border-start border-bottom border-gray-lightest rounded-start bg-white p-15 align-self-stretch"
        params={params}
        onChangeFilters={changeHandler}
      />
      <div className="vstack">
        <Header
          className="border border-gray-lightest rounded-top rounded-start-0 bg-white z-1"
          params={params}
          onChangeFilters={changeHandler}
        />
        <Calendar className="border-start border-end border-bottom border-gray-lightest rounded-bottom rounded-start-0 bg-white overflow-auto z-0 flex-fill" />
      </div>
      <DefaultErrorBoundary>
        <Outlet />
      </DefaultErrorBoundary>
    </div>
  )
}

export default Dashboard
