import React, { useEffect } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"
import { routes } from "router"
import Header from "components/layout/Header"
import GuideHeader from "components/guides/Header"
import Navigation from "components/guides/Navigation"
import Spinner from "components/common/Spinner"
import Footer from "components/layout/Footer"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useDispatch, useSelector } from "react-redux"
import { cleanGuide, getClientGuide } from "store/guides"

function GuideLayout() {
  const params = useParams()
  const dispatch = useDispatch()
  const { role } = useSelector((store) => store.user)
  const { guide } = useSelector((store) => store.guides)
  const isGuide = role === "guide_user"

  useEffect(() => {
    dispatch(getClientGuide(params.id))
    return () => dispatch(cleanGuide())
  }, [params.id, dispatch])

  return isGuide ? (
    <Navigate to={routes.rootPath()} />
  ) : (
    <main className="vstack min-vh-100 bg-light">
      <Header withNavigation withShadow />
      {guide.loading || !guide.id ? (
        <Spinner className="my-40" />
      ) : (
        <>
          <div className="bg-white shadow pt-20">
            <GuideHeader guide={guide} />
            <Navigation id={guide.id} />
          </div>
          <div className="flex-fill vstack min-h-100">
            <DefaultErrorBoundary>
              <Outlet />
            </DefaultErrorBoundary>
          </div>
        </>
      )}
      <Footer />
    </main>
  )
}

export default withDefaultErrorBoundary(GuideLayout)
