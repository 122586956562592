import React, { useMemo, useState, useCallback, useEffect, memo } from "react"
import { Button } from "reactstrap"
import DatepickerDropdown from "components/common/DatepickerDropdown"
import Icon from "components/common/Icon"

import moment from "moment"

import { DATE_FORMAT, MONTH_FORMAT, WEEK_FORMAT, YEAR_FORMAT } from "constants/date"

export const TYPE_FORMATS = {
  day: DATE_FORMAT,
  week: WEEK_FORMAT,
  month: MONTH_FORMAT,
  year: YEAR_FORMAT
}
const DISPLAY_TYPE_FORMATS = {
  day: "DD MMM, YYYY",
  week: "[W]W YYYY",
  month: "MMMM YYYY",
  year: "YYYY"
}

const ACCEPTED_TYPES = ["day", "week", "month", "year"]

function DateSwitcher({ value, type = "day", onChange, className, ...datepickerProps }) {
  const acceptedType = useMemo(() => (ACCEPTED_TYPES.includes(type) ? type : ACCEPTED_TYPES.at(0)), [type])
  const format = useMemo(() => TYPE_FORMATS[acceptedType], [acceptedType])
  const displayFormat = useMemo(() => DISPLAY_TYPE_FORMATS[acceptedType], [acceptedType])
  const momentValue = useMemo(() => {
    const parsedMomentValue = moment(value, format)
    return parsedMomentValue.isValid() ? parsedMomentValue : false
  }, [value, format])

  const today = moment().startOf("day")
  const [momentDate, setMomentDate] = useState(momentValue || today)
  const formattedDate = useMemo(() => momentDate.format(DATE_FORMAT), [momentDate])

  const typeStartDate = useMemo(
    () =>
      moment(momentDate)
        .startOf(acceptedType === "week" ? "isoWeek" : acceptedType)
        .format(TYPE_FORMATS.day),
    [momentDate, acceptedType]
  )
  const typeEndDate = useMemo(
    () =>
      moment(momentDate)
        .endOf(acceptedType === "week" ? "isoWeek" : acceptedType)
        .format(TYPE_FORMATS.day),
    [momentDate, acceptedType]
  )

  const changeDate = useCallback(
    (step) => () => {
      const methodName = step ? "add" : "subtract"
      const newDate = moment(momentDate)[methodName](step, `${acceptedType === "week" ? "isoWeek" : acceptedType}s`)
      setMomentDate(newDate)
    },
    [momentDate, acceptedType]
  )

  const updateDate = ({ target }) => setMomentDate(target.date)

  useEffect(() => {
    const newDate = momentDate.format(format)
    if (typeof onChange === "function") onChange(newDate, typeStartDate, typeEndDate, momentDate)
    //eslint-disable-next-line
  }, [formattedDate])

  const classes = ["hstack align-items-stretch"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <Button
        className="btn-ghost text-dark px-2 py-2 rounded-1"
        onClick={changeDate(-1)}
        disabled={datepickerProps.minDate && moment(datepickerProps.minDate) >= moment(typeStartDate)}
      >
        <Icon iconName="SidebarToggle" className="rotate-90" size={18} block />
      </Button>
      <DatepickerDropdown
        {...datepickerProps}
        toggleClassName="btn-ghost px-2 py-2 rounded-1"
        type={acceptedType}
        value={momentValue || momentDate}
        onChange={updateDate}
        buttons={{ showToday: true }}
      >
        {useCallback(
          (inputValue, isValid) => (
            <span className={["h3 lh-1 d-block mb-n1", isValid ? "" : "opacity-50"].join(" ")}>
              {inputValue ? moment(inputValue, format).format(displayFormat) : "—"}
            </span>
          ),
          [format, displayFormat]
        )}
      </DatepickerDropdown>
      <Button
        className="btn-ghost text-dark px-2 py-2 rounded-1"
        onClick={changeDate(1)}
        disabled={datepickerProps.maxDate && moment(datepickerProps.maxDate) <= moment(typeEndDate)}
      >
        <Icon iconName="SidebarToggle" className="rotate-270" size={18} block />
      </Button>
    </div>
  )
}

export default memo(DateSwitcher)
