import React, { useCallback, useEffect } from "react"
import { Container } from "reactstrap"

import Spinner from "components/common/Spinner"
import Preview from "components/boats/Preview"

import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"
import { Waypoint } from "react-waypoint"

import { useDispatch, useSelector } from "react-redux"
import { cleanBoat, getClientGuideBoats } from "store/boats"

export default function Boats() {
  const { guide } = useSelector((store) => store.guides)
  const { items, loading, metadata } = useSelector((state) => state.boats)
  const hasBoat = !!items?.length
  const dispatch = useDispatch()
  useTitle(`Boats | ${guide.display_name}`)
  const fetchBoats = useCallback((nextPage = false) => dispatch(getClientGuideBoats(guide.id, nextPage)), [dispatch, guide.id])

  useEffect(() => {
    fetchBoats()
    return () => dispatch(cleanBoat())
    //eslint-disable-next-line
  }, [])

  return (
    <Container className="vstack gap-30 py-40">
      {hasBoat
        ? items.map((boat, index) => <Preview key={boat.id} isOdd={(index + 1) % 2 !== 0} boat={boat} />)
        : !loading && (
            <div className="bg-white p-20 rounded">
              <h2 className="h3">No boats at the moment</h2>
            </div>
          )}
      {loading && <Spinner className="mt-40" />}
      {!isLastPage(metadata) && !loading && hasBoat && <Waypoint onEnter={() => fetchBoats(true)} />}
    </Container>
  )
}
