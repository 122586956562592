import React, { memo, useEffect, useMemo, useState } from "react"
import { Button, Col, Label, Row } from "reactstrap"
import { Accordion } from "react-bootstrap"
import Input from "components/form/Input"
import Icon from "components/common/Icon"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { pick, omitBy } from "lodash"
import { hasEmptyFields } from "helpers/form"

import { useSelector } from "react-redux"

import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { generateCountryOptions } from "components/bookings/helpers"

const PaymentMethodNew = ({ active, saveCard, onChange, onSaveCard }) => {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const user = useSelector((store) => store.user || {})

  const [form, changeHandler] = useForm({}, ["billing_details"])
  const userAddress = pick(user, ["address_line_1", "address_line_2", "city", "state", "country", "zip_code"])
  const disabledAddressAutoFill = !user.id || hasEmptyFields(userAddress, ["address_line_1", "city", "state", "country", "zip_code"])

  const [completedCardFields, setCardCompleted] = useState({})
  const isCardFieldsCompleted = completedCardFields.cardNumber && completedCardFields.cardExpiry && completedCardFields.cardCvc
  const isFullNameCompleted = useMemo(
    () => !hasEmptyFields(form.billing_details || {}, ["first_name", "last_name"]),
    [form.billing_details]
  )
  const isAddressCompleted = useMemo(
    () => !hasEmptyFields(form.billing_details?.address || {}, ["city", "country", "line1", "postal_code", "state"]),
    [form.billing_details]
  )
  const isCardCompleted = isCardFieldsCompleted && isFullNameCompleted && isAddressCompleted

  const collectedCardData = useMemo(() => {
    if (!stripe || !elements || !isCardCompleted) return {}
    const cardNumberElement = elements.getElement(CardNumberElement)
    return {
      type: "card",
      card: cardNumberElement,
      billing_details: {
        name: `${form.billing_details?.first_name} ${form.billing_details?.last_name}`,
        address: omitBy(form.billing_details?.address, (item) => item === "")
      }
    }
  }, [stripe, elements, form, isCardCompleted])

  const cardChangeHandler = (e) => setCardCompleted((prev) => ({ ...prev, [e.elementType]: e.complete }))

  const useAccountAddress = () => {
    changeHandler({ target: { name: "billing_details.first_name", value: user.first_name } })
    changeHandler({ target: { name: "billing_details.last_name", value: user.last_name } })
    changeHandler({ target: { name: "billing_details.address.line1", value: userAddress.address_line_1 } })
    changeHandler({ target: { name: "billing_details.address.line2", value: userAddress.address_line_2 } })
    changeHandler({ target: { name: "billing_details.address.city", value: userAddress.city } })
    changeHandler({ target: { name: "billing_details.address.state", value: userAddress.state } })
    changeHandler({ target: { name: "billing_details.address.postal_code", value: userAddress.zip_code } })
    changeHandler({ target: { name: "billing_details.address.country", value: userAddress.country } })
  }

  useEffect(() => {
    if (active === null && !isCardCompleted) return
    onChange(collectedCardData)
  }, [collectedCardData]) //eslint-disable-line

  const isOpen = active instanceof Object

  return (
    <Accordion onSelect={() => onChange(collectedCardData)} activeKey={isOpen ? "new" : ""}>
      <Accordion.Item
        eventKey="new"
        className={["bg-white rounded border shadow overflow-hidden", isOpen ? "border-primary-second " : "border-gray-lightest"].join(" ")}
      >
        <Accordion.Button className="p-15 fs-5 lh-sm">
          <div className="p-0 rounded-circle border border-gray-lightest me-2">
            <Icon iconName="Close" size={24} className="rotate-45" block />
          </div>
          <span className="">{"Use a different payment method"}</span>
        </Accordion.Button>
        <Accordion.Body className="vstack gap-20 p-15">
          <Row className="gap-y-15 gx-4">
            <Col xs={12}>
              <Label className="fs-7" for="credit_card_number">
                {t("booking.wizard.step_3_checkout.labels.credit_card_number")} *
              </Label>
              <Input
                id="credit_card_number"
                tag={CardNumberElement}
                options={{ showIcon: true, disableLink: true }}
                onChange={cardChangeHandler}
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="expiry_date">
                {t("booking.wizard.step_3_checkout.labels.expiry_date")} *
              </Label>
              <Input id="expiry_date" tag={CardExpiryElement} onChange={cardChangeHandler} />
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="cvv">
                {t("booking.wizard.step_3_checkout.labels.cvv")} *
              </Label>
              <Input id="cvv" tag={CardCvcElement} onChange={cardChangeHandler} />
            </Col>
            <Col xs={12}>
              <Label check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
                <Input
                  type="checkbox"
                  name="save_card"
                  checked={saveCard}
                  onChange={() => onSaveCard?.(!saveCard)}
                  className="mt-0"
                  style={{ width: 12, height: 12 }}
                />
                <span className={`text-dark ${saveCard ? "" : "text-opacity-50"}`}>
                  {t(`booking.wizard.step_3_checkout.labels.save_card`)}
                </span>
              </Label>
            </Col>
          </Row>

          <Row className="gap-y-15 gx-4">
            <Col xs={12} className="hstack gap-20 justify-content-between">
              <h3>{t("booking.wizard.step_3_checkout.billing_details")}</h3>
              {!disabledAddressAutoFill && (
                <Button
                  color="primary-second"
                  className="px-15 py-1 fs-7 fw-medium flex-shrink-0"
                  onClick={useAccountAddress}
                  disabled={disabledAddressAutoFill}
                >
                  {t("booking.wizard.step_3_checkout.use_account_address")}
                </Button>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="first_name">
                {t("settings.first_name")} *
              </Label>
              <Input
                id="first_name"
                type="text"
                placeholder={t("settings.first_name")}
                name="billing_details.first_name"
                value={form.billing_details?.first_name || ""}
                onChange={changeHandler}
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="last_name">
                {t("settings.last_name")} *
              </Label>
              <Input
                id="last_name"
                type="text"
                placeholder={t("settings.last_name")}
                name="billing_details.last_name"
                value={form.billing_details?.last_name || ""}
                onChange={changeHandler}
              />
            </Col>
            <Col xs={12}>
              <Label className="fs-7" for="address_line_1">
                {t("settings.address.address_line_1")} *
              </Label>
              <Input
                id="address_line_1"
                type="text"
                placeholder={t("settings.address.address_line_1")}
                name="billing_details.address.line1"
                value={form.billing_details?.address?.line1 || ""}
                onChange={changeHandler}
              />
            </Col>
            <Col xs={12}>
              <Label className="fs-7" for="address_line_2">
                {t("settings.address.address_line_2")} *
              </Label>
              <Input
                id="address_line_2"
                type="text"
                placeholder={t("settings.address.address_line_2")}
                name="billing_details.address.line2"
                value={form.billing_details?.address?.line2 || ""}
                onChange={changeHandler}
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="city">
                {t("settings.address.city")} *
              </Label>
              <Input
                id="city"
                type="text"
                placeholder={t("settings.address.city")}
                name="billing_details.address.city"
                value={form.billing_details?.address?.city || ""}
                onChange={changeHandler}
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="state">
                {t("settings.address.state")} *
              </Label>
              <Input
                id="state"
                type="text"
                placeholder={t("settings.address.state")}
                name="billing_details.address.state"
                value={form.billing_details?.address?.state || ""}
                onChange={changeHandler}
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="country">
                {t("settings.address.country")} *
              </Label>
              <Input
                id="country"
                type="select"
                placeholder={t("settings.address.country")}
                name="billing_details.address.country"
                value={form.billing_details?.address?.country || ""}
                onChange={changeHandler}
                className={form.billing_details?.address?.country ? "" : "text-gray-light"}
              >
                {generateCountryOptions()}
              </Input>
            </Col>
            <Col xs={12} md={6}>
              <Label className="fs-7" for="postal_code">
                {t("settings.address.zip_code")} *
              </Label>
              <Input
                id="postal_code"
                type="text"
                placeholder={t("settings.address.zip_code")}
                name="billing_details.address.postal_code"
                value={form.billing_details?.address?.postal_code || ""}
                onChange={changeHandler}
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default memo(PaymentMethodNew)
