import React, { useCallback, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Review from "components/reviews/Review"
import Spinner from "components/common/Spinner"

import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanReviews, getClientGuideReviews } from "store/reviews"

export default function Reviews() {
  const dispatch = useDispatch()
  const { guide } = useSelector((state) => state.guides)
  const { items, loading, metadata } = useSelector((state) => state.reviews)
  const hasReviews = !!items.length
  useTitle(`Reviews | ${guide.display_name}`)

  const fetchReviews = useCallback((nextPage = false) => dispatch(getClientGuideReviews(guide.id, nextPage)), [guide.id, dispatch])

  useEffect(() => {
    fetchReviews()
    return () => dispatch(cleanReviews())
  }, [dispatch, fetchReviews])

  return (
    <Container className="py-40">
      {hasReviews ? (
        <Row className="gap-y-20 gap-y-lg-30 gx-30">
          {items.map((review) => (
            <Col xs={12} sm={6} md={4} xl={3} key={review.id}>
              <Review review={review} className="h-100" />
            </Col>
          ))}
        </Row>
      ) : (
        !loading && (
          <div className="bg-white p-20 rounded">
            <h2 className="h3">No reviews at the moment</h2>
          </div>
        )
      )}
      {loading && <Spinner className="mt-40" />}
      {!isLastPage(metadata) && !loading && hasReviews && <Waypoint onEnter={() => fetchReviews(true)} />}
    </Container>
  )
}
