import React, { useCallback, useEffect } from "react"
import { Container } from "reactstrap"
import { Outlet } from "react-router-dom"
import Post from "components/posts/Post"
import Spinner from "components/common/Spinner"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"
import { Waypoint } from "react-waypoint"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanPost, getClientGuidePosts } from "store/posts"

export default function Feeder() {
  const dispatch = useDispatch()
  const { guide } = useSelector((state) => state.guides)
  const { items, loading, metadata } = useSelector((state) => state.posts)
  const hasPosts = !!items?.length
  useTitle(`Feeder | ${guide.display_name}`)

  const fetchPosts = useCallback((nextPage = false) => dispatch(getClientGuidePosts(guide.id, nextPage)), [guide.id, dispatch])

  useEffect(() => {
    fetchPosts()
    return () => dispatch(cleanPost())
    //eslint-disable-next-line
  }, [])

  return (
    <Container className="vstack gap-20 py-40">
      {hasPosts
        ? items.map((post) => <Post post={post} key={post.id} />)
        : !loading && (
            <div className="bg-white p-20 rounded">
              <h2 className="h3">No posts at the moment</h2>
            </div>
          )}
      {loading && <Spinner className="mt-40" />}
      {!isLastPage(metadata) && !loading && hasPosts && <Waypoint onEnter={() => fetchPosts(true)} />}
      <DefaultErrorBoundary>
        <Outlet />
      </DefaultErrorBoundary>
    </Container>
  )
}
