import React from "react"
import { useSearchParams } from "react-router-dom"
import TotalYearBarChart from "./TotalYearBarChart"
import Spinner from "components/common/Spinner"

import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"
import { metadataSelector } from "store/selectors"

export default function TotalYear() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const range = searchParams.get("range")

  const metadata = useSelector(metadataSelector("accounting"))
  const { stats_per_year } = metadata

  return (
    <div className="bg-white rounded p-15 h-100 vstack position-relative">
      <h3 className="h4 fw-normal mb-n45">{t(`accounting.labels.total_year`)}</h3>
      {stats_per_year ? <TotalYearBarChart range={range} /> : <Spinner className="flex-fill" />}
    </div>
  )
}
