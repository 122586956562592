import React from "react"
import { Link } from "react-router-dom"
import Icon from "components/common/Icon"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"
import { routes } from "router"

export default function ResetPasswordSuccess() {
  useTitle("Reset Password — Success")
  const { t } = useTranslation()

  return (
    <>
      <h1 className="h2 lh-1">{t("reset_password.success")}</h1>
      <div className="fs-6 fw-medium mt-20">
        <div className="hstack gap-1 small link-target">
          Go to{" "}
          <Link to={routes.signInPath()} className="text-primary-hover text-decoration-none">
            Login
          </Link>
          <Icon iconName="ArrowLeft" width={18} height={10} className="rotate-180" />
        </div>
      </div>
    </>
  )
}
