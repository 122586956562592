import convert from "convert"

// prettier-ignore
const PARSER_MAP = {
  "true": true,
  "false": false,
  "null": null,
  "undefined": undefined,
  "NaN": NaN,
  "": null
}

export const convertStringToNumber = (str) => {
  const numberizeStr = str === null ? str : +str
  if (isNaN(numberizeStr)) return str
  if (typeof numberizeStr === "number") return numberizeStr
  return str
}

export const parse = (value, allowEmpty = false) => {
  if (Object.keys(PARSER_MAP).includes(value)) return allowEmpty && value === "" ? "" : PARSER_MAP[value]
  if (value instanceof Object) return value
  return convertStringToNumber(value)
}

export const filterHashTags = (string, addOrUpdate = false) => {
  let filtered = string
    .replace(/(?![#\w\s]).+/g, "")
    .replace(/#+/g, "#")
    .replace(/^(\w)$/, "#$1")
    .replace(/^[\s\w]/, "")
    .replace(/\s+$/, " ")
  if (addOrUpdate)
    filtered = filtered
      .replace(/(?<=#)\s+(?=\w)/g, "") //skip spaces between # and word
      .replace(/(?<=#)\s+(?=#)/g, " ") //skip spaces between #
      .replace(/(?<=\w)\s+(?=#\s)/g, " ") //only one new tag between #
      .replace(/(?<=\w)\s(?=\s#)/g, " #") //insert tag
      .replace(/(?<=\w)[#\s](?=\w)/g, " #") //split tag
      .replace(/(?<=\w)#/g, "") //skip # between word and space
      .replace(/\s+(?=\s)/g, " ")
  //skip spaces
  else filtered = filtered.replace(/(?<=\w)\s(?=\w)/g, "").replace(/\s+/g, " ")

  return filtered
}

export const splitHashTags = (string) => string.match(/#(\w+)/g) || []

export const numberToCurrency = (number, currency = "USD") => {
  return isNaN(+number) ? "—" : new Intl.NumberFormat("en-US", { style: "currency", currency }).format(+number)
}

export const numberFormat = (number, options = {}) => {
  return isNaN(+number) ? "—" : new Intl.NumberFormat("en-US", options).format(+number)
}

export const CONVERT_TO_METRIC_MAP = {
  fahrenheit: "celsius",
  inch: "millimeter",
  foot: "meter",
  mile: "kilometer",
  "mile-per-hour": "kilometer-per-hour",
  "inches-of-mercury": "hectopascal"
}

const CONVERT_NUMBER_K = {
  "inches-of-mercury": 33.865
}

const CONVERT_NUMBER_FORMATS_MAP = {
  fahrenheit: "F",
  celsius: "C",
  inch: "in",
  millimeter: "mm",
  foot: "ft",
  meter: "m",
  mile: "mi",
  kilometer: "km",
  "mile-per-hour": "mi",
  "kilometer-per-hour": "km",
  "inches-of-mercury": "inHg",
  hectopascal: "hPa"
}

export const numberFormatTo = (value, to, maximumFractionDigits = 2) => {
  const toFormat = CONVERT_NUMBER_FORMATS_MAP[to]
  let formattedValue = value
  try {
    formattedValue = numberFormat(value, { style: "unit", maximumFractionDigits, unit: to })
  } catch (e) {
    formattedValue = `${(+value).toFixed(maximumFractionDigits)}${toFormat}`
  }
  return formattedValue
}

export const numberFormatToMetric = (value, from, digits, format = true) => {
  const to = CONVERT_TO_METRIC_MAP[from]
  const formattedValue = format ? numberFormatTo(value, to, digits) : numberFormatTo(value, from, digits)
  return formattedValue
}

export const convertNumber = (value, from, to, format = true, digits) => {
  const fromFormat = CONVERT_NUMBER_FORMATS_MAP[from]
  const toFormat = CONVERT_NUMBER_FORMATS_MAP[to]
  let convertedValue = value
  try {
    convertedValue = convert(value, fromFormat).to(toFormat)
  } catch (e) {
    let k = CONVERT_NUMBER_K[from] || 1
    convertedValue = Math.round(value * k * Math.pow(10, digits)) / Math.pow(10, digits)
  }
  const formattedValue = numberFormatTo(convertedValue, to, digits)
  return format ? formattedValue : convertedValue
}

export const convertToMetric = (value, from, format = true, digits, convert = true) => {
  const to = CONVERT_TO_METRIC_MAP[from]
  const formattedValue = numberFormatTo(value, from, digits)
  const convertedValue = convertNumber(value, from, to, format, digits)
  return convert ? convertedValue : format ? formattedValue : value
}
