import React from "react"
import Step3Form from "components/guide/wizard/Step3Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function Step3() {
  useTitle("Guide Wizard — Step 3")
  const { t } = useTranslation()
  const title = t("guide.wizard.step_3.title")
  const subtitle = t("guide.wizard.step_3.subtitle")

  return (
    <>
      <div className="text-center">
        {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
        {subtitle && <p className="fs-5 mt-15 mb-40 text-dark text-opacity-50">{subtitle}</p>}
      </div>
      <DefaultErrorBoundary>
        <Step3Form />
      </DefaultErrorBoundary>
    </>
  )
}
