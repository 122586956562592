import React from "react"
import { Container } from "reactstrap"
import { Outlet } from "react-router-dom"
import Header from "components/layout/Header"
import UnactiveGuideMessage from "components/common/UnactiveGuideMessage"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

function DashboardLayout() {
  return (
    <main className="vstack min-vh-100 bg-light">
      <Header withNavigation />
      <Container className="flex-fill vstack min-h-100 py-30">
        <UnactiveGuideMessage>
          <DefaultErrorBoundary>
            <Outlet />
          </DefaultErrorBoundary>
        </UnactiveGuideMessage>
      </Container>
    </main>
  )
}

export default withDefaultErrorBoundary(DashboardLayout)
