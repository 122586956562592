import React from "react"
import Step5Form from "components/guide/wizard/Step5Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function Step5() {
  useTitle("Guide Wizard — Step 5")
  const { t } = useTranslation()
  const title = t("guide.wizard.step_5.title")
  const subtitle = t("guide.wizard.step_5.subtitle")

  return (
    <div className="text-center">
      {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
      {subtitle && (
        <p className="fs-5 mt-15 mb-40 text-dark text-opacity-50 mx-auto" style={{ maxWidth: 540 }}>
          {subtitle}
        </p>
      )}
      <DefaultErrorBoundary>
        <Step5Form />
      </DefaultErrorBoundary>
    </div>
  )
}
