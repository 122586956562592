import React, { useEffect, useRef } from "react"
import { Container } from "reactstrap"
import { NavLink, useMatch } from "react-router-dom"
import { routes } from "router"
import UserMenu from "components/common/UserMenu"
import GuideNavigation from "./GuideNavigation"
import TripFiltersNavigation from "./TripFiltersNavigation"
import SearchTrips from "./SearchTrips"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import Logo from "components/common/Logo"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { updateWindowSize } from "modules/window/reducer"

function Header({ noPadding = false, fluid = false, withNavigation = false, withShadow: shadow = false, className }) {
  const dispatch = useDispatch()
  const headerRef = useRef(null)
  const headerHeight = useSelector((store) => store.window)
  const user = useSelector((store) => store.user)
  const { role } = user
  const isGuide = role === "guide_user"
  const isTripsPage = !!useMatch(routes.tripsPath())
  const rootPath = role ? (isGuide ? routes.guideRootPath() : routes.clientRootPath()) : routes.rootPath()

  const showGuideNavigation = withNavigation && isGuide
  const showTripFiltersNavigation = isTripsPage && !isGuide
  const withShadow = shadow || showGuideNavigation || showTripFiltersNavigation
  const title = isGuide && "Guide Portal"

  const setHeight = () => {
    if (!headerRef.current) return
    const height = headerRef.current.clientHeight
    const isHeightChanged = headerHeight !== height
    if (!isHeightChanged) return

    document.body.style.setProperty("--header-height", `${height}px`)
    dispatch(updateWindowSize({ headerHeight: height }))
  }

  useEffect(() => {
    setHeight()
    window.addEventListener("load", setHeight)
    window.addEventListener("resize", setHeight)
    return () => {
      window.removeEventListener("load", setHeight)
      window.removeEventListener("resize", setHeight)
    }
  }, []) //eslint-disable-line

  const classes = ["bg-white py-10 py-sm-15 position-relative z-1"]
  if (withShadow) classes.push("shadow")
  if (className) classes.push(className)

  return (
    <div className="sticky-top" ref={headerRef} style={{ ...(isTripsPage && { maxHeight: 140 }) }}>
      <header className={classes.join(" ")}>
        <Container className={noPadding ? "p-0" : ""} fluid={fluid || isTripsPage}>
          <div className="hstack gap-10 justify-content-between flex-wrap">
            <NavLink
              className="d-block rounded-circle bg-gray-light-hover bg-opacity-25-hover text-dark p-2 m-n2"
              to={rootPath}
              state={{ scrollToTop: true }}
              title="Home"
              size={32}
            >
              <Logo className="rounded-circle" alt="Logo" width={32} height={32} />
            </NavLink>
            {title && <p className="h4">{title}</p>}
            {!isGuide && <SearchTrips className="order-2 order-md-1 flex-grow-1 flex-md-grow-0" />}
            <UserMenu className="order-1" />
          </div>
        </Container>
      </header>
      {showGuideNavigation ? <GuideNavigation /> : null}
      {showTripFiltersNavigation ? <TripFiltersNavigation /> : null}
    </div>
  )
}

export default withDefaultErrorBoundary(Header)
