import React, { memo, useMemo } from "react"
import { routes } from "router"
import { Link } from "react-router-dom"

import moment from "moment"

import { useSelector } from "react-redux"

import { DATE_FORMAT } from "constants/date"

const CalendarDay = ({ day, today, thisMonth = true }) => {
  //eslint-disable-next-line
  const nextDay = useMemo(() => moment(today).add(1, "days"), [today.format(DATE_FORMAT)])
  const isUpperNextDay = moment(nextDay) <= moment(day)
  const dayName = day.format("dddd").toLowerCase()
  const isToday = today.format(DATE_FORMAT) === day.format(DATE_FORMAT)
  const { items } = useSelector((state) => state.trips)

  const filteredTrips = items.filter(
    (trip) =>
      trip.available_days[dayName] && isUpperNextDay && (!trip.seasonal || (moment(trip.start_date) <= day && day <= moment(trip.end_date)))
  )

  const classes = ["vstack border-end border-bottom border-gray-lightest p-2"]
  if (!thisMonth) classes.push("bg-light")

  const numberClasses = ["rounded-circle px-1 m-n1 me-auto fs-7 fw-medium text-center mw-100"]
  if (isToday) numberClasses.push("bg-dark text-white")
  if (!thisMonth) numberClasses.push("opacity-50")

  return (
    <div className={classes.join(" ")} style={{ minHeight: 130 }}>
      <div className={numberClasses.join(" ")} style={{ width: 30, lineHeight: "30px" }}>
        {day.format("DD")}
      </div>
      {filteredTrips.length ? (
        <div className="vstack gap-1 justify-content-end mt-2">
          {filteredTrips.map((trip) => (
            <Link
              key={trip.id}
              to={routes.tripPath({ id: trip.id })}
              state={{ date: day.format(DATE_FORMAT) }}
              className="badge rounded bg-primary bg-opacity-25 bg-opacity-100-hover fs-7 fw-semibold text-primary text-white-hover text-start text-wrap text-decoration-none lh-1 py-1 px-3 me-auto"
            >
              {trip.title}
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default memo(CalendarDay)
