import React, { memo, useMemo } from "react"
import Times from "./Times"
import DayWithTime from "./DayWithTime"
import Spinner from "components/common/Spinner"

import moment from "moment"

import { useSelector } from "react-redux"

const CalendarWeek = ({ date, className }) => {
  const { loading } = useSelector((store) => store.dashboard)

  const today = moment()

  const weekStartDate = useMemo(() => moment(date).startOf("isoWeek"), [date])
  const weekEndDate = useMemo(() => moment(date).endOf("isoWeek"), [date])

  const calendarDaysCount = useMemo(() => weekEndDate.diff(weekStartDate, "days") + 1, [weekStartDate, weekEndDate])
  const calendarDays = useMemo(
    () => new Array(calendarDaysCount).fill().map((_, index) => weekStartDate.clone().add(index, "days")),
    [weekStartDate, calendarDaysCount]
  )

  const classes = ["grid grid-cols-7 gap-0 position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ gridTemplateColumns: "auto repeat(var(--bs-columns, 12), minmax(0,1fr))" }}>
      <Times />
      {calendarDays.map((day, index) => (
        <DayWithTime
          key={day.dayOfYear()}
          day={day}
          today={today}
          thisMonth={day.isBetween(weekStartDate, weekEndDate, "day", "[]")}
          first={index === 0}
          last={index === calendarDays.length - 1}
        />
      ))}
      {loading && <Spinner className="bg-light bg-opacity-75 w-100 h-100" absolute />}
    </div>
  )
}
export default memo(CalendarWeek)
