import React, { memo } from "react"
import Logo from "./Logo"

function ImagePlaceholder({ label = "Image not found", className }) {
  const classes = ["vstack align-items-center justify-content-center bg-gray-lightest text-gray-light"]
  className && classes.push(className)

  return (
    <div className={classes.join(" ")}>
      <Logo width={80} height={80} alt="Logo" className="opacity-50" style={{ mixBlendMode: "darken", filter: "grayscale(1)" }} />
      <p className="fs-7 lh-1 mt-4 mb-0">{label}</p>
    </div>
  )
}

export default memo(ImagePlaceholder)
