import React, { useState } from "react"
import { Button, UncontrolledAccordion, AccordionBody, AccordionHeader, Container, AccordionItem, TabContent, TabPane } from "reactstrap"
import { Link } from "react-router-dom"
import { useTitle } from "hooks/useTitle"
import { routes } from "router"

export default function FaqLanding() {
  useTitle("FAQ")
  const [activeAccordion, setActiveAccordion] = useState("client")

  return (
    <>
      <div className="flex-fill bg-light py-100">
        <Container>
          <h1 className="h2 lh-sm text-center mx-auto mb-80">Frequently Asked Questions</h1>
          <div className="hstack gap-3 justify-content-center mb-55 w-100">
            <Button
              onClick={() => setActiveAccordion("client")}
              color="primary-second"
              outline={activeAccordion === "guide"}
              className="rounded"
            >
              Client
            </Button>
            <Button
              onClick={() => setActiveAccordion("guide")}
              color="primary-second"
              outline={activeAccordion === "client"}
              className="rounded"
            >
              Guide
            </Button>
          </div>
          <TabContent activeTab={activeAccordion} className="w-100 mx-auto mb-35" style={{ maxWidth: 780 }}>
            <TabPane tabId="client">
              <UncontrolledAccordion className="vstack gap-15" defaultOpen="client_1">
                <AccordionItem className="card overflow-hidden border border-gray-lightest">
                  <AccordionHeader targetId="client_1">
                    <h2 className="h4 lh-sm">Client: How can I create an account?</h2>
                  </AccordionHeader>
                  <AccordionBody accordionId="client_1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do.
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className="card overflow-hidden border border-gray-lightest">
                  <AccordionHeader targetId="client_2">
                    <h2 className="h4 lh-sm">Client: Insert another question Here?</h2>
                  </AccordionHeader>
                  <AccordionBody accordionId="client_2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do.
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className="card overflow-hidden border border-gray-lightest">
                  <AccordionHeader targetId="client_3">
                    <h2 className="h4 lh-sm">Client: Insert another question Here?</h2>
                  </AccordionHeader>
                  <AccordionBody accordionId="client_3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do.
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </TabPane>

            <TabPane tabId="guide">
              <UncontrolledAccordion className="vstack gap-15" defaultOpen="guide_1">
                <AccordionItem className="card overflow-hidden border border-gray-lightest">
                  <AccordionHeader targetId="guide_1">
                    <h2 className="h4 lh-sm">Guide: How can I create an account?</h2>
                  </AccordionHeader>
                  <AccordionBody accordionId="guide_1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do.
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className="card overflow-hidden border border-gray-lightest">
                  <AccordionHeader targetId="guide_2">
                    <h2 className="h4 lh-sm">Guide: Insert another question Here?</h2>
                  </AccordionHeader>
                  <AccordionBody accordionId="guide_2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do.
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className="card overflow-hidden border border-gray-lightest">
                  <AccordionHeader targetId="guide_3">
                    <h2 className="h4 lh-sm">Guide: Insert another question Here?</h2>
                  </AccordionHeader>
                  <AccordionBody accordionId="guide_3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do.
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </TabPane>
          </TabContent>
          <p className="text-center mb-0">
            Didn’t find the answer you are looking for?&nbsp;
            <Link className="fw-semibold text-decoration-none" to={routes.rootPath()}>
              Contact our support
            </Link>
          </p>
        </Container>
      </div>
    </>
  )
}
