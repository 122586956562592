import React, { useEffect } from "react"
import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom"
import { routes } from "router"
import Cable from "components/Cable"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import { useDispatch, useSelector } from "react-redux"
import { cleanUser, getUser } from "store/user"

const PrivateRoutes = ({ accessRole = null }) => {
  const dispatch = useDispatch()
  useLoaderWatchers({ watchers: "user" })
  const cuurentPageIsWizard = useMatch({ path: routes.guideWizardRootPath(), end: false })
  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const { id, role, guide } = useSelector((store) => store.user)
  const from = useLocation()

  useEffect(() => {
    if (loggedIn && !id) dispatch(getUser())
    return () => !loggedIn && dispatch(cleanUser())
  }, [loggedIn, id]) //eslint-disable-line

  if (loggedIn && role) {
    if (role !== accessRole) return <Navigate to={routes.rootPath()} replace />
    if (role === "guide_user" && guide.id && !guide.wizard_completed && !cuurentPageIsWizard)
      return <Navigate to={routes.guideWizardRootPath()} replace />
  }

  return loggedIn ? (
    <FirstLoading className="vh-100">
      {id ? (
        <Cable>
          <Outlet />
        </Cable>
      ) : (
        "User not found"
      )}
    </FirstLoading>
  ) : (
    <Navigate to={routes.signInPath()} state={{ from }} replace />
  )
}

export default PrivateRoutes
