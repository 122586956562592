import React, { memo } from "react"
import Avatar from "components/common/Avatar"

import moment from "moment"

import { useSelector } from "react-redux"
import { isMyMessageSelector } from "store/conversations"

function Message({ message }) {
  const isMyMessage = useSelector(isMyMessageSelector(message))

  const classes = ["message hstack gap-15 p-20 h5 fw-normal"]
  if (isMyMessage) classes.push("justify-content-end")
  else classes.push("justify-content-start")
  if (!message.viewed && !isMyMessage) classes.push("bg-primary-second bg-opacity-10")

  return (
    <div className={classes.join(" ")}>
      <div className={["vstack gap-3", isMyMessage ? "align-items-end" : ""].join(" ")} style={{ maxWidth: 480 }}>
        <div className={["hstack gap-10", isMyMessage ? "flex-row-reverse" : ""].join(" ")}>
          <div className="position-relative flex-shrink-0">
            <Avatar user={message?.author} size={30} className="" border={false} showStatus />
          </div>
          <div className="fs-7 lh-sm">
            <div className="fw-bold">{message.author.full_name}</div>
            <div className="small text-dark text-opacity-50 ms-auto">{moment(message.created_at).fromNow()}</div>
          </div>
        </div>
        <div className={isMyMessage ? "text-dark" : "text-gray-medium"}>{message.body}</div>
      </div>
    </div>
  )
}

export default memo(Message)
