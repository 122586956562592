import React, { memo, useEffect, useMemo } from "react"
import { DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"
import CustomDatepicker from "components/form/CustomDatepicker"

import moment from "moment"

import { DATE_FORMAT } from "constants/date"

const RANGES = {
  current_month: "Current month",
  last_month: "Last month",
  last_30_days: "Last 30 days",
  total_by_scope: "Custom date range"
}

function RangeSelector({ params, onChangeFilters }) {
  const gteqMaxDate = useMemo(() => (params.date_lteq ? moment(params.date_lteq).endOf("day") : false), [params.date_lteq])
  const lteqMinDate = useMemo(() => (params.date_gteq ? moment(params.date_gteq) : false), [params.date_gteq])
  const lteqMaxDate = moment().endOf("day")

  useEffect(() => {
    if (params.type !== "business_overview") {
      onChangeFilters({ target: { name: "overview_range", value: null } })
      onChangeFilters({ target: { name: "date_gteq", value: null } })
      onChangeFilters({ target: { name: "date_gteq", value: null } })
      return
    }
    if (!params.overview_range) onChangeFilters({ target: { name: "overview_range", value: "current_month" } })
    //eslint-disable-next-line
  }, [params.type])

  useEffect(() => {
    if (!params.overview_range || params.overview_range !== "total_by_scope") {
      onChangeFilters({ target: { name: "date_gteq", value: null } })
      onChangeFilters({ target: { name: "date_lteq", value: null } })
      return
    }

    const defaultStartDate = moment(params.date_lteq ? params.date_lteq : moment())
      .startOf("month")
      .format(DATE_FORMAT)
    const defaultEndDate = moment().format(DATE_FORMAT)

    if (!params.date_gteq) onChangeFilters({ target: { name: "date_gteq", value: defaultStartDate } })
    if (!params.date_lteq) onChangeFilters({ target: { name: "date_lteq", value: defaultEndDate } })
    //eslint-disable-next-line
  }, [params.overview_range])

  return (
    <div className="d-flex gap-20 align-items-center flex-wrap">
      <Dropdown
        className="my-n1"
        toggleButton={
          <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
            <h1 className="h3 fw-medium lh-1">{RANGES[params.overview_range]}</h1>
            <Icon iconName="SidebarToggle" size={20} />
          </DropdownToggle>
        }
      >
        {Object.entries(RANGES).map(([range, label]) => (
          <DropdownItem key={range} name="overview_range" value={range} onClick={onChangeFilters}>
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
      {params.overview_range === "total_by_scope" && (
        <div className="d-flex gap-10 align-items-center flex-wrap flex-fill">
          <CustomDatepicker
            name="date_gteq"
            value={params.date_gteq}
            onChange={onChangeFilters}
            maxDate={gteqMaxDate || lteqMaxDate}
            className="flex-grow-1 flex-sm-grow-0"
            inputClassName="fs-6 fw-medium"
          />
          {" - "}
          <CustomDatepicker
            name="date_lteq"
            value={params.date_lteq}
            onChange={onChangeFilters}
            minDate={lteqMinDate}
            maxDate={lteqMaxDate}
            className="flex-grow-1 flex-sm-grow-0"
            inputClassName="fs-6 fw-medium"
          />
        </div>
      )}
    </div>
  )
}

export default memo(RangeSelector)
