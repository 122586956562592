import React from "react"

import moment from "moment"
import { pull, orderBy } from "lodash"
import { getData } from "country-list"

import { DATE_FORMAT, WEEK_DAY_NUMBERS } from "constants/date"

export const getBookingDisabledDates = (trip, booked_date) => {
  const today = moment()
  const dateProps = {}
  if (!trip) return dateProps

  const nextDay = today.clone().add(1, "days")
  const startDate = moment(trip.start_date, DATE_FORMAT)
  const endDate = moment(trip.end_date, DATE_FORMAT)
  const todayPlusOneYear = today.clone().add(1, "year")

  dateProps.minDate = nextDay
  if (trip.seasonal && startDate.isValid() && startDate > nextDay) dateProps.minDate = startDate
  if (trip.seasonal && endDate.isValid()) dateProps.maxDate = dateProps.minDate && dateProps.minDate > endDate ? dateProps.minDate : endDate
  if (!dateProps.maxDate || todayPlusOneYear.isBefore(dateProps.maxDate)) dateProps.maxDate = todayPlusOneYear

  if (trip.booking_dates?.length) dateProps.disabledDates = trip.booking_dates
  if (trip.unavailable_dates?.length) dateProps.disabledDates = trip.unavailable_dates.concat(dateProps.disabledDates || [])
  if (dateProps.disabledDates?.length) dateProps.disabledDates = pull([...new Set(dateProps.disabledDates)], booked_date)

  const tripUnavailableWeekdays = trip.unavailable_weekdays?.map((day) => WEEK_DAY_NUMBERS[day])
  dateProps.daysOfWeekDisabled = tripUnavailableWeekdays

  return dateProps
}

export const generateGuestCountOptions = (selected = 0, max = 100) => [
  <option key={0} value={0}>
    0
  </option>,
  ...new Array(Math.max(0, max - selected)).fill().map((_, i) => (
    <option key={i + 1} value={i + 1}>
      {i + 1}
    </option>
  ))
]

export const generateCountryOptions = () => {
  const countryList = orderBy(getData(), "name")
  return [
    <option key="select" value="">
      Select country
    </option>,
    countryList.map(({ code, name }) => (
      <option key={code} value={code}>
        {name}
      </option>
    ))
  ]
}

export const brandImage = (brand) => {
  if (!brand) return

  try {
    return require(`../../assets/images/payment/${brand}.jpg`)
  } catch (e) {
    console.log(e)
    return ""
  }
}

export const calcPayToday = (po, total, deposit_percentage = 0) => {
  if (po === "full_amount_today") return total
  if (["deposit_and_balance_later", "deposit_and_balance_in_cash"].includes(po)) return total * (deposit_percentage / 100)
  if (po === "full_amount_in_cash_on_day") return 0
  return null
}

export const calculateBookingTotal = (trip, guests = 0) => {
  if (!trip || !guests) return

  const { pricing_type = "charge_per_person", pricing = {} } = trip || {}
  const { price_per_person, flat_rate, minimum_rate, minimum_rate_person_count, rate_per_additional_person } = pricing || {}

  const guestsDiff = guests - +minimum_rate_person_count
  const guestsGreater = guestsDiff > 0
  const types = {
    charge_per_person: +price_per_person * guests,
    charge_flat_rate: +flat_rate,
    set_minimum_rate: +minimum_rate + (guestsGreater ? guestsDiff * +rate_per_additional_person : 0)
  }
  return types[pricing_type]
}

export const calculateChanges = (form, booking) => {
  const trip = booking?.trip || {}
  if (!trip) return

  const { pricing_type, max_guests } = trip
  const { price_per_person, minimum_rate, minimum_rate_person_count, rate_per_additional_person } = booking?.trip?.pricing || {}
  const calculation = {}

  calculation.pricingType = pricing_type

  calculation.pricePerPerson =
    (pricing_type === "set_minimum_rate" && +rate_per_additional_person) || (pricing_type === "charge_per_person" && +price_per_person) || 0
  calculation.minimumPersons =
    (pricing_type === "set_minimum_rate" && +minimum_rate_person_count) || (pricing_type === "charge_flat_rate" && +max_guests) || 0
  calculation.minimumRate = (pricing_type === "set_minimum_rate" && +minimum_rate) || 0

  calculation.guests = Math.max(+form.child_count + +form.adult_count, calculation.minimumPersons)
  calculation.totalPrice = calculateBookingTotal(trip, calculation.guests)

  calculation.adultOverCount = Math.max(+form.adult_count, calculation.minimumPersons) - calculation.minimumPersons
  calculation.childOverCount = Math.min(
    Math.max(+form.adult_count + +form.child_count, calculation.minimumPersons) - calculation.minimumPersons,
    +form.child_count
  )

  if (!booking) return calculation

  calculation.adultCountDiff = calculation.minimumPersons ? calculation.adultOverCount : +form.adult_count - +booking?.adult_count
  calculation.childCountDiff = calculation.minimumPersons ? calculation.childOverCount : +form.child_count - +booking?.child_count

  calculation.prevGuests = Math.max(+booking.child_count + +booking.adult_count, calculation.minimumPersons)
  calculation.prevTotalPrice = calculateBookingTotal(trip, calculation.prevGuests)

  calculation.guestsDiff = calculation.guests - calculation.prevGuests
  calculation.adultPriceDiff = calculation.adultCountDiff * calculation.pricePerPerson
  calculation.childPriceDiff = calculation.childCountDiff * calculation.pricePerPerson
  calculation.totalPriceDiff = calculation.totalPrice - calculation.prevTotalPrice

  calculation.isDateChanged = form.booked_date !== booking.booked_date
  calculation.isGuestsChanged = calculation.guestsDiff !== 0

  return calculation
}
