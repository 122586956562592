import React from "react"
import { Container } from "reactstrap"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"

export default function Location() {
  const { t } = useTranslation()
  const { guide } = useSelector((store) => store.guides)
  useTitle(`Feeder | ${guide.display_name}`)

  return (
    <Container className="py-40">
      <h2 className="fw-semibold">{t("location.title")}</h2>
    </Container>
  )
}
