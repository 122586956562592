import React, { memo, useMemo } from "react"
import { FormGroup, InputGroup, InputGroupText, Label } from "reactstrap"
import Icon from "components/common/Icon"
import Input from "./Input"
import DatepickerDropdown from "components/common/DatepickerDropdown"

import moment from "moment"

import { PICKER_FORMATS } from "constants/date"

const CustomDatepicker = ({
  type = "date",
  label,
  name,
  placeholder,
  value,
  onChange,
  disabled = false,
  floating = false,
  noIcon = false,
  className,
  labelClassName,
  inputClassName,
  iconClassName,
  ...rest
}) => {
  const format = useMemo(() => (Object.keys(PICKER_FORMATS).includes(type) ? PICKER_FORMATS[type] : PICKER_FORMATS.date), [type])
  const momentValue = useMemo(() => {
    const parsedMomentValue = moment(value, format)
    return parsedMomentValue.isValid() ? parsedMomentValue : false
  }, [value, format])

  const labelClasses = []
  if (labelClassName) labelClasses.push(labelClassName)

  const inputClasses = []
  if (inputClassName) inputClasses.push(inputClassName)
  if (!noIcon) inputClasses.push("border-end-0")

  const iconClasses = ["text-dark text-opacity-50 border-start-0"]
  if (iconClassName) iconClasses.push(iconClassName)
  if (disabled) iconClasses.push("bg-secondary-bg")
  else iconClasses.push("bg-white")

  return (
    <DatepickerDropdown
      {...rest}
      toggleTag={floating ? InputGroup : FormGroup}
      toggleClassName="flex-nowrap"
      toggleProps={!floating && { noMargin: true }}
      type={type}
      name={name}
      value={momentValue}
      onChange={onChange}
      buttons={{ showToday: true, showClear: true }}
      disabled={disabled}
      className={className}
    >
      {(inputValue, isValid) =>
        floating ? (
          <>
            <FormGroup noMargin floating>
              <Input
                type={type}
                placeholder={placeholder}
                value={inputValue || ""}
                className={[...inputClasses, inputValue && !isValid ? "text-opacity-50" : ""].join(" ")}
                disabled={disabled}
                readOnly
              />
              {label && <Label className={labelClasses.join(" ")}>{label}</Label>}
            </FormGroup>
            {!noIcon && (
              <InputGroupText className={iconClasses.join(" ")}>
                <Icon iconName="Calendar" size={24} />
              </InputGroupText>
            )}
          </>
        ) : (
          <>
            {label && <Label className={labelClasses.join(" ")}>{label}</Label>}
            <InputGroup>
              <Input
                type={type}
                placeholder={placeholder}
                value={inputValue || ""}
                className={[...inputClasses, inputValue && !isValid ? "text-opacity-50" : ""].join(" ")}
                disabled={disabled}
                readOnly
              />
              {!noIcon && (
                <InputGroupText className={iconClasses.join(" ")}>
                  <Icon iconName="Calendar" size={24} />
                </InputGroupText>
              )}
            </InputGroup>
          </>
        )
      }
    </DatepickerDropdown>
  )
}
export default memo(CustomDatepicker)
