import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showModal } from "../reducer"
import { confirmModalSelector } from "../selectors"

export const useConfirmModal = () => {
  const dispatch = useDispatch()
  const confirmModal = useSelector(confirmModalSelector)
  const { isOpen, submit } = confirmModal
  const [confirmHandler, setConfirmHandler] = useState([])

  const confirm = (modalProps, cb) => (event) => {
    if (event && event.persist) event.persist()
    const props = {
      target: {
        name: event?.currentTarget?.name,
        value: event?.currentTarget?.value
      }
    }
    if (typeof cb === "function") setConfirmHandler([cb, props])
    dispatch(showModal("confirmModal", modalProps))
  }

  useEffect(() => {
    const [handler, event] = confirmHandler
    if (submit && typeof handler === "function") handler?.(event) || console.log("Confirm modal has no callback")
  }, [submit]) //eslint-disable-line

  useEffect(() => {
    if (!isOpen) setConfirmHandler([])
  }, [isOpen])

  return confirm
}
