import React, { useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Pagination, Scrollbar } from "swiper"
import { LazyLoadImage } from "react-lazy-load-image-component"
import ImagePlaceholder from "components/common/ImagePlaceholder"
import Icon from "components/common/Icon"

const PhotoSlider = ({ photos = [], preview = false, ratio = "4x3", className, swiperClassName, ...rest }) => {
  const slideLeftRef = useRef(null)
  const slideRightRef = useRef(null)

  const onInit = (swiper) => {
    swiper.params.navigation.prevEl = slideLeftRef.current
    swiper.params.navigation.nextEl = slideRightRef.current
    swiper.navigation.init()
    swiper.navigation.update()
  }

  const classes = ["mw-100"]
  if (className) classes.push(className)

  const swiperClasses = ["hover-target bg-gray-lightest h-100"]
  if (swiperClassName) swiperClasses.push(swiperClassName)

  return (
    <div className={classes.join(" ")} {...rest}>
      <Swiper
        onInit={onInit}
        slidesPerView={1}
        spaceBetween={0}
        modules={[FreeMode, Scrollbar, Navigation, Pagination]}
        pagination={{ horizontalClass: "bottom-0" }}
        className={swiperClasses.join(" ")}
      >
        {photos.length ? (
          photos.map((photo, index) => (
            <SwiperSlide index={index} key={photo.id} className={`w-100 h-auto ratio ratio-${ratio}`}>
              <LazyLoadImage
                src={preview && photo.preview_url ? photo.preview_url : photo.url}
                alt=""
                className="object-fit-cover w-100 h-100"
                effect="blur"
                placeholderSrc={photo.placeholder_url || null}
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide index={0} className={`w-100 h-auto ratio ratio-${ratio}`}>
            <ImagePlaceholder label={false} />
          </SwiperSlide>
        )}
        {photos.length ? (
          <>
            <div
              className="swiper-button-prev top-0 start-0 m-0 p-2 h-100 w-auto bg-white bg-opacity-0 bg-opacity-25-hover transition hover-target__show-on-hover"
              ref={slideLeftRef}
            >
              <div className="bg-dark bg-opacity-50 rounded-circle position-absolute" style={{ width: 25, height: 25 }} />
              <Icon iconName="Dropdown" width={27} height={27} className="text-white rotate-90 w-auto h-auto" />
            </div>
            <div
              className="swiper-button-next top-0 end-0 m-0 p-2 h-100 w-auto bg-white bg-opacity-0 bg-opacity-25-hover transition hover-target__show-on-hover"
              ref={slideRightRef}
            >
              <div className="bg-dark bg-opacity-50 rounded-circle position-absolute" style={{ width: 25, height: 25 }} />
              <Icon iconName="Dropdown" width={27} height={27} className="text-white rotate-270 w-auto h-auto" />
            </div>
          </>
        ) : null}
      </Swiper>
    </div>
  )
}

export default PhotoSlider
