import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { routes } from "router"
import { Waypoint } from "react-waypoint"
import Trip from "components/trips/Trip"
import Icon from "components/common/Icon"
import WithEmptyState from "components/common/WithEmptyState"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTrip, createTrip, getGuideTrips } from "store/trips"
import { collectionAnySelector, collectionSelector, metadataSelector } from "store/selectors"

export default function Trips() {
  useTitle("Trips")
  useLoaderWatchers({ watchers: ["trips"] })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const metadata = useSelector(metadataSelector("trips"))
  const trips = useSelector(collectionSelector("trips"))
  const hasTrips = useSelector(collectionAnySelector("trips"))

  const fetchTrips = (nextPage = false) => dispatch(getGuideTrips(nextPage))

  useEffect(() => {
    dispatch(cleanTrip())
    fetchTrips()
    return () => dispatch(cleanTrip())
  }, []) //eslint-disable-line

  const createTripHandler = (event) => {
    if (event.persist) event.persist()
    dispatch(createTrip()).then(({ data: trip }) => trip.id && navigate(routes.guideTripWizardRootPath({ id: trip.id })))
  }

  return (
    <FirstLoading name="trips">
      <div className="hstack gap-15">
        <h1 className="h3 fw-medium">{t("trips.title")}</h1>
        {hasTrips && (
          <Button color="primary" className="px-3 py-2 fs-7" onClick={createTripHandler}>
            {t("trips.add_new")}
          </Button>
        )}
      </div>
      <WithEmptyState
        tag={Row}
        name="trip"
        hasItems={hasTrips}
        className="gap-y-20 gx-20 gap-y-xl-35 gx-xl-35 mt-25"
        fallback={
          <Col xs={12} sm={6} xl={3}>
            <div className="bg-white rounded border border-gray-lightest p-4 mt-25">
              <Icon iconName="Trips" className="" size={44} />
              <h4 className="mt-30">{t("trips.empty_message")}</h4>
              <Button color="primary" className="mt-25" block onClick={createTripHandler}>
                {t("trips.create_now")}
              </Button>
            </div>
          </Col>
        }
      >
        {trips.map((trip, index) => (
          <Col xs={12} lg={6} key={index}>
            <Trip trip={trip} className="h-100" guideView />
          </Col>
        ))}
        {!isLastPage(metadata) && <Waypoint onEnter={() => fetchTrips(true)} />}
      </WithEmptyState>
    </FirstLoading>
  )
}
