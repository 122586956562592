import React from "react"
import NavigationLink from "./NavigationLink"

import { Container } from "reactstrap"
import { useTranslation } from "react-i18next"
import { routes } from "router"
import { useScrollToActive } from "hooks/useScrollToActive"
import { scrollToActive } from "helpers/DOM"

import { useSelector } from "react-redux"

export default function GuideNavigation({ className }) {
  const { t } = useTranslation()
  const navigationRef = useScrollToActive()
  const { metadata } = useSelector((store) => store.conversations)
  const totalUnviewed = metadata.total_unviewed_messages

  const classes = ["bg-white shadow"]
  if (className) classes.push(className)

  const clickHandler = ({ currentTarget }) => scrollToActive(currentTarget)

  return (
    <nav className={classes.join(" ")}>
      <Container>
        <div className="full-window-scroll" ref={navigationRef}>
          <div className="hstack gap-20 py-10 py-sm-20">
            <NavigationLink
              to={routes.guideDashboardPath()}
              iconName="Dashboard"
              label={t("guide.navigation.dashboard")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={routes.guideBookingsPath()}
              iconName="Bookings"
              label={t("guide.navigation.bookings")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={routes.guideClientsPath()}
              iconName="Customers"
              label={t("guide.navigation.clients")}
              onClick={clickHandler}
            />
            <NavigationLink to={routes.guideTripsPath()} iconName="Trips" label={t("guide.navigation.trips")} onClick={clickHandler} />
            <NavigationLink to={routes.guideBoatsPath()} iconName="Boats" label={t("guide.navigation.boats")} onClick={clickHandler} />
            <NavigationLink
              to={routes.guideMessagesPath()}
              iconName="Messages"
              label={t("guide.navigation.messages")}
              onClick={clickHandler}
              badge={totalUnviewed > 0 && totalUnviewed}
            />
            <NavigationLink
              to={routes.guideAccountingPath()}
              iconName="Accounting"
              label={t("guide.navigation.accounting")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={routes.guideMarketingPath()}
              iconName="Marketing"
              label={t("guide.navigation.marketing")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={routes.guideWeatherPath()}
              iconName="Weather"
              label={t("guide.navigation.weather")}
              onClick={clickHandler}
            />
          </div>
        </div>
      </Container>
    </nav>
  )
}
