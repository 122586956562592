import React, { memo } from "react"
import { Link } from "react-router-dom"
import { routes } from "router"
import Avatar from "components/common/Avatar"

import { useSelector } from "react-redux"
import { activeConversationSelector } from "store/conversations"

function Conversation({ conversation, avatarSize = 30 }) {
  const role = useSelector((state) => state.user.role)
  const activeConversation = useSelector(activeConversationSelector)
  const active = activeConversation.id === conversation.id
  const isGuide = role === "guide_user"
  const url = isGuide
    ? routes.guideMessagesPath({}, { conversation_id: conversation.id })
    : routes.clientMessagesPath({}, { conversation_id: conversation.id })
  const user = isGuide ? conversation.contact : conversation.client
  const participant = isGuide ? conversation.client : conversation.contact
  const lastMessage = conversation.last_message || {}
  const slicedMessage = conversation.last_message?.body?.slice(0, 100) || ""
  const isSlicedMessage = conversation.last_message?.body?.length > slicedMessage?.length

  const classes = ["hstack gap-15 border-bottom border-gray-lightest border-opacity-50 p-10 ps-20 link-target"]
  if (active) classes.push("bg-gray-lightest")
  else classes.push("bg-white")

  if (!conversation.id) return null
  return (
    <div className={classes.join(" ")}>
      <div className="hstack gap-0 align-items-center flex-shrink-0 mb-auto">
        <Avatar user={user} size={avatarSize} className="border-white" />
        <div className="position-relative ms-n10">
          <Avatar user={participant} size={avatarSize} showStatus className="border-white" />
        </div>
      </div>
      <div className="min-w-0 flex-fill">
        <Link to={url} className="h6 fs-7 fw-medium text-dark text-primary-second-hover text-decoration-none lh-1 d-block my-1">
          <small className="">{`You and ${participant.full_name}`}</small>
        </Link>
        <div className="hstack gap-1">
          <span
            className={[
              "h6 fs-7 lh-sm flex-fill",
              lastMessage.author_id === user.id ? "fw-bold" : "fw-medium text-dark text-opacity-50"
            ].join(" ")}
          >
            {slicedMessage}
            {isSlicedMessage && "..."}
          </span>
          {conversation.unviewed > 0 && (
            <div
              className="bg-white border border-dark rounded-pill text-dark text-center fs-6 lh-sm flex-shrink-0 px-1"
              style={{ minWidth: 20 }}
            >
              {conversation.unviewed}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Conversation)
