import React from "react"
import { Button } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { routes } from "router"
import Avatar from "components/common/Avatar"
import Icon from "components/common/Icon"

import { useDispatch, useSelector } from "react-redux"
import { createConversation } from "store/conversations"

const Guide = ({ guide, className }) => {
  const { loggedIn } = useSelector((store) => store.auth)
  const { role } = useSelector((store) => store.user)
  const back_url = useLocation().pathname
  const isGuide = role === "guide_user"
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const guidePath = routes.publicGuidePath({ id: guide.id }, { back_url })

  const clientMessages = () => {
    dispatch(createConversation({ participant_id: guide.user_id })).then((conversation) =>
      navigate(routes.clientMessagesPath({}, { conversation_id: conversation.id }))
    )
  }
  const classes = []
  if (className) classes.push(className)
  if (isGuide) classes.push("pointer-events-none opacity-50")

  return (
    <div className={classes.join(" ")}>
      <div className="hstack gap-10 link-target">
        <Avatar user={guide} size={52} />
        <div className="my-auto flex-fill">
          <p className="text-dark text-opacity-50 fs-7 fw-medium mb-1">Guided by</p>
          <div className="d-flex justify-content-between flex-wrap gap-x-10 gap-y-1">
            <Link to={isGuide ? "" : guidePath} className="h4 lh-1 text-primary-hover d-block flex-grow-1" style={{ flexBasis: 180 }}>
              {guide.display_name}
            </Link>
            <div className="hstack gap-1">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <Icon key={index} iconName="StarNew" className="text-primary-second" />
                ))}
            </div>
          </div>
        </div>
      </div>
      <p className="mt-25 mb-40">{guide.description}</p>
      <div className="hstack gap-15 flex-wrap mt-auto">
        <Button color="primary" className="flex-fill" disabled={!loggedIn} onClick={clientMessages}>
          Message Guide
        </Button>
        <Button tag={Link} to={isGuide ? "" : guidePath} color="primary" className="flex-fill text-white-hover" outline>
          View Guide Profile
        </Button>
      </div>
    </div>
  )
}

export default Guide
