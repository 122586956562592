import React, { useCallback } from "react"
import { Button, Col, ModalBody, Row, Modal } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import { routes } from "router"
import Icon from "components/common/Icon"
import ReviewForm from "components/reviews/ReviewForm"
import BookingForm from "components/bookings/BookingForm"
import Guide from "components/guides/Guide"
import BoatPreview from "components/boats/Preview"
import PhotoSlider from "components/common/PhotoSlider"

import { hideModal, showModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import { useTranslation } from "react-i18next"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { useSelector, useDispatch } from "react-redux"
import { createFavoriteTrip, deleteFavouriteTrip } from "store/favourite-trips"
import { isGuideSelector, isUserSelector } from "store/user"
import { modelSelector } from "store/selectors"

function renderPhotoGrid(photos, openHandler) {
  if (photos.length === 0) return null

  if (photos.length === 1)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-12" style={{ maxHeight: 320 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
      </div>
    )

  if (photos.length === 2)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-md-12 g-col-lg-6" style={{ maxHeight: 320 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-md-12 g-col-lg-6" style={{ maxHeight: 320 }}>
          <LazyLoadImage
            src={photos.at(1).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(1).placeholder_url || null}
          />
        </div>
      </div>
    )

  if (photos.length === 3)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 320 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-lg-6 grid grid-cols-6 gap-2">
          <div className="g-col-6 grid grid-cols-1 grid-rows-7 gap-2" style={{ maxHeight: 320 }}>
            <div className="g-row-3">
              <LazyLoadImage
                src={photos.at(1).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(1).placeholder_url || null}
              />
            </div>
            <div className="g-row-4">
              <LazyLoadImage
                src={photos.at(2).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(2).placeholder_url || null}
              />
            </div>
          </div>
        </div>
      </div>
    )
  if (photos.length === 4)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-5" style={{ maxHeight: 320 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-lg-7 grid grid-cols-7 gap-2">
          <div className="g-col-4 grid grid-cols-1 grid-rows-7 gap-2" style={{ maxHeight: 320 }}>
            <div className="g-row-3">
              <LazyLoadImage
                src={photos.at(1).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(1).placeholder_url || null}
              />
            </div>
            <div className="g-row-4 ">
              <LazyLoadImage
                src={photos.at(2).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(2).placeholder_url || null}
              />
            </div>
          </div>
          <div className="g-col-3" style={{ maxHeight: 320 }}>
            <LazyLoadImage
              src={photos.at(3).url}
              alt=""
              className="object-fit-cover w-100 h-100 rounded"
              wrapperClassName="w-100 h-100"
              effect="blur"
              placeholderSrc={photos.at(3).placeholder_url || null}
            />
          </div>
        </div>
      </div>
    )
  if (photos.length > 4)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-5" style={{ maxHeight: 320 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-lg-7 grid grid-cols-7 gap-2">
          <div className="g-col-4 grid grid-cols-1 grid-rows-7 gap-2" style={{ maxHeight: 320 }}>
            <div className="g-row-3">
              <LazyLoadImage
                src={photos.at(1).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(1).placeholder_url || null}
              />
            </div>
            <div className="g-row-4 ">
              <LazyLoadImage
                src={photos.at(2).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(2).placeholder_url || null}
              />
            </div>
          </div>
          <div className="g-col-3 position-relative" style={{ maxHeight: 320 }}>
            <div className="position-absolute bottom-0 end-0 p-3 z-2">
              <Button color="ghost" className="bg-white bg-opacity-25  p-1" onClick={openHandler}>
                <div className="fs-7 lh-0 text-white opacity-25">{"See More"}</div>
              </Button>
            </div>
            <LazyLoadImage
              src={photos.at(3).url}
              alt=""
              className="object-fit-cover w-100 h-100 rounded"
              wrapperClassName="w-100 h-100"
              effect="blur"
              placeholderSrc={photos.at(3).placeholder_url || null}
            />
          </div>
        </div>
      </div>
    )
}

export default function Preview({ isEdit = false, guideView = false }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  useLoaderWatchers({ watchers: ["trips.trip"] })

  const trip = useSelector(modelSelector("trip"))
  const isGuide = useSelector(isGuideSelector)
  const isUser = useSelector(isUserSelector)
  const booking = useSelector(modelSelector("booking"))
  const {
    id,
    activity_type,
    title,
    description,
    address,
    min_guests,
    max_guests,
    alcohol_allowed,
    accessibility_enabled,
    bring_items,
    included_items,
    target_fish_species,
    skill_level,
    boat,
    guide,
    favorite_id
  } = trip

  const ecosystem = trip[`${activity_type}_ecosystem`]
  const isPublished = trip.status === "published"
  const isCompleted = booking.status === "completed"

  const lightbox = useSelector(modalSelector("lightbox"))
  const openHandler = () => dispatch(showModal("lightbox"))
  const closeHandler = () => dispatch(hideModal("lightbox"))

  const toggleFavoriteTrip = useCallback(() => {
    if (favorite_id) return dispatch(deleteFavouriteTrip(favorite_id))
    dispatch(createFavoriteTrip(trip.id))
  }, [trip.id, favorite_id]) //eslint-disable-line

  return (
    <FirstLoading name="trips.trip">
      <div className="hstack position-relative pe-100">
        <h1 className="h2 lh-1 mb-0">{title}</h1>
        {(isUser && (
          <div className="hstack gap-10 mt-n2 position-absolute top-0 end-0">
            <Button
              color="ghost"
              className={[
                "p-1",
                favorite_id ? "bg-primary bg-opacity-75-hover text-white" : "bg-white bg-opacity-25 bg-opacity-100-hover text-dark"
              ].join(" ")}
              onClick={toggleFavoriteTrip}
            >
              <Icon iconName="Like" className={["flex-shrink-0"].join(" ")} size={30} block />
            </Button>
            <Button color="ghost" className="p-1" onClick={console.log}>
              <Icon iconName="Share" className="flex-shrink-0" size={26} block />
            </Button>
          </div>
        )) ||
          (isGuide && !isEdit && (
            <div className="hstack gap-10 mt-n1 position-absolute top-0 end-0">
              <Button
                tag={Link}
                color="ghost"
                className="p-1"
                to={routes.guideTripWizardRootPath({ id: id }, { back_url: location.pathname })}
              >
                <Icon iconName="EditForm" className="flex-shrink-0" size={26} block />
              </Button>
            </div>
          ))}
      </div>
      <Modal
        isOpen={lightbox.isOpen}
        size="xl"
        centered
        scrollable
        backdropClassName="backdrop-blur-3"
        contentClassName="rounded-sm-3"
        toggle={closeHandler}
      >
        <ModalBody className="p-0">
          <PhotoSlider photos={trip.photos} className="w-100" />
        </ModalBody>
      </Modal>
      <div className="d-flex flex-column flex-sm-row align-items-end gap-20 mt-30">
        <div className="hstack gap-x-30 gap-y-2 flex-wrap">
          {trip.licenses_required ||
            (true && (
              <div className="hstack gap-2 mt-auto">
                <Icon iconName="License" className="flex-shrink-0" width={20} />
                <span className="fs-6 fw-bold">{t("trip.labels.licenses_required")}</span>
              </div>
            ))}
          <div className="hstack gap-2 mt-auto">
            <Icon iconName="Location" className="flex-shrink-0" size={20} />
            <span className="fs-6">{address || "—"}</span>
          </div>
          <div className="hstack gap-2 mt-auto">
            <Icon iconName="Users" className="flex-shrink-0" size={24} />
            <span className="fs-6">
              {min_guests === max_guests
                ? t("trip.card.persons", { count: min_guests })
                : t("trip.card.persons_range", { min_guests, max_guests }) || "—"}
            </span>
          </div>
          {accessibility_enabled && (
            <div className="hstack gap-2 mt-auto">
              <Icon iconName="Wheelchair" className="flex-shrink-0" size={20} />
              <span className="fs-6">{t("trip.labels.accessibility_label")}</span>
            </div>
          )}
        </div>
        {isGuide && (
          <div className="ms-auto mb-n2 w-100 w-sm-auto fs-6 fw-semibold text-white">
            {isPublished ? (
              <div className="hstack bg-primary rounded-pill p-2 pe-15 justify-content-center">
                <Icon iconName="Checked" className="me-1 my-n2" size={22} block />
                {t("trip.card.published")}
              </div>
            ) : (
              <div className="hstack bg-gray-light rounded-pill py-1 px-25">{t("trip.card.draft")}</div>
            )}
          </div>
        )}
      </div>

      {renderPhotoGrid(trip.photos, openHandler)}
      <div className="mt-40">
        <h2 className="h3">{t("trip.labels.about")}</h2>
        <p>{description}</p>
      </div>

      <div className="mt-60">
        <h2 className="h3">{t("trip.labels.included_items")}</h2>
        <ul className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 grid-cols-xl-4 gap-20 list-unstyled m-0 mt-20">
          {included_items.length ? included_items.map((item) => <li key={item}>— {item}</li>) : "—"}
        </ul>
      </div>
      <div className="mt-60">
        <h2 className="h3">{t("trip.labels.bring_items")}</h2>
        <ul className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 grid-cols-xl-4 gap-20 list-unstyled m-0 mt-20">
          {bring_items.length ? bring_items.map((item) => <li key={item}>— {item}</li>) : "—"}
        </ul>
      </div>
      <div className="mt-20">
        <div className="hstack gap-2 mt-auto">
          <Icon iconName={`Alkohol${alcohol_allowed ? "Yes" : "No"}`} className="flex-shrink-0" size={20} />
          <span className="fs-6">{t(`trip.labels.alcohol_allowed_${alcohol_allowed}`)}</span>
        </div>
      </div>

      <Row className="mt-60 gap-y-60 gx-40">
        {!booking.with_trip_review &&
          (isCompleted ? (
            <Col xs={12} lg={6} className="d-flex flex-column">
              <h2 className="h3 mb-30">{t("trip.labels.trip_review")}</h2>
              <ReviewForm className="mb-lg-30" />
            </Col>
          ) : (
            <Col xs={12} lg={6} className="d-flex flex-column">
              <h2 className="h3">{t("trip.labels.trip_calculator")}</h2>
              <BookingForm />
            </Col>
          ))}
        <Col xs={12} lg={6} className="d-flex flex-column">
          <h2 className="h3">{t("trip.labels.your_guide")}</h2>
          {guide?.id && <Guide guide={guide} className="vstack flex-fill mt-35 mb-lg-45" />}
        </Col>
      </Row>

      <div className="mt-60">
        <h2 className="h3">{t("trip.labels.ds_location")}</h2>
      </div>

      <div className="mt-60">
        <h2 className="h3">{t("trip.labels.additional")}</h2>
        <div className="grid grid-cols-1 grid-cols-sm-2 grid-cols-lg-3 grid-cols-xl-4 gap-20 flex-wrap align-items-start mt-20">
          <div>
            <h3 className="h4">{t("trip.labels.target_fish_species")}</h3>
            <ul className="vstack gap-15 list-unstyled m-0 mt-15">
              {target_fish_species.length ? target_fish_species.map((item) => <li key={item}>— {item}</li>) : "—"}
            </ul>
          </div>
          <div>
            <h3 className="h4">{t("trip.labels.skill_level")}</h3>
            <ul className="vstack gap-15 list-unstyled m-0 mt-15">
              <li>— {skill_level && t(`trip.skill_levels.${skill_level}`)}</li>
            </ul>
          </div>
          <div>
            <h3 className="h4">{t("trip.labels.ecosystem")}</h3>
            <ul className="vstack gap-15 list-unstyled m-0 mt-15">
              <li>— {ecosystem && t(`trip.ecosystems.${ecosystem}`)}</li>
            </ul>
          </div>
        </div>
      </div>
      {boat && (
        <div className="mt-60">
          <BoatPreview boat={boat} />
        </div>
      )}
    </FirstLoading>
  )
}
