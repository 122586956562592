import React from "react"
import { NavLink } from "react-router-dom"

import { useWizardStep } from "hooks/wizard"
import { scrollToActive } from "helpers/DOM"

const linkTemplate =
  ({ stepNumber, label, sublabel, disabled, showLine, horizontal = false, lineSize = "calc(var(--gap) - 10px)" }) =>
  ({ isActive }) =>
    (
      <>
        {stepNumber ? (
          <div
            className={[
              "rounded-circle border border-primary-second h6 fw-medium lh-1 p-1",
              disabled ? "opacity-75 border-opacity-0" : "",
              isActive ? "border-opacity-1" : "border-opacity-0"
            ].join(" ")}
          >
            <div
              className={[
                "hstack justify-content-center rounded-circle border position-relative",
                disabled
                  ? "border-gray-light text-gray-light opacity-75"
                  : isActive
                  ? "border-primary-second text-white bg-primary-second"
                  : "border-primary-second text-primary-second"
              ].join(" ")}
              style={{ width: 28, height: 28 }}
            >
              {showLine && (
                <div
                  className={[
                    "position-absolute",
                    disabled ? "bg-gray-light" : "bg-primary-second",
                    horizontal ? "end-100 me-3" : "d-none d-md-block bottom-100 mb-3"
                  ].join(" ")}
                  style={horizontal ? { height: 1, width: lineSize } : { height: lineSize, width: 1 }}
                />
              )}
              {stepNumber}
            </div>
          </div>
        ) : null}
        <div
          className={[
            "vstack gap-1 justify-content-center fs-7",
            ...(horizontal ? ["position-absolute bottom-0 start-50 translate-middle-x"] : [])
          ].join(" ")}
        >
          <span className={["h6 fw-7 fw-medium lh-1", isActive ? "text-primary-second" : "text-dark"].join(" ")}>{label}</span>
          {sublabel ? <small className="text-gray-light lh-1">{sublabel}</small> : null}
        </div>
      </>
    )

const baseClass =
  ({ className, disabled, horizontal = false }) =>
  ({ isActive }) => {
    const classses = ["hstack gap-2 gap-sm-3 opacity-75-hover cursor-pointer text-decoration-none text-nowrap"]
    if (isActive) classses.push("active-link")
    if (isActive || disabled) classses.push("pointer-events-none")
    if (className) classses.push(className)
    if (disabled) classses.push("opacity-50")
    if (horizontal) classses.push("flex-column position-relative pb-25")
    return classses.join(" ")
  }

const CustomLink = ({
  stepNumber = null,
  to = null,
  end = false,
  label,
  sublabel,
  onClick,
  className = null,
  disabled = null,
  horizontal = false,
  lineSize
}) => {
  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault()
      onClick()
    }
    scrollToActive(event.currentTarget)
  }

  return (
    <NavLink
      onClick={handleClick}
      to={onClick ? "/" : to}
      state={{ scrollToTop: true }}
      className={baseClass({ className, disabled, horizontal })}
      end={end}
    >
      {linkTemplate({ stepNumber, label, sublabel, disabled, horizontal, lineSize })}
    </NavLink>
  )
}

const PathLink = ({
  name = "",
  stepsKey = name,
  stepNumber = null,
  label,
  sublabel,
  className = null,
  end = false,
  horizontal = false,
  lineSize,
  disabled: linkDisabled = null
}) => {
  const showLine = typeof stepNumber === "number" && stepNumber > 1
  const [to, stepDisabled] = useWizardStep({ name, stepsKey, stepNumber })
  const disabled = linkDisabled !== null ? linkDisabled : stepDisabled

  return (
    <NavLink
      to={to}
      onClick={({ currentTarget }) => scrollToActive(currentTarget)}
      state={{ scrollToTop: true }}
      className={baseClass({ className, disabled, horizontal })}
      end={end}
    >
      {linkTemplate({ stepNumber, label, sublabel, disabled, showLine, horizontal, lineSize })}
    </NavLink>
  )
}

const StepLink = ({ name = "", to = null, onClick, ...rest }) =>
  to || onClick ? <CustomLink to={to} onClick={onClick} {...rest} /> : name ? <PathLink name={name} {...rest} /> : null

export default StepLink
