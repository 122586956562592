import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find } from "lodash"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requestedGuide = createAction("guides/requestedGuide")
const receivedGuide = createAction("guides/receivedGuide")
const failedGuide = createAction("guides/failedGuide")
const cleanedGuide = createAction("guides/cleanedGuide")

// REDUCER
const initialState = {
  guide: {
    ...LOADING_INITIAL_STATE,
    id: null
  }
}

const guidesReducer = createReducer(initialState, {
  [requestedGuide.type]: (state) => {
    setRequested(state.guide)
  },
  [receivedGuide.type]: (state, action) => {
    setReceived(state.guide)
    Object.assign(state.guide, action.payload)
  },
  [failedGuide.type]: (state) => {
    setFailed(state.guide)
  },
  [cleanedGuide.type]: (state) => {
    state.guide = initialState.guide
  }
})
export default guidesReducer

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const guide = find(state.guides.items, ["id", +id])

  if (guide) {
    dispatch(receivedGuide(guide))
    return new Promise((resolve) => resolve(guide))
  }
  return dispatch(
    api({
      url,
      onStart: requestedGuide.type,
      onSuccess: receivedGuide.type,
      onError: failedGuide.type
    })
  )
}

// PUBLIC ACTIONS

export const getGuide = (id) => (dispatch, getState) => getItem(`/guides/${id}`, id, dispatch, getState)
export const getClientGuide = (id) => (dispatch, getState) => getItem(`/client/guides/${id}`, id, dispatch, getState)

export const cleanGuide = () => (dispatch) => dispatch(cleanedGuide())
