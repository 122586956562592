import { useEffect, useRef, useState } from "react"
import { useJsApiLoader } from "@react-google-maps/api"
import useGeocoder from "hooks/useGeocoder"

import { PLACE_OPTIONS, API_OPTIONS } from "constants/map"

export default function usePredictions() {
  const { isLoaded } = useJsApiLoader(API_OPTIONS)
  const predictionsRef = useRef(null)
  const geocoder = useGeocoder(null)
  const [place, setPlace] = useState(null)
  const [predictions, setPredictions] = useState([])

  const getPredictions = (input) => {
    if (input) predictionsRef.current.getPlacePredictions({ input, ...PLACE_OPTIONS }, setPredictions)
    else setPredictions([])
  }

  const selectPlace = (placeId, cb) => {
    geocoder({ placeId }, (place) => {
      setPlace(place)
      setPredictions([])
      if (typeof cb === "function") cb(place)
    })
  }

  useEffect(() => {
    if (isLoaded && !predictionsRef.current) predictionsRef.current = new window.google.maps.places.AutocompleteService()
  }, [isLoaded])

  return [predictions, place, getPredictions, selectPlace]
}
