import React from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Button, Container } from "reactstrap"
import Avatar from "components/common/Avatar"
import Icon from "components/common/Icon"

import { useDispatch, useSelector } from "react-redux"
import { createConversation } from "store/conversations"
import { routes } from "router"

const Header = ({ guide, className }) => {
  const { loggedIn } = useSelector((store) => store.auth)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const classes = ["py-20"]
  if (className) classes.push(className)

  const address = []
  if (guide.city) address.push(guide.city)
  if (guide.country) address.push(guide.country)

  const clientMessages = () => {
    dispatch(createConversation({ participant_id: guide.user_id })).then((conversation) =>
      navigate(routes.clientMessagesPath({}, { conversation_id: conversation.id }))
    )
  }

  return (
    <Container className={classes.join(" ")}>
      <div className="hstack gap-10 flex-wrap flex-sm-nowrap">
        <div className="hstack gap-20 w-100 w-md-auto" style={{ flexGrow: 20 }}>
          <Avatar user={guide} size={78} />
          <div className="my-auto flex-fill">
            <div className="hstack gap-1 mb-3">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <Icon key={index} iconName="StarNew" className="text-primary-second" />
                ))}
            </div>
            <h1 className="h3 lh-1 fw-medium d-block">{guide.display_name}</h1>
            <p className="hstack gap-2 mt-2 mb-0 fs-6 fw-light lh-1">
              <Icon iconName="Location" className="flex-shrink-0" size={14} />
              <span>{address.join(", ") || "—"}</span>
            </p>
          </div>
        </div>
        <div className="hstack gap-x-10 gap-y-1 flex-wrap flex-grow-1 flex-sm-grow-0" style={{ flexShrink: 20 }}>
          {searchParams.has("back_url") && (
            <Button
              tag={Link}
              to={searchParams.get("back_url")}
              color="light"
              className="d-flex gap-3 justify-content-center py-2 px-20 fw-medium text-nowrap flex-fill"
            >
              <Icon iconName="ArrowLeft" size={18} block />
              Back to trip
            </Button>
          )}
          <Button onClick={clientMessages} color="primary" className="py-2 px-20 text-white-hover flex-fill" outline disabled={!loggedIn}>
            Message
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default Header
