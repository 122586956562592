import React from "react"
import { Button, Col, Label, Row } from "reactstrap"
import Input from "components/form/Input"
import LanguageChanger from "./LanguageChanger"
import Avatar from "components/common/Avatar"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { compressFile } from "helpers/compress"
import useForm from "hooks/useForm"

import { useSelector, useDispatch } from "react-redux"
import { updateUser } from "store/user"
import { generateCountryOptions } from "components/bookings/helpers"

export default function SettingsForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const loadedUser = useSelector((store) => store.user)
  const {
    first_name = "",
    last_name = "",
    email = "",
    birthday = "",
    phone_number = "",
    gender = "",
    address_line_1 = "",
    address_line_2 = "",
    city = "",
    state = "",
    country = "",
    zip_code = ""
  } = loadedUser
  const prevValues = {
    first_name,
    last_name,
    birthday,
    email,
    phone_number,
    gender,
    address_line_1,
    address_line_2,
    city,
    state,
    country,
    zip_code
  }
  const [user, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(prevValues)

  const handleImageChange = async (e) => {
    const avatar = await compressFile(e.target.files[0])
    const formData = serialize({ avatar })
    if (avatar) dispatch(updateUser(formData))
  }

  submitCallback(() => {
    dispatch(updateUser(user))
  })

  return (
    <form className="mt-40 w-100" onSubmit={submitHandler}>
      <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
        <Col xs={12} sm={3} md={2} className="align-self-center hstack">
          <div className="link-target mw-100">
            <Avatar user={loadedUser} size={116} />
            <div className="hstack position-absolute top-0 start-0 w-100 h-100 z-2">
              <Button tag="label" htmlFor="avatarPhoto" color="ghost" size="icon" className="ms-auto mt-auto">
                <Icon iconName="Edit" className="d-block text-dark" />
              </Button>
              <input
                type="file"
                id="avatarPhoto"
                name="avatarPhoto"
                className="d-none"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={9} md={10}>
          <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
            <Col xs={12} md={6}>
              <Label className="mt-1" for="first_name">
                {t("settings.first_name")} *
              </Label>
              <Input
                id="first_name"
                type="text"
                name="first_name"
                placeholder={t("settings.first_name")}
                value={user.first_name || ""}
                onChange={changeHandler}
                withError
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="mt-1" for="last_name">
                {t("settings.last_name")} *
              </Label>
              <Input
                id="last_name"
                type="text"
                name="last_name"
                placeholder={t("settings.last_name")}
                value={user.last_name || ""}
                onChange={changeHandler}
                withError
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="gender">
            {t("settings.gender_label")}
          </Label>
          <Input
            id="gender"
            type="select"
            name="gender"
            placeholder={t("settings.gender_label")}
            value={user.gender || ""}
            onChange={changeHandler}
            withError
          >
            <option value="male">{t("settings.gender.male")}</option>
            <option value="female">{t("settings.gender.female")}</option>
            <option value="other">{t("settings.gender.other")}</option>
          </Input>
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="birthday">
            {t("settings.birthday")}
          </Label>
          <Input
            id="birthday"
            type="date"
            name="birthday"
            placeholder={t("settings.birthday")}
            value={user.birthday || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="email">
            {t("settings.email")} *
          </Label>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder={t("settings.email")}
            value={user.email || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="phone_number">
            {t("settings.phone")}
          </Label>
          <Input
            id="phone_number"
            type="tel"
            name="phone_number"
            placeholder={t("settings.phone")}
            value={user.phone_number || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <LanguageChanger />
        </Col>
        <Col xs={12}>
          <Label className="mt-1">{t("settings.address.title")}</Label>
          <Input
            type="text"
            name="address_line_1"
            placeholder={t("settings.address.address_line_1")}
            value={user.address_line_1 || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <Input
            type="text"
            name="address_line_2"
            placeholder={t("settings.address.address_line_2")}
            value={user.address_line_2 || ""}
            onChange={changeHandler}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="text"
            name="city"
            placeholder={t("settings.address.city")}
            value={user.city || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="text"
            name="state"
            placeholder={t("settings.address.state")}
            value={user.state || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="select"
            name="country"
            placeholder={t("settings.address.country")}
            value={user.country || ""}
            onChange={changeHandler}
            className={user.country ? "" : "text-gray-light"}
            withError
          >
            {generateCountryOptions()}
          </Input>
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="text"
            name="zip_code"
            placeholder={t("settings.address.zip_code")}
            value={user.zip_code || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <div className="hstack gap-20 justify-content-end mt-25">
          {isChanged && (
            <Button color="light" type="button" onClick={reset}>
              {t("cancel")}
            </Button>
          )}
          <Button color="primary" disabled={!isChanged}>
            {t("settings.update")}
          </Button>
        </div>
      </Row>
    </form>
  )
}
