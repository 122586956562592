import React from "react"
import { Button } from "reactstrap"
import Preview from "components/trips/Preview"
import ActionButtons from "components/wizards/ActionButtons"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

import { useDispatch, useSelector } from "react-redux"
import { updateTrip } from "store/trips"

export default function Step5() {
  useTitle("Trip Wizard — Step 5")
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { trip } = useSelector((store) => store.trips)
  const isPublished = trip.status === "published"

  const publishTripHandler = (event) => {
    if (event.persist) event.persist()
    dispatch(updateTrip(trip.id, { trip: { status: "published" } }))
  }

  return (
    <>
      <Preview isEdit />
      <ActionButtons
        action={updateTrip}
        name="trips.trip"
        submitText={t("trip.wizard.submit")}
        showSubmitAfterCompleted={false}
        className="mt-50"
      >
        {!isPublished && (
          <Button color="primary" onClick={publishTripHandler}>
            {t("global.publish")}
          </Button>
        )}
      </ActionButtons>
    </>
  )
}
