import React, { memo, useEffect, useRef } from "react"
import { Button, DropdownToggle, Label } from "reactstrap"
import { Dropdown } from "components/common/Dropdown"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
import { colorClasses } from "helpers/color"
import { numberToCurrency } from "helpers/string"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { recordGuideBookingDeposit, refundGuideBookingDeposit } from "store/bookings"

import { PAYMENT_STATUS_COLOR } from "components/bookings/constants"
import Spinner from "components/common/Spinner"

const depositFn = {
  record: recordGuideBookingDeposit,
  refund: refundGuideBookingDeposit
}

const Payment = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const recordInputRef = useRef(null)
  const refundInputRef = useRef(null)
  const { booking } = useSelector((store) => store.bookings)
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({ record: 0, refund: 0 })

  const tripCost = (booking.adult_count + booking.child_count) * booking.trip.price_per_person - +booking.refund
  const outstanding = Math.round((tripCost - booking.cash_deposit) * 100) / 100

  const onRecordToggle = () => recordInputRef?.current?.select?.()
  const onRefundToggle = () => refundInputRef?.current?.select?.()

  useEffect(() => {
    if (!form.cash_deposit) changeHandler({ target: { name: "record", value: outstanding } })
    //eslint-disable-next-line
  }, [])

  submitCallback(({ target }) => {
    const cash_deposit = form[target.name]
    const fn = depositFn[target.name]
    fn && dispatch(fn(booking.id, { cash_deposit })).then(reset)
  })

  const dropdownClasses = ["bg-opacity-25 border-0 rounded-pill fw-semibold px-3 py-0 lh-base shadow-none"]
  dropdownClasses.push(colorClasses(booking.payment_status, PAYMENT_STATUS_COLOR))

  const recordClasses = "bg-success text-success bg-opacity-25 border-0 rounded-pill fw-semibold px-3 py-0 lh-base shadow-none"
  const refundClasses = "bg-info text-info bg-opacity-25 border-0 rounded-pill fw-semibold px-3 py-0 lh-base shadow-none"

  return (
    <div className="vstack gap-20 bg-white rounded p-20">
      {form.cash_deposit}
      <div className="hstack gap-10">
        <h5>{t("booking.payment.labels.payment_summary")}</h5>
        <Dropdown
          className="ms-auto"
          classNameMenu="p-15"
          end
          toggleButton={
            <DropdownToggle className={dropdownClasses.join(" ")}>
              {t(`booking.payment.statuses.${booking.payment_status}`, { outstanding: numberToCurrency(outstanding) })}
            </DropdownToggle>
          }
        >
          <div className="vstack gap-10">
            <Label className="fs-7 text-dark text-opacity-50 m-0">
              <small>{t("global.actions")}</small>
            </Label>

            <Dropdown
              classNameMenu="p-15"
              onToggle={onRecordToggle}
              toggleButton={
                <DropdownToggle className={recordClasses} style={{ minWidth: 150 }}>
                  {t("booking.payment.labels.record_payment")}
                </DropdownToggle>
              }
            >
              <div className="vstack gap-10 position-relative">
                <Label for="record" className="fs-7 text-dark text-opacity-50 m-0">
                  <small>{t("booking.payment.labels.enter_cash_amount")}</small>
                </Label>
                <Input id="record" type="number" name="record" value={form.record} onChange={changeHandler} ref={recordInputRef} />
                {isChanged && (
                  <Button color="primary" className="fs-7 px-4 py-2 ms-auto" type="button" onClick={submitHandler} name="record">
                    {t("global.save")}
                  </Button>
                )}
                {booking.depositLoading && <Spinner absolute className="bg-white bg-opacity-75 w-100 h-100" />}
              </div>
            </Dropdown>

            <Dropdown
              classNameMenu="p-15"
              onToggle={onRefundToggle}
              toggleButton={
                <DropdownToggle className={refundClasses} style={{ minWidth: 150 }}>
                  {t("booking.payment.labels.refund_client")}
                </DropdownToggle>
              }
            >
              <div className="vstack gap-10">
                <Label for="refund" className="fs-7 text-dark text-opacity-50 m-0">
                  <small>{t("booking.payment.labels.enter_refund_amount")}</small>
                </Label>
                <Input id="refund" type="number" name="refund" value={form.refund} onChange={changeHandler} ref={refundInputRef} />
                {isChanged && (
                  <Button color="primary-second" className="fs-7 px-4 py-2 ms-auto" type="button" onClick={submitHandler} name="refund">
                    {t("global.save")}
                  </Button>
                )}
                {booking.depositLoading && <Spinner absolute className="bg-white bg-opacity-75 w-100 h-100" />}
              </div>
            </Dropdown>
          </div>
        </Dropdown>
      </div>

      <div className="grid grid-cols-2 gap-10" style={{ maxWidth: 400 }}>
        <span>{t("booking.payment.labels.trip_cost")}:</span>
        <span className="text-end">{numberToCurrency(tripCost)}</span>
        <span>{t("booking.payment.labels.amount_deposited")}:</span>
        <span className="text-end">{numberToCurrency(booking.cash_deposit)}</span>
        <span className="fw-semibold">{t("booking.payment.labels.outstanding_amount")}:</span>
        <span className="text-end fw-semibold">{numberToCurrency(outstanding)}</span>
      </div>
    </div>
  )
}

export default memo(Payment)
