import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Input, Label, Row } from "reactstrap"
import ArrayInput from "components/form/ArrayInput"
import GooglePlacesInput from "components/common/GooglePlacesInput"
import ActionButtons from "components/wizards/ActionButtons"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { useWizardSteps } from "hooks/wizard"
import { map } from "lodash"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import useForm, { useNestedFields } from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import {
  guideSelector,
  isStripeChargesEnabledSelector,
  isStripeConnectedSelector,
  isStripePayoutsEnabledSelector,
  stripeConnect,
  updateGuide
} from "store/user"

import { ReactComponent as StripeIcon } from "assets/images/payment/stripe.svg"

const Step4Form = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()

  const updateStep = useWizardSteps({ name: "user.guide" })
  const guide = useSelector(guideSelector)
  const isStripeConnected = useSelector(isStripeConnectedSelector)
  const isStripeChargesEnabled = useSelector(isStripeChargesEnabledSelector)
  const isStripePayoutsEnabled = useSelector(isStripePayoutsEnabledSelector)
  const operating_locations = useMemo(
    () => guide.operating_locations?.map((address, index) => ({ _id: index + 1, address })) || [],
    [guide.operating_locations]
  )

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ ...guide, operating_locations }, [
    "upload_client_database",
    "operating_locations"
  ])
  const [, , addLocationHandler, removeLocationHandler] = useNestedFields(form, "operating_locations", [], changeHandler)

  const saveChanges = (nextPath) => {
    const operating_locations = map(form.operating_locations, "address")
    const formData = serialize({ guide: { ...updateStep(form), operating_locations } })
    return dispatch(updateGuide(guide.id, formData)).then(() => nextPath && navigate(nextPath))
  }

  const connectStripeAccount = isChanged
    ? confirmModal(
        {
          title:
            "You have unsaved changes!<br/><p class='text-dark text-opacity-50 fs-5 lh-sm'>To connect a stripe account, you will be redirected to the creation page.</p>",
          submitText: t("global.save_and_redirect"),
          cancelText: t("global.cancel"),
          props: { size: "md" }
        },
        () => saveChanges().then(() => dispatch(stripeConnect))
      )
    : () => dispatch(stripeConnect)

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value
    saveChanges(nextPath)
  })

  return (
    <form onSubmit={submitHandler}>
      <Row className="gap-y-3 text-start">
        <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} xxl={{ size: 6, offset: 3 }}>
          <div className="bg-white rounded p-20">
            <h2 className="h4 fw-medium">1. {t("guide.extras.database_to_upload")}</h2>
            <div className="hstack gap-30 mt-15 ps-4">
              <Label check className="hstack align-items-center gap-1 fs-5 fw-normal">
                <Input
                  type="radio"
                  name="upload_client_database"
                  value={"true"}
                  checked={[true, "true"].includes(form.upload_client_database)}
                  onChange={changeHandler}
                  className=""
                />
                <span className="text-dark">{t("global.yes")}</span>
              </Label>
              <Label check className="hstack align-items-center gap-1 fs-5 fw-normal">
                <Input
                  type="radio"
                  name="upload_client_database"
                  value={"false"}
                  checked={[false, "false"].includes(form.upload_client_database)}
                  onChange={changeHandler}
                  className=""
                />
                <span className="text-dark">{t("global.no")}</span>
              </Label>
            </div>
          </div>
        </Col>

        <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} xxl={{ size: 6, offset: 3 }}>
          <div className="bg-white rounded p-20">
            <h2 className="h4 fw-medium">2. {t("guide.extras.operate_from")}</h2>
            <div className="hstack gap-30 mt-15 ps-4">
              <ArrayInput
                form={form}
                name="operating_locations"
                field="address"
                changeHandler={changeHandler}
                addHandler={addLocationHandler}
                removeHandler={removeLocationHandler}
                placeholder="Search Location"
                className="w-100"
                ipnutTag={GooglePlacesInput}
                controlled={false}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} xxl={{ size: 6, offset: 3 }}>
          <div className="bg-white rounded p-20">
            <h2 className="h4 fw-medium">3. {t("guide.extras.stripe_account")}</h2>
            <div className="vstack gap-10 mt-15">
              <div className="hstack gap-30 justify-content-between">
                <StripeIcon className="" width={120} height={50} style={{ width: 80, height: "auto" }} />
                {isStripeConnected ? (
                  <div className="bg-primary bg-opacity-10 rounded-pill hstack gap-2 fs-4 fw-semibold py-2 px-30 text-primary">
                    Connected
                    <Icon iconName="Valid" />
                  </div>
                ) : (
                  <Button color="primary-second" className="d-flex gap-2" onClick={connectStripeAccount}>
                    Connect
                  </Button>
                )}
              </div>
              {isStripeConnected && (
                <>
                  <div className="hstack gap-10 fs-6 fw-semibold">
                    Charge:
                    <span
                      className={[
                        isStripeChargesEnabled ? "bg-primary text-primary" : "bg-danger text-danger",
                        "badge bg-opacity-10 py-1 px-10 rounded-pill"
                      ].join(" ")}
                    >
                      {(isStripeChargesEnabled ? "Enabled" : "Disabled").toUpperCase()}
                    </span>
                  </div>
                  <div className="hstack gap-10 fs-6 fw-semibold">
                    Payouts:
                    <span
                      className={[
                        isStripePayoutsEnabled ? "bg-primary text-primary" : "bg-danger text-danger",
                        "badge bg-opacity-10 py-1 px-10 rounded-pill"
                      ].join(" ")}
                    >
                      {(isStripePayoutsEnabled ? "Enabled" : "Disabled").toUpperCase()}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <ActionButtons withSkip isChanged={isChanged} action={updateGuide} name="user.guide" className="mt-50" />
    </form>
  )
}

export default Step4Form
