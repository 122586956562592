import React from "react"
import SettingsForm from "components/settings/SettingsForm"

// import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function MyDetails() {
  useTitle("Settings — My Details")
  // const { t } = useTranslation()

  return (
    <>
      <SettingsForm />
    </>
  )
}
