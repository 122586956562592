import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Icon from "components/common/Icon"

import { CATEGORY_ICONS } from "constants/accounting"

function LinkWrapperToEditExpence({ record, children, className, color = "primary-second" }) {
  const [searchParams] = useSearchParams()
  if (record.class_name === "Expense") {
    return (
      <Link
        className={["text-dark text-decoration-none bg-opacity-0 bg-opacity-5-hover", `bg-${color}`, className].join(" ")}
        to={routes.guideAccountingEditExpancePath({ id: record.id }, searchParams)}
      >
        {children}
      </Link>
    )
  }
  return <div className={className}>{children}</div>
}

const getCategoryIcon = (slug) => {
  const icon = CATEGORY_ICONS[slug]
  return icon ?? "Income"
}

export default function DayDetails({ date, details = {} }) {
  const { items = [], formatted_total_amount = "—" } = details

  return (
    <div className="bg-white rounded overflow-hidden">
      <div className="hstack justify-content-between gap-10 px-15 py-10 border-bottom border-gray-lightest">
        <p className="fs-6 fw-medium m-0">{date}</p>
        <p className="fs-6 fw-medium m-0">{formatted_total_amount}</p>
      </div>
      {items.length > 0 && (
        <div className="vstack">
          {items.map((record, index) => (
            <LinkWrapperToEditExpence record={record} key={index} className="hstack gap-2 align-items-center fs-7 py-10 px-15">
              <div
                className={[
                  "rounded-circle p-2 bg-opacity-5",
                  record.class_name === "Income" ? "bg-primary text-primary" : "bg-primary-second text-primary-second"
                ].join(" ")}
              >
                <Icon iconName={getCategoryIcon(record.slug)} size={30} />
              </div>
              <div>
                <div className="fst-italic">{record.displayed_name}</div>
                <div className="small text-dark text-opacity-50">{record.displayed_for}</div>
              </div>
              <div className="ms-auto">{record.formatted_amount}</div>
            </LinkWrapperToEditExpence>
          ))}
        </div>
      )}
    </div>
  )
}
