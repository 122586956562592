import React from "react"
import Step1Form from "components/trips/wizard/Step1Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function Step1() {
  useTitle("Trip Wizard — Step 1")
  const { t } = useTranslation()
  const title = t("trip.wizard.step_1.title")
  const subtitle = t("trip.wizard.step_1.subtitle")

  return (
    <>
      <div className="text-center">
        {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
        {subtitle && <p className="fs-5 mt-15 mb-40 text-dark text-opacity-50">{subtitle}</p>}
      </div>
      <DefaultErrorBoundary>
        <Step1Form />
      </DefaultErrorBoundary>
    </>
  )
}
