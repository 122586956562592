import React, { useCallback, useEffect } from "react"
import { Button, Col, Row } from "reactstrap"
import { Link, Outlet } from "react-router-dom"
import { routes } from "router"
import Spinner from "components/common/Spinner"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import Boat from "components/boats/Boat"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"
import { isLastPage } from "helpers/pagination"
import { Waypoint } from "react-waypoint"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanBoat, getGuideBoats } from "store/boats"

export default function Boats() {
  useTitle("Boats")
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const { items, loading, metadata } = useSelector((state) => state.boats)
  const hasBoat = !!items?.length

  const fetchBoats = useCallback((nextPage = false) => dispatch(getGuideBoats(nextPage)), [dispatch])

  useEffect(() => {
    fetchBoats()
    return () => dispatch(cleanBoat())
    //eslint-disable-next-line
  }, [])

  return (
    <div className="flex-fill bg-light z-0" id="boats">
      <div className="sticky-top mt-n30 mb-30" style={{ top: headerHeight }}>
        <div className="full-window-block align-items-center bg-white py-10 py-sm-20 shadow">
          <h1 className="h3 fw-medium lh-1">Boats</h1>
          <Button tag={Link} color="primary" className="px-20 py-10 fs-7 ms-auto my-n1" to={routes.guideAddNewBoatPath()}>
            {t("boats.add_new")}
          </Button>
        </div>
      </div>
      <div className="vstack gap-20">
        <Row className="gap-y-20 gx-20 gap-y-xl-35 gx-xl-35 mt-25">
          {hasBoat
            ? items.map((boat) => (
                <Col xs={12} sm={6} xl={3} key={boat.id}>
                  <Link
                    className={"text-dark text-decoration-none bg-opacity-0 bg-opacity-5-hover bg-primary-second"}
                    to={routes.guideEditBoatPath({ id: boat.id })}
                  >
                    <Boat boat={boat} className="h-100" />
                  </Link>
                </Col>
              ))
            : !loading && (
                <div className="bg-white p-20 rounded">
                  <h2 className="h3">No boats at the moment</h2>
                  <p className="text-dark text-opacity-50 mb-0">Try creating a new boat and it will be displayed here</p>
                </div>
              )}
        </Row>

        {loading && <Spinner className="mt-40" />}
        {!isLastPage(metadata) && !loading && hasBoat && <Waypoint onEnter={() => fetchBoats(true)} />}
        <DefaultErrorBoundary>
          <Outlet />
        </DefaultErrorBoundary>
      </div>
    </div>
  )
}
