export const DEFAULT_TYPE = "upcoming"

export const STATS_NAMES = {
  upcoming: "Upcoming",
  all: "All",
  outstanding: "Payment Outstanding",
  full_paid: "Paid",
  canceled: "Canceled",
  past: "Past"
}
export const TYPE_OPTIONS_ENUM = {
  email: "Email",
  text: "Text",
  message: "Message"
}

export const DEFAULT_RANGE = "month"

export const RANGE_OPTIONS = {
  month: "Month",
  week: "Week"
}

export const STATUS_COLOR = {
  booked: "primary",
  completed: "primary-second",
  canceled: "dark",
  unpaid: "warning"
}

export const PAYMENT_STATUS_COLOR = {
  pending: "dark",
  outstanding: "warning",
  full_paid: "success"
}

export const PAYMENT_FULL_TODAY = "full_amount_today"
export const PAYMENT_DEPOSIT = "deposit_and_balance_later"
export const PAYMENT_DEPOSIT_CASH = "deposit_and_balance_in_cash"
export const PAYMENT_FULL_CASH_ON_DAY = "full_amount_in_cash_on_day"

export const PAYMENT_TYPES = {
  default: [PAYMENT_FULL_TODAY],
  cash: [PAYMENT_FULL_TODAY, PAYMENT_FULL_CASH_ON_DAY],
  deposit: [PAYMENT_FULL_TODAY, PAYMENT_DEPOSIT],
  cash_and_deposit: [PAYMENT_FULL_TODAY, PAYMENT_DEPOSIT, PAYMENT_DEPOSIT_CASH, PAYMENT_FULL_CASH_ON_DAY]
}
