import React, { useEffect, useMemo, useState } from "react"
import { Button, Label, Modal, ModalBody, TabContent, TabPane, Accordion, AccordionItem, AccordionBody, AccordionHeader } from "reactstrap"
import { BtnBold, BtnItalic, BtnUnderline, Editor, EditorProvider, Toolbar, createButton } from "react-simple-wysiwyg"

import { useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Input from "components/form/Input"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { filter } from "lodash"
import { dataToObject } from "helpers/form"
import useForm from "hooks/useForm"
import pluralize from "pluralize"

import { useDispatch, useSelector } from "react-redux"
import { sendEmail, sendSms, sendMessage } from "store/clients"
import { hideModal, resetModal, showModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"
import InputError from "components/form/InputError"

const ActionForm = ({ onChanged, type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const BtnAlignCenter = createButton("Align center", <Icon iconName={"TextAlignCenter"} size={13} />, "justifyCenter")
  const BtnAlignStart = createButton("Align Start", "≡", "justifyLeft")

  const [searchParams] = useSearchParams()
  const userIds = dataToObject(searchParams)["user_ids[]"]
  const clients = useSelector((store) => store.clients.items)
  const selectedClients = useMemo(() => filter(clients, (client) => userIds.includes(client.user_id)), [userIds, clients])

  const { metadata } = useSelector((store) => store.clients)

  const selectedEmails = useMemo(() => selectedClients.map((item) => item.user.email), [selectedClients])
  const selectedNames = useMemo(() => selectedClients.map((item) => item.user.first_name), [selectedClients])
  const selectedPhones = useMemo(
    () => selectedClients.map((item) => item.user.phone_number || "").filter((item) => item !== ""),
    [selectedClients]
  )

  const [messageType, setMessageType] = useState("custom")
  const templateModal = useSelector(modalSelector("templateModal"))

  const templates = metadata[`${type}_templates_collection`]

  const openHandler = () => dispatch(showModal("templateModal"))
  const closeHandler = () => dispatch(hideModal("templateModal"))

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm({ client_ids: userIds }, [
    "body",
    "subject",
    "client_ids",
    "template_id"
  ])

  const onClosedHandler = () => dispatch(resetModal("templateModal")) && navigate(routes.guideClientsPath({}, searchParams))

  submitCallback(() => {
    const data = {}
    data.client_ids = form.client_ids
    if (messageType === "template") data.template_id = form.template_id
    else if (messageType === "custom") {
      data.body = form.body
      data.subject = form.subject
    }
    const formDataType = {
      email: "email",
      text: "sms",
      message: "message"
    }
    const formData = serialize({ [formDataType[type]]: form })

    const fn = {
      email: sendEmail,
      text: sendSms,
      message: sendMessage
    }
    dispatch(fn[type](formData)).then(() => openHandler())
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  const selectedItems = {
    email: selectedEmails,
    text: selectedPhones,
    message: selectedNames
  }

  const typeLables = {
    email: "Client email",
    text: "Client phone",
    message: "Client"
  }

  return (
    <form onSubmit={submitHandler}>
      <Modal
        isOpen={templateModal.isOpen}
        size="md"
        centered
        scrollable
        backdropClassName="backdrop-blur-3"
        contentClassName="rounded-sm-3"
        toggle={closeHandler}
        onClosed={onClosedHandler}
      >
        <ModalBody className="vstack align-items-center gap-20 p-20 text-center">
          <h3>{`Email Successfully Sent to ${pluralize("customer", selectedItems[type].length, true)}.`}</h3>
          <Button color="primary" className="text-white" onClick={closeHandler}>
            OK
          </Button>
        </ModalBody>
      </Modal>

      <div className="vstack gap-20">
        <div className="vstack gap-20 bg-white rounded p-20">
          <div className="hstack align-items-center">
            <Label className="fs-6 m-0">{t(`client.${type}`)}</Label>
            <div className="bg-primary text-white rounded-pill fs-7 fw-semibold ms-20 px-10 py-1">
              {selectedItems[type].length} {pluralize(typeLables[type], selectedItems[type].length)}
            </div>
          </div>
          <div className="fs-7 fw-normal text-dark text-opacity-50">{selectedItems[type]?.join(", ")}</div>
          <div className="hstack gap-15 mt-15">
            <Button
              color={messageType === "custom" ? "primary-second" : "gray-lightest"}
              className="px-30 py-1 fs-6 fw-normal rounded-1"
              onClick={() => setMessageType("custom")}
            >
              {t(`client.new_${type}`)}
            </Button>
            {type !== "message" && (
              <Button
                color={messageType === "template" ? "primary-second" : "gray-lightest"}
                className="px-30 py-2 fs-7 rounded-1"
                onClick={() => setMessageType("template")}
              >
                {"Templates"}
              </Button>
            )}
          </div>

          <TabContent activeTab={messageType} className="w-100">
            <TabPane tabId="custom">
              {type !== "message" && (
                <div>
                  <Input
                    id="subject"
                    type="text"
                    name="subject"
                    placeholder={t("client.subject")}
                    value={form.subject || ""}
                    onChange={changeHandler}
                    className="fs-6"
                    withError
                  />
                </div>
              )}
              <div className={type === "message" ? "pb-20" : "py-20"}>
                <EditorProvider>
                  <Editor value={form.body || ""} onChange={changeHandler} style={{ height: "200px" }} id="body" name="body">
                    {type !== "message" && (
                      <Toolbar style={{ background: "white" }}>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnAlignCenter />
                        <BtnAlignStart />
                      </Toolbar>
                    )}
                  </Editor>
                </EditorProvider>
                <InputError field="body" />
              </div>
              <div className="hstack gap-10">
                <Button color="primary" className="fs-7 ms-auto" disabled={!isChanged}>
                  {t("global.send")}
                </Button>
              </div>
            </TabPane>

            {type !== "message" && (
              <TabPane tabId="template">
                <Accordion
                  className="contact-templates"
                  toggle={(value) =>
                    changeHandler({ target: { name: "template_id", value: form.template_id === value ? null : value.toString() } })
                  }
                  open={form.template_id?.toString() || ""}
                >
                  {templates?.map((template, index) => (
                    <AccordionItem
                      key={index}
                      className={[
                        "overflow-hidden  border-bottom border-end-0 border-start-0 border-gray-lightest rounded-0",
                        !index ? "border-top" : "",
                        +form.template_id === +template.id ? "bg-primary-second bg-opacity-5" : ""
                      ].join(" ")}
                    >
                      <AccordionHeader targetId={template.id.toString()}>
                        <h2 className="h5 lh-sm ps-10 py-15">{template.name}</h2>
                      </AccordionHeader>
                      <AccordionBody accordionId={template.id.toString()}>
                        {template.heading && <h5 className=" pt-10">{template.heading}</h5>}
                        {<span className="fs-7 py-10 ">{"Hello [Client Name]"}</span>}
                        <div className="fs-7">{template.paragraph_text}</div>
                        <div className="pt-10 fs-7 mt-auto">{template.signature}</div>
                        <div className="fs-7 fw-bold fst-italic">{"[Guide profile name]"}</div>
                        <Button color="primary" className="fs-7 mt-20">
                          {t("global.send")}
                        </Button>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Accordion>
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>
    </form>
  )
}

export default ActionForm
