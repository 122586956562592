import { createSelector } from "@reduxjs/toolkit"
import { get } from "lodash"

export const loadingSelector = (name) =>
  createSelector(
    (state) => get(state, name),
    (obj) => obj.loading
  )

export const firstLoadingSelector = (name) =>
  createSelector(
    (state) => get(state, name),
    (obj) => (obj.loading && !obj.firstLoaded) || !obj.firstLoaded
  )

export const notFirstLoadingSelector = (name) =>
  createSelector(
    (state) => get(state, name),
    (obj) => obj.loading && obj.firstLoaded
  )

export const containerLoadingSelector = (containerName, firstLoading = false) =>
  createSelector([(state) => state.loaderWatchers[containerName] || [], (state) => state], (wathers, state) =>
    wathers.some((watcher) => (firstLoading ? firstLoadingSelector(watcher)(state) : notFirstLoadingSelector(watcher)(state)))
  )

export const loadingWatchersSelector = (firstLoading = false) =>
  createSelector([(state) => state, (state) => Object.keys(state.loaderWatchers)], (state, loaderWatcherContainers) =>
    loaderWatcherContainers.reduce(
      (acc, container) => ({
        ...acc,
        [container]: (state.loaderWatchers[container] || []).some((watcher) =>
          firstLoading ? firstLoadingSelector(watcher)(state) : notFirstLoadingSelector(watcher)(state)
        )
      }),
      {}
    )
  )
