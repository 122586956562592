import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Col, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Trip from "components/trips/Trip"
import Spinner from "components/common/Spinner"
import ZoomButtons from "components/map/ZoomButtons"
import CurrentLocationButton from "components/map/CurrentLocationButton"
import TripMapMarker from "components/trips/TripMapMarker"
import WithEmptyState from "components/common/WithEmptyState"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Loading from "modules/loader-watchers/Loading"

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"
import { pick, omit } from "lodash"
import { formatAddressComponent, getLocalStorageBrowserLocation } from "helpers/location"
import { isEmptyFields } from "helpers/form"
import { isLastPage } from "helpers/pagination"
import { useTitle } from "hooks/useTitle"
import useGeocoder from "hooks/useGeocoder"
import useSearchFilter from "hooks/useSearchFilter"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTrip, cleanTrips, getTrips } from "store/trips"
import { collectionAnySelector, collectionSelector, metadataSelector } from "store/selectors"

import { API_OPTIONS, DEFAULT_ZOOM, MAP_OPTIONS } from "constants/map"
import { TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"

export default function Trips() {
  useTitle("Search Trips")
  const { isLoaded } = useJsApiLoader(API_OPTIONS)
  const dispatch = useDispatch()
  const geocoder = useGeocoder(null)
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const trips = useSelector(collectionSelector("trips"))
  const hasTrips = useSelector(collectionAnySelector("trips"))
  const metadata = useSelector(metadataSelector("trips"))

  const [hoverItemId, setHoverItem] = useState(null)
  const [activeItemId, setActiveItem] = useState(null)
  const [tripMouseOver, setTripMouseOver] = useState(false)

  const [map, setMap] = useState(null)
  const [boundsLatLng, setBoundsLatLng] = useState(null)
  const [params, updateSearchFilters, searchUpdatedCallback] = useSearchFilter({
    accessAttributes: TRIP_FILTERS,
    exceptDependencies: TRIP_FILTERS_HELP_ATTRIBUTES,
    additionalDependencies: [boundsLatLng?.northeast_corner, boundsLatLng?.southwest_corner]
  })
  const browserLocation = useMemo(() => getLocalStorageBrowserLocation(), [])
  const paramsLatLng = useMemo(() => (isEmptyFields(params, ["lat", "lng"]) ? null : pick(params, ["lat", "lng"])), [params])
  const [centerLatLng, setCenterLatLng] = useState(paramsLatLng || browserLocation)
  const zoom = map?.zoom || params.zoom || DEFAULT_ZOOM

  const updateSearchHandler = useCallback(
    (location) =>
      geocoder({ location }, (place) =>
        updateSearchFilters({ ...location, zoom, address: formatAddressComponent(place.address_components, ["locality", "political"]) })
      ),
    [zoom, geocoder, updateSearchFilters]
  )

  const setBoundsHandler = useCallback(
    (loadedMap) => {
      const bounds = (loadedMap || map)?.getBounds()
      if (!bounds) return
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const northeast_corner = Object.keys(ne)
        .reduce((acc, key) => acc.concat(ne[key]()), [])
        .join(", ")
      const southwest_corner = Object.keys(sw)
        .reduce((acc, key) => acc.concat(sw[key]()), [])
        .join(", ")
      setBoundsLatLng({ northeast_corner, southwest_corner })
    },
    [map]
  )

  const onLoad = useCallback((map) => setMap(map), [])
  const onUnmount = useCallback(() => setMap(null), [])

  const onIdleHandler = useCallback(() => {
    const mapCenter = map?.center
    if (!mapCenter) return
    const lat = mapCenter.lat()
    const lng = mapCenter.lng()
    updateSearchHandler({ lat, lng })
    setBoundsHandler(map)
  }, [map, updateSearchHandler, setBoundsHandler])

  useEffect(() => {
    if (paramsLatLng) setCenterLatLng(paramsLatLng)
  }, [paramsLatLng?.lat, paramsLatLng?.lng]) //eslint-disable-line

  useEffect(() => {
    dispatch(cleanTrip())
    return () => {
      setMap(null)
      dispatch(cleanTrips())
    }
  }, []) //eslint-disable-line

  const fetchTrips = (nextPage = false) => {
    if (!boundsLatLng?.northeast_corner || !boundsLatLng?.southwest_corner) return
    const search = { ...boundsLatLng, q: omit(params, TRIP_FILTERS_HELP_ATTRIBUTES) }
    if (!nextPage) window.scrollTo(0, 0)
    dispatch(getTrips(nextPage, search))
  }

  searchUpdatedCallback(() => fetchTrips())

  return (
    <Row className="flex-fill g-0 bg-light z-0">
      <Col
        xs={12}
        md={{ size: 7, order: 1 }}
        className="vstack sticky-top z-0"
        style={{ top: headerHeight, height: `calc(100vh - ${headerHeight}px)` }}
      >
        {isLoaded ? (
          <GoogleMap
            mapContainerClassName="h-100"
            options={{ ...MAP_OPTIONS, clickableIcons: false }}
            center={centerLatLng || paramsLatLng}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onIdle={onIdleHandler}
            onClick={() => !tripMouseOver && setActiveItem(null)}
          >
            <div className="vstack position-absolute p-10 w-100 h-100 gap-10 pointer-events-none">
              <div className="pointer-events-auto hstack gap-2 ms-auto ">
                <CurrentLocationButton />
              </div>
              {centerLatLng ? (
                <>
                  {trips.map((trip) => (
                    <TripMapMarker trip={trip} key={trip.id} isActive={hoverItemId === trip.id} toggle={setActiveItem} />
                  ))}
                  {activeItemId && (
                    <TripMapMarker
                      trip={trips.find((trip) => trip.id === activeItemId)}
                      isActive
                      withPreview
                      toggle={setActiveItem}
                      onMouseOver={() => setTripMouseOver(true)}
                      onMouseLeave={() => setTripMouseOver(false)}
                    />
                  )}
                </>
              ) : (
                <div className="hstack h-100 p-50">
                  <Spinner className="m-auto" />
                </div>
              )}
              <ZoomButtons />
            </div>
          </GoogleMap>
        ) : (
          <div className="hstack h-100 p-50">
            <Spinner className="m-auto" />
          </div>
        )}
      </Col>
      <Col xs={12} md={5} className="z-1">
        <FirstLoading name="trips" className="p-20 h-100">
          <Loading name="trips" className="vstack p-20 h-100 bg-white shadow" spinnerProps={{ position: "fixed" }}>
            <WithEmptyState
              name="trip"
              hasItems={hasTrips}
              className="vstack gap-15"
              fallbackTitle={"No exact matches"}
              fallbackText={"Try changing or removing some filters or customizing your search area."}
            >
              {trips.map((trip) => (
                <Trip
                  trip={trip}
                  key={trip.id}
                  onMouseOver={() => setHoverItem(trip.id)}
                  onMouseLeave={() => setHoverItem(null)}
                  withRating
                />
              ))}
              {!isLastPage(metadata) && <Waypoint onEnter={() => fetchTrips(true)} />}
            </WithEmptyState>
          </Loading>
        </FirstLoading>
      </Col>
    </Row>
  )
}
