import React from "react"
import { Col, Input, Label, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import ActionButtons from "components/wizards/ActionButtons"

import useForm from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { updateGuide } from "store/user"

import { ReactComponent as FishingImage } from "assets/images/fishing.svg"
import { ReactComponent as HuntingImage } from "assets/images/hunting.svg"

// import { ACTIVITIES } from "constants/trip"
const ACTIVITIES = ["fishing", "hunting"]

const images = {
  fishing: <FishingImage />,
  hunting: <HuntingImage />
}

const Step1Form = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "user.guide" })
  const guide = useSelector((store) => store.user.guide)
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(guide, ["activity_type"], {
    force: guide.wizard_step === "activity"
  })

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value
    dispatch(updateGuide(guide.id, updateStep(form))).then(() => navigate(nextPath))
  })

  return (
    <Row tag="form" onSubmit={submitHandler}>
      <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }}>
        <div className="hstack gap-25">
          {ACTIVITIES.map((activity) => (
            <Label
              check
              className={`flex-grow-1 vstack align-items-center gap-1 p-15 bg-white border ${
                form.activity_type === activity ? "border-gray" : "border-gray-lightest"
              } rounded h6 fs-7 fw-medium shadow-sm`}
              key={activity}
            >
              <Input
                type="radio"
                name="activity_type"
                value={activity}
                checked={form.activity_type === activity}
                onChange={changeHandler}
                className="me-auto"
                disabled={activity === "hunting" ? true : false}
              />
              {images[activity]}
              <span className={`text-dark ${form.activity_type === activity ? "" : "text-opacity-50"} mt-4 mb-10`}>
                {t(`guide.activity_types.${activity}`)}
              </span>
            </Label>
          ))}
        </div>
        <ActionButtons isChanged={isChanged} action={updateGuide} name="user.guide" className="mt-50" />
      </Col>
    </Row>
  )
}

export default Step1Form
