import React from "react"
import { Col, Row } from "reactstrap"

import PhotoSlider from "components/common/PhotoSlider"

export default function Preview({ boat, isOdd = false }) {
  const { name, description, custom_info, properties: photos } = boat

  return (
    <article className="flex-fill rounded p-20 my-15">
      <Row className={isOdd ? "flex-row-reverse" : "flex-row"}>
        <Col>
          <h3 className="h3 fw-medium lh-sm text-start me-auto">{name}</h3>
          <p>{description}</p>
          <div className="grid grid-cols-2 gap-x-20 gap-y-10">
            {custom_info.map((item) => (
              <p key={item} className="m-0">
                - {item}
              </p>
            ))}
          </div>
        </Col>
        <Col>
          <PhotoSlider photos={photos} preview className="w-100 h-100" />
        </Col>
      </Row>
    </article>
  )
}
