import { useCallback, useEffect, useMemo } from "react"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { pick, omit, omitBy, isNull } from "lodash"
import { parse } from "helpers/string"

export const toAccessSearchString = (object, accessAttributes = []) =>
  createSearchParams(omitBy(pick(object, accessAttributes), isNull)).toString()

const accessParams = (searchParams, accessAttributes) =>
  accessAttributes.reduce((acc, key) => {
    const arrRegex = /\[\]$/g
    const isArr = key.match(arrRegex)?.length
    const value = isArr ? searchParams.getAll(key).map(parse) : parse(searchParams.get(key))
    return { ...acc, [key]: value }
  }, {})

export default function useSearchFilter({ accessAttributes = [], exceptDependencies = [], additionalDependencies = [] }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const accessedParams = useMemo(() => accessParams(searchParams, accessAttributes), [searchParams, accessAttributes])
  const accessedParamsFiltered = useMemo(() => omitBy(accessedParams, isNull), [accessedParams])

  let updatedCallback
  const setCallback = (cb) => {
    if (typeof cb === "function") {
      updatedCallback = cb
    }
  }

  const updateSearchFilters = useCallback(
    (params) => {
      setSearchParams(
        (prevParams) => {
          const accessedParams = accessParams(prevParams, accessAttributes)
          const newParams = omitBy(
            {
              ...omitBy(accessedParams, isNull),
              ...pick(params, accessAttributes)
            },
            isNull
          )
          return newParams
        },
        { replace: true }
      )
    },
    [accessAttributes, setSearchParams]
  )

  const dependencies = Object.values(omit(accessedParams, exceptDependencies)).map((item) => (item instanceof Array ? item.length : item))
  useEffect(() => {
    if (updatedCallback) updatedCallback(accessedParamsFiltered, additionalDependencies)
  }, [...additionalDependencies, ...dependencies]) // eslint-disable-line

  return [accessedParamsFiltered, updateSearchFilters, setCallback]
}
