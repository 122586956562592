import React, { memo, useEffect, useMemo, useState } from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { numberFormat } from "helpers/string"
import { moonPhaseImage, moonRiseAndSetCycles } from "helpers/weather"

import { useSelector } from "react-redux"
import { firstForecastSelector } from "store/weather"

import { TIME_FORMAT } from "constants/date"

function Moon({ className, onNextDays, onlyBody = false, maxWidth = 350 }) {
  const { t } = useTranslation()
  const firstForecast = useSelector(firstForecastSelector)
  const [moonCycles, setMoonCycles] = useState([])

  const currentTime = moment(firstForecast.forecast_date).hours(moment().hours()).minutes(moment().minutes())
  const {
    start,
    end,
    illumination,
    waxing,
    overHorizon = false
  } = useMemo(() => moonCycles.find((cycle) => currentTime.isBetween(cycle.start, cycle.end)) || {}, [moonCycles, currentTime])

  useEffect(() => {
    moonRiseAndSetCycles({ lat: +firstForecast.latitude, lon: +firstForecast.longitude }, firstForecast.forecast_date).then(setMoonCycles)
  }, [firstForecast.latitude, firstForecast.longitude, firstForecast.forecast_date])

  if (!start || !end) return null

  const { moonPhase } = firstForecast?.forecast_json?.daily || {}
  const timeToEndCycle = end.diff(currentTime, "hour")
  const moonwayDuration = Math.abs(end.diff(start, "minute"))
  const currentDuration = currentTime.diff(start, "minute")

  const moonPositionPercent = currentDuration / moonwayDuration
  const moonPositionAngle = moonPositionPercent * 180 * (overHorizon ? 1 : -1) || 0

  const major_time_1 = start.clone().add(moonwayDuration / 2, "minute")
  const major_time_2 = moonCycles[2].start.clone().add(moonCycles[2].end.diff(moonCycles[2].start, "minute") / 2, "minute")

  const minor_time_1 = start.clone()
  const minor_time_2 = end.clone()

  const classes = []
  if (!onlyBody) classes.push("bg-white rounded p-25 overflow-hidden")
  if (className) classes.push(className)

  const moonImage = moonPhaseImage(illumination, waxing)

  return (
    <div className={classes.join(" ")}>
      {!onlyBody && (
        <div className="hstack justify-content-between">
          <h3>{t("weather.moon")}</h3>
          <Button value="moon" onClick={onNextDays} color="primary-second" className="py-2 px-20">
            {t("weather.next_days")}
          </Button>
        </div>
      )}
      <div className="hstack justify-content-center gap-10 my-10">
        <img src={moonImage} alt="" width="76" height="76" />
        <div className="fs-3">
          {moonPhase}
          <div className="fs-5 text-dark text-opacity-50">
            {numberFormat(illumination * 100, { style: "unit", unit: "percent", maximumFractionDigits: 2 })}
          </div>
        </div>
      </div>
      <div className={`hstack gap-30 align-items-${overHorizon ? "end" : "start"} w-100 ${overHorizon ? "pb-40" : "pt-20"}`}>
        <div className="text-center fs-3 mt-n20 mb-n30">
          <Icon iconName={`Moon${overHorizon ? "rise" : "set"}`} block className="mx-auto mb-1" />
          {start.format(TIME_FORMAT)}
          {!start.isSame(firstForecast.forecast_date, "day") && <div className="fs-6 lh-1">Previous day</div>}
          <div className="fs-5 text-dark text-opacity-50">{t(`weather.${overHorizon ? "moonrise" : "moonset"}`)}</div>
        </div>

        <div className="flex-fill mx-auto position-relative" style={{ maxWidth }}>
          <div
            className={`w-100 border-${overHorizon ? "bottom" : "top"} border-5 border-light position-relative`}
            style={{ paddingTop: "50%" }}
          >
            <div
              className={`border border-5 border-dark border-${overHorizon ? "bottom" : "top"}-0 h-100 w-100 position-absolute start-0 ${
                overHorizon ? "bottom" : "top"
              }-0`}
              style={{ borderRadius: overHorizon ? "1000px 1000px 0 0" : "0 0 1000px 1000px" }}
            />
            <div
              className={`position-absolute ${overHorizon ? "bottom" : "top"}-0 start-0 w-100 px-1 pointer-events-none`}
              style={{ rotate: `${moonPositionAngle}deg` }}
            >
              <div className="border border-2 border-dark rounded-circle bg-white m-n10" style={{ width: 20, height: 20 }} />
            </div>
          </div>
          <span
            className={`fs-3 text-dark text-opacity-50 text-center text-nowrap pb-2 position-absolute ${
              overHorizon ? "bottom" : "top"
            }-0 start-50 translate-middle-x`}
          >
            {t(`weather.${overHorizon ? "moonset" : "moonrise"}_in`, { hours: timeToEndCycle })}
          </span>
        </div>

        <div className="text-center fs-3 mt-n20 mb-n30">
          <Icon iconName={`Moon${overHorizon ? "set" : "rise"}`} block className="mx-auto mb-1" />
          {end.format(TIME_FORMAT)}
          {!end.isSame(firstForecast.forecast_date, "day") && <div className="fs-6 lh-1">Next day</div>}
          <div className="fs-5 text-dark text-opacity-50">{t(`weather.${overHorizon ? "moonset" : "moonrise"}`)}</div>
        </div>
      </div>
      <div className="hstack gap-20 justify-content-between mt-20">
        <div>
          <span className="text-dark text-opacity-50">{t("weather.major_times")}</span>
          <div className="fs-3 lh-sm">
            {major_time_1.clone().subtract(1, "hour").format(TIME_FORMAT)} - {major_time_1.clone().add(1, "hour").format(TIME_FORMAT)}
          </div>
          <div className="fs-3 lh-sm">
            {major_time_2.clone().subtract(1, "hour").format(TIME_FORMAT)} - {major_time_2.clone().add(1, "hour").format(TIME_FORMAT)}
          </div>
        </div>
        <div>
          <span className="text-dark text-opacity-50">{t("weather.minor_times")}</span>
          <div className="fs-3 lh-sm">
            {minor_time_1.clone().subtract(0.5, "hour").format(TIME_FORMAT)} - {minor_time_1.clone().add(0.5, "hour").format(TIME_FORMAT)}
          </div>
          <div className="fs-3 lh-sm">
            {minor_time_2.clone().subtract(0.5, "hour").format(TIME_FORMAT)} - {minor_time_2.clone().add(0.5, "hour").format(TIME_FORMAT)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Moon)
