import React, { useEffect, useState } from "react"
import { Button, Dropdown, DropdownToggle } from "reactstrap"
import { DropdownMenu } from "components/common/Dropdown"
import Trip from "./Trip"

import { OverlayView } from "@react-google-maps/api"
import { useMedia } from "modules/window/hooks/useMedia"

const TripMapMarker = ({ trip, isActive = false, withPreview = false, toggle, onMouseOver, onMouseLeave }) => {
  const toSm = useMedia({ to: "sm" })
  const [prevTrip, setPrevTrip] = useState(trip)
  const [isOpen, setIsOpen] = useState(false)

  const toggleHandler = (id) => () => typeof toggle === "function" && toggle(id)

  useEffect(() => {
    if (prevTrip?.id === trip?.id) setIsOpen(true)
    else {
      setIsOpen(false)
      setPrevTrip(trip)
    }
    //eslint-disable-next-line
  }, [prevTrip?.id, trip?.id])

  return trip ? (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={{ lat: trip.latitude, lng: trip.longitude }}
      getPixelPositionOffset={(width, height) => ({
        x: width / -2,
        y: height / -2
      })}
    >
      <div className="position-absolute translate-middle z-2-hover" style={{ zIndex: isActive ? 2 : 0 }}>
        {withPreview ? (
          <Dropdown direction="down" isOpen={isOpen} toggle={() => {}}>
            <DropdownToggle
              color={isOpen ? "primary" : "primary-second"}
              className="bg-primary-hover rounded py-1 px-2 fs-7 fw-semibold lh-1 text-white shadow transition"
              style={{ transform: isOpen && "scale(1.2)" }}
              onClick={toggleHandler(null)}
            >
              ${Math.round(trip.price_per_person)}
            </DropdownToggle>
            <DropdownMenu
              {...(toSm ? { container: ".gm-style" } : {})}
              className={["bg-transparent shadow-none", toSm ? "p-4 map-bottom-dropdown" : "p-0"].join(" ")}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            >
              <Trip trip={trip} preview onClose={toggleHandler(null)} />
            </DropdownMenu>
          </Dropdown>
        ) : (
          <Button
            color={isActive ? "primary" : "primary-second"}
            className="bg-primary-hover border-0 rounded py-1 px-2 fs-7 fw-semibold lh-1 text-white shadow transition"
            style={{ transform: isActive && "scale(1.3)" }}
            onClick={toggleHandler(trip.id)}
          >
            ${Math.round(trip.price_per_person)}
          </Button>
        )}
      </div>
    </OverlayView>
  ) : null
}

export default TripMapMarker
