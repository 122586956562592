import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import ActionButtons from "components/wizards/ActionButtons"
import Icon from "components/common/Icon"
import Spinner from "components/common/Spinner"
import GooglePlacesInput from "components/common/GooglePlacesInput"
import CurrentLocationButton from "components/map/CurrentLocationButton"
import ZoomButtons from "components/map/ZoomButtons"

import { serialize } from "object-to-formdata"
import { useWizardSteps } from "hooks/wizard"
import { isEqual } from "lodash"
import { getLocalStorageBrowserLocation } from "helpers/location"

import { useDispatch, useSelector } from "react-redux"
import { tripLatLngSelector, updateTrip } from "store/trips"
import { modelSelector } from "store/selectors"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"

import { API_OPTIONS, DEFAULT_ZOOM, MAP_OPTIONS, MARKER_ICON } from "constants/map"

const Step4Form = () => {
  const { isLoaded } = useJsApiLoader(API_OPTIONS)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "trips.trip" })

  const trip = useSelector(modelSelector("trip"))
  const tripLatLng = useSelector(tripLatLngSelector)

  const browserLocation = useMemo(() => getLocalStorageBrowserLocation(), [])
  const [map, setMap] = useState(null)
  const [mapCenter, setMapCenter] = useState(map?.center)
  const [centerLatLng, setCenterLatLng] = useState(tripLatLng || browserLocation)
  const zoom = map?.zoom || DEFAULT_ZOOM

  const mapCenterLatLng = useMemo(() => mapCenter && { lat: mapCenter.lat(), lng: mapCenter.lng() }, [mapCenter])

  const isChanged = useMemo(() => !isEqual(tripLatLng, mapCenterLatLng), [tripLatLng, mapCenterLatLng])

  const centerChangedHandler = useCallback(() => setMapCenter(map?.center), [map])
  const placeChangedHandler = useCallback(({ target: { coordinates } }) => setCenterLatLng(coordinates), [])

  const resetLocation = () => setCenterLatLng({ ...tripLatLng })

  const onLoad = useCallback((map) => setMap(map) || setMapCenter(map?.center), [])
  const onUnmount = useCallback(() => setMap(null), [])

  const submitHandler = ({ nativeEvent }) => {
    const form = { latitude: mapCenterLatLng.lat, longitude: mapCenterLatLng.lng }
    const formData = serialize({ trip: updateStep(form) }, { indices: true })
    const nextPath = nativeEvent?.submitter?.value
    dispatch(updateTrip(trip.id, formData)).then(() => navigate(nextPath))
  }

  useEffect(() => {
    return () => setMap(null)
  }, [])

  return (
    <form onSubmit={submitHandler}>
      {isLoaded ? (
        <GoogleMap
          mapContainerClassName="rounded"
          mapContainerStyle={{ height: 330 }}
          options={MAP_OPTIONS}
          center={centerLatLng}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onCenterChanged={centerChangedHandler}
        >
          {mapCenterLatLng && (
            <Marker position={mapCenterLatLng} icon={{ ...MARKER_ICON, scaledSize: new window.google.maps.Size(30, 30) }} />
          )}
          <div className="vstack position-absolute p-10 w-100 h-100 gap-10 pointer-events-none">
            <div className="hstack gap-10">
              <div className="pointer-events-auto position-relative shadow">
                <div className="px-2 position-absolute top-50 start-0 translate-middle-y pointer-events-none">
                  <Icon iconName="Search" size={18} className="text-gray-light" />
                </div>
                <GooglePlacesInput
                  placeholder="Search Location"
                  bsSize="sm"
                  className="py-2 ps-30"
                  onChange={placeChangedHandler}
                  dropDownMenuClassName="mt-n1"
                  resetIfChanged
                />
              </div>
              <div className="pointer-events-auto hstack gap-2 ms-auto">
                <CurrentLocationButton />
                {isChanged && tripLatLng && (
                  <Button color="white" onClick={resetLocation} className="btn-sm p-2 fs-7 shadow">
                    <Icon iconName="Update" size={16} />
                  </Button>
                )}
              </div>
            </div>
            <ZoomButtons />
          </div>
        </GoogleMap>
      ) : (
        <div className="hstack bg-white p-50 rounded" style={{ height: 330 }}>
          <Spinner className="m-auto" />
        </div>
      )}
      <ActionButtons isChanged={isChanged} action={updateTrip} name="trips.trip" className="mt-50" />
    </form>
  )
}

export default Step4Form
