import React, { memo } from "react"
import { useSearchParams } from "react-router-dom"

import moment from "moment"
import { weatherImage } from "helpers/weather"

import { useSelector } from "react-redux"

import { DATE_FORMAT, TIME_FORMAT } from "constants/date"

const CELL_HEIGHT = 60

const Times = ({ day }) => {
  const [searchParams] = useSearchParams()
  const showWeather = searchParams.get("weather") === "true"

  const hourlyForecasts = useSelector((state) => state.weather.hourlyForecasts)
  const hourlyForecast = hourlyForecasts[day?.format(DATE_FORMAT)]

  const classes = ["border-end border-gray-lightest show-on-hover"]

  const numberClasses = ["fs-7 fw-medium mw-100 border-bottom border-gray-lightest bg-white py-1 px-2 sticky-top"]
  if (showWeather) numberClasses.push("py-2")

  return (
    <div className={classes.join(" ")}>
      <div className={numberClasses.join(" ")}>Time</div>
      <div className="grid grid-cols-1 gap-0 px-2" style={{ gridTemplateRows: `repeat(24, ${CELL_HEIGHT}px)` }}>
        {new Array(24).fill().map((_, index) => {
          const time = moment(index, "H").format(TIME_FORMAT)
          const weatherIcon = hourlyForecast?.[time]?.iconCode
          return index ? (
            <div key={index} className="d-flex align-items-end">
              <span className="fs-7 fw-medium mb-n10">
                {time}
                {showWeather && weatherIcon && <img src={weatherImage(weatherIcon)} alt="" height="26" className="mx-n10 mb-n25 d-block" />}
              </span>
            </div>
          ) : null
        })}
      </div>
    </div>
  )
}

export default memo(Times)
