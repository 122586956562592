import React from "react"
import DynamicTag from "components/DynamicTag"

import { useTranslation } from "react-i18next"
import pluralize from "pluralize"

export default function WithEmptyState({
  name,
  tag = "div",
  emptyProps = {},
  hasItems = false,
  children,
  fallback,
  fallbackTitle,
  fallbackText,
  ...rest
}) {
  const { t } = useTranslation()

  const emptyClasses = ["bg-white p-20 rounded"]
  if (emptyProps.className) emptyClasses.push(emptyProps.className)

  return hasItems ? (
    <DynamicTag {...rest} tag={tag || React.Fragment}>
      {children}
    </DynamicTag>
  ) : (
    fallback || (
      <div className={emptyClasses.join(" ")}>
        <h2 className="h3 lh-sm">{fallbackTitle || t("empty_state.title", { name: pluralize(name) })}</h2>
        <p className="text-dark text-opacity-50 mb-0">{fallbackText || t("empty_state.text", { name })}</p>
      </div>
    )
  )
}
