import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, FormGroup, Label, TabContent, TabPane } from "reactstrap"
import Input from "components/form/Input"
import Spinner from "components/common/Spinner"
import Preview from "./Preview"
import Client from "./form/Client"
import GuideCalculator from "./GuideCalculator"
import CalculatedTable from "./CalculationTable"
import Notes from "./form/Notes"
import PastTrips from "./form/PastTrips"
import CanceledBanner from "./form/CanceledBanner"
import Icon from "components/common/Icon"
import InputError from "components/form/InputError"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { numberToCurrency } from "helpers/string"
import { calculateChanges } from "./helpers"
import pluralize from "pluralize"
import useForm, { cleanNestedAttributes } from "hooks/useForm"
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { saveGuideBooking, cancelGuideBooking } from "store/bookings"
import { modelSelector } from "store/selectors"

const InfoMessage = ({ text }) => (
  <div className="hstack gap-2 rounded p-10 bg-light text-dark text-opacity-50">
    <Icon iconName="Info" size={18} className="mt-1 mb-auto flex-shrink-0" block />
    <span>{text}</span>
  </div>
)

const Form = ({ onChanged, edit = false, closeHandler }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const booking = useSelector(modelSelector("booking"))
  const { trip = {} } = booking

  const isBookingPersisted = !!booking?.id
  const [formActive, activateForm] = useState(false)
  const [activeTab, activateTab] = useState("details")
  const [cashPayment, setCashPaymant] = useState(false)
  const [refund, setRefund] = useState(false)
  const [openPreviousDate, setOpenPreviousDate] = useState(null)

  const [form, changeHandler, submitHandler, submitCallback, resetForm, isChanged] = useForm(
    {
      ...booking,
      adult_count: booking.adult_count || 1
    },
    [
      "client",
      "trip_id",
      "booked_date",
      "adult_count",
      "child_count",
      "accessibility_enabled",
      "accessibility_notes",
      "notes",
      "payment_status",
      "cash_deposit"
    ]
  )
  const calculation = calculateChanges(form, booking)
  const calculationsBeforeSave = useRef({})

  const isWithin48Hours = useMemo(
    () => (booking.booked_date ? moment(`${booking.booked_date} ${trip.start_time || "00:00"}`).diff(moment(), "hours") <= 48 : false),
    [booking.booked_date, trip.start_time]
  )
  const isFullCashOnDay = booking.payment_option === "full_amount_in_cash_on_day"
  const confirmChangeTextType =
    (calculation.isGuestsChanged && calculation.isDateChanged && "guests_with_date") ||
    (calculation.isGuestsChanged && "guests") ||
    (calculation.isDateChanged && "date")

  const deleteHandler = confirmModal(
    { title: "Are you sure you want to cancel this Booking?", color: "danger", submitText: t("global.submit") },
    ({ target }) => dispatch(cancelGuideBooking(target.value)).then(closeHandler)
  )

  const reset = () =>
    resetForm() ||
    setCashPaymant(false) ||
    setOpenPreviousDate(null) ||
    setRefund(false) ||
    activateForm(false) ||
    activateTab("details") ||
    (calculationsBeforeSave.current = {})
  const toggleEdit = () => activateForm((prev) => (prev && reset()) || !prev)

  const confirmChanges = (withRefund) => {
    calculationsBeforeSave.current = calculation
    setRefund(withRefund)
    submitHandler()
  }

  submitCallback(() => {
    const trip_booking = { ...form, client_attributes: form.client, notes_attributes: cleanNestedAttributes(form.notes, ["content"]) }
    delete trip_booking.notes
    delete trip_booking.client
    if (edit) delete trip_booking.client_attributes

    const formData = serialize({ trip_booking }, { indices: true })

    dispatch(saveGuideBooking(booking.id, formData)).then(
      () => (activeTab === "details" && closeHandler()) || (activeTab === "confirm" && activateTab("success"))
    )
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  useEffect(() => {
    if (isFullCashOnDay) setCashPaymant(true)
  }, [isFullCashOnDay])

  return (
    <FirstLoading name="bookings.booking" new>
      <TabContent activeTab={activeTab} className="w-100">
        <TabPane tabId="details">
          <form onSubmit={submitHandler} className="position-relative">
            <CanceledBanner status={booking.status} className="mb-20" />
            <div className="vstack gap-20">
              {edit && !formActive ? (
                <Preview form={form} changeHandler={changeHandler} isChanged={isChanged} />
              ) : (
                <>
                  <div className="hstack align-items-center gap-20 bg-white rounded p-20">
                    <h1 className="h3 fw-medium lh-sm">{t(`bookings.${isBookingPersisted ? "edit" : "add"}`)}</h1>
                    <div className="hstack gap-10 my-n1 ms-auto">
                      {edit && (
                        <Button color="light" className="fs-7" type="button" onClick={toggleEdit}>
                          {t("global.go_back")}
                        </Button>
                      )}
                      {calculation.isGuestsChanged || calculation.isDateChanged ? (
                        <Button
                          color="primary"
                          className="fs-7"
                          disabled={!isChanged || !calculation.guests}
                          onClick={() => activateTab("confirm")}
                        >
                          {t("global.next")}
                        </Button>
                      ) : (
                        <Button color="primary" className="fs-7" disabled={!isChanged}>
                          {t("global.submit")}
                        </Button>
                      )}
                    </div>
                  </div>

                  {!edit && <Client form={form} changeHandler={changeHandler} />}
                  <InputError field="client_id" className="my-2 " />
                  <GuideCalculator
                    form={form}
                    changeHandler={changeHandler}
                    edit={edit}
                    current_booked_date={booking?.booked_date}
                    calculation={calculation}
                  />
                  {calculation.isGuestsChanged && <CalculatedTable calculation={calculation} />}
                  <div className="vstack gap-15 bg-white rounded p-20">
                    <FormGroup tag="label" switch className="hstack justify-content-between m-0 me-auto p-0 cursor-pointer">
                      <Label for="accessibility_enabled" className="fs-6 m-0 cursor-pointer">
                        {t("booking.labels.accessibility_required")}
                      </Label>
                      <Input
                        id="accessibility_enabled"
                        name="accessibility_enabled"
                        type="checkbox"
                        checked={[true, "true"].includes(form.accessibility_enabled)}
                        onChange={changeHandler}
                        className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary m-0 ms-20 cursor-pointer"
                      />
                    </FormGroup>
                    {form.accessibility_enabled && (
                      <Input
                        type="textarea"
                        name="accessibility_notes"
                        placeholder={t("booking.labels.accessibility_notes")}
                        value={form.accessibility_notes || ""}
                        onChange={changeHandler}
                        className="fs-6"
                        withError
                      />
                    )}
                  </div>
                </>
              )}

              {(!edit || booking.id) && (
                <Notes form={form} changeHandler={changeHandler} isChanged={isChanged} formActive={formActive} edit={edit} />
              )}

              {booking.id && (
                <div className="vstack gap-20">
                  <PastTrips className={formActive ? "d-none" : ""} />
                  {booking.status !== "canceled" &&
                    (formActive ? (
                      <button
                        type="button"
                        className="link link-dark link-opacity-50 fs-6 fw-medium ms-auto"
                        value={booking.id}
                        onClick={deleteHandler}
                      >
                        {t("global.cancel")}
                      </button>
                    ) : (
                      <button type="button" className="link link-dark link-opacity-50 fs-6 fw-medium ms-auto" onClick={toggleEdit}>
                        {t("global.edit")}
                      </button>
                    ))}
                </div>
              )}
            </div>

            {booking.loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100" absolute />}
          </form>
        </TabPane>

        <TabPane tabId="confirm">
          <form onSubmit={submitHandler}>
            <div className="bg-white rounded p-20">
              <Button onClick={() => activateTab("details")} color="ghost" className="p-2 my-n2 d-block">
                <Icon iconName="ArrowLeft" size={18} block />
              </Button>
              <h1 className="h3 fw-medium lh-sm my-20">
                {confirmChangeTextType &&
                  t(`booking.confirm_change_title.${confirmChangeTextType}`, {
                    text:
                      calculation.guestsDiff > 0
                        ? `add ${pluralize("preson", calculation.guestsDiff, true)} to`
                        : `remove ${pluralize("preson", -calculation.guestsDiff, true)} from`,
                    date: form.booked_date
                  })}
              </h1>
              {calculation.guestsDiff < 0 && (
                <InfoMessage
                  text={t(`booking.confirm_change_text.${booking.payment_option}${isWithin48Hours ? "_in_48h" : ""}`, {
                    refund: numberToCurrency(calculation.totalPriceDiff),
                    new_total: numberToCurrency(calculation.totalPrice)
                  })}
                />
              )}
              {calculation.guestsDiff > 0 && (
                <>
                  <CalculatedTable calculation={calculation} noPading />
                  <FormGroup
                    tag="label"
                    switch
                    className={[
                      "hstack justify-content-between my-30 p-0",
                      isFullCashOnDay ? "pointer-events-none opacity-50" : "cursor-pointer"
                    ].join(" ")}
                    style={{ maxWidth: 250 }}
                  >
                    <Label htmlFor="cashPayment" className="fs-6 m-0 cursor-pointer">
                      {"Cash Payment Allowed"}
                    </Label>
                    <Input
                      id="cashPayment"
                      type="checkbox"
                      checked={cashPayment}
                      onChange={() => setCashPaymant(!cashPayment)}
                      className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary m-0 ms-20 cursor-pointer"
                      disabled={isFullCashOnDay}
                    />
                  </FormGroup>
                </>
              )}
              {calculation.isDateChanged && (
                <div className="bg-white rounded mt-30">
                  <Label className="fs-6 gap-20">
                    {t("booking.confirm_change_title.open_previous_date", { prev_date: booking.booked_date })}
                  </Label>
                  <div className="hstack gap-30">
                    <Label check className="hstack gap-10 d-inline-flex fs-7 fw-normal">
                      <Input
                        type="radio"
                        name="open_previous_date"
                        value={true}
                        checked={openPreviousDate === true}
                        onChange={() => setOpenPreviousDate(true)}
                        className="mt-0 cursor-pointer"
                      />
                      <span className={`text-dark ${openPreviousDate === true ? "" : "text-opacity-50"}`}>Yes</span>
                    </Label>
                    <Label check className="hstack gap-10 d-inline-flex fs-7 fw-normal">
                      <Input
                        type="radio"
                        name="open_previous_date"
                        value={false}
                        checked={openPreviousDate === false}
                        onChange={() => setOpenPreviousDate(false)}
                        className="mt-0 cursor-pointer"
                      />
                      <span className={`text-dark ${openPreviousDate === false ? "" : "text-opacity-50"}`}>No</span>
                    </Label>
                  </div>
                </div>
              )}
              <div className="hstack gap-10 mt-30">
                <Button
                  color="primary"
                  className="fs-7"
                  type="button"
                  disabled={!isChanged}
                  onClick={() => confirmChanges(!isWithin48Hours && calculation.guestsDiff < 0)}
                >
                  {"Confirm Changes"}
                </Button>
                {isWithin48Hours && calculation.guestsDiff < 0 && (
                  <Button
                    color="primary"
                    className="fs-7 text-white-hover"
                    outline
                    type="button"
                    disabled={!isChanged}
                    onClick={() => confirmChanges(true)}
                  >
                    {"Confirm Changes & Refund"}
                  </Button>
                )}
              </div>
            </div>

            {booking.loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100" absolute />}
          </form>
        </TabPane>

        <TabPane tabId="success">
          <div className="align-items-center bg-white rounded p-20">
            <h1 className="h3 fw-medium lh-sm mb-20">{t(`booking.success_change_title`)}</h1>
            <div className="vstack gap-2 my-20">
              {calculationsBeforeSave.current.guestsDiff > 0 && booking.payment_option && (
                <InfoMessage
                  text={t(
                    `booking.success_change_text.${booking.payment_option}${
                      (!isFullCashOnDay && (cashPayment ? "_cash" : "_nocash")) || ""
                    }`,
                    {
                      record: numberToCurrency(calculationsBeforeSave.current.totalPriceDiff),
                      new_total: numberToCurrency(calculationsBeforeSave.current.totalPrice),
                      date: booking.booked_date
                    }
                  )}
                />
              )}

              {calculationsBeforeSave.current.guestsDiff < 0 && <InfoMessage text={t("booking.success_change_text.remove")} />}
              {calculationsBeforeSave.current.guestsDiff < 0 &&
                (refund ? (
                  <InfoMessage
                    text={t(`booking.success_change_text.${isFullCashOnDay ? "recalc" : "refund"}`, {
                      refund: numberToCurrency(-calculationsBeforeSave.current.totalPriceDiff),
                      new_total: numberToCurrency(calculationsBeforeSave.current.totalPrice)
                    })}
                  />
                ) : (
                  <InfoMessage text={t("booking.success_change_text.no_refund")} />
                ))}

              {calculationsBeforeSave.current.isDateChanged && (
                <InfoMessage text={t("booking.success_change_text.new_date", { new_date: form.booked_date })} />
              )}
            </div>

            <Button color="primary" className="fs-7" onClick={toggleEdit}>
              {t("global.ok")}
            </Button>
          </div>
        </TabPane>
      </TabContent>
    </FirstLoading>
  )
}

export default Form
