import React, { forwardRef, memo, useMemo, useRef } from "react"
import { DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"
import Input from "components/form/Input"

import { get } from "lodash"

const suggestionValue = (item, valueKey) => (item instanceof Object ? get(item, valueKey, ">> set 'valueKey' <<") : item)

const AutofillInput = forwardRef(
  ({ name, onChange, addHandler, removeHandler, suggestions = {}, dropdownProps = {}, ...rest }, inputRef) => {
    const { list = [], selected = [], valueKey, withSearch = false } = suggestions
    const value = rest.value || rest.defaultValue || ""

    const ref = useRef(null)
    const suggestionsFiltered = useMemo(
      () =>
        list.filter((item) => {
          const suggestion = suggestionValue(item, valueKey)
          const isSelected = selected.includes(suggestion) && suggestion !== value
          return (withSearch ? suggestion.toLowerCase().match(new RegExp(value.toLowerCase(), "g")) : true) && !isSelected
        }),
      [list, valueKey, selected, value, withSearch]
    )

    const openHandler = () => ref.current && ref.current.toggle()

    return (
      <UncontrolledDropdown direction="down" {...dropdownProps} ref={ref}>
        <Input name={name} onChange={onChange} {...rest} onClick={openHandler} ref={inputRef} />
        <DropdownToggle className="position-absolute top-0 start-0 h-100 w-100 p-0 border-0 opacity-0 pointer-events-none" />
        {suggestionsFiltered.length > 0 && (
          <DropdownMenu container="body">
            {suggestionsFiltered.map((autofillItem, index) => {
              const suggestion = suggestionValue(autofillItem, valueKey)
              const suggestionMatched = suggestion.match(new RegExp(value.toLowerCase(), "i"))?.[0] || ""
              const [first, second, third] = suggestion.replace(suggestionMatched, `%%${suggestionMatched}%%`).split("%%")
              return (
                <DropdownItem
                  key={index}
                  name={name}
                  value={suggestion}
                  onClick={onChange}
                  className="text-wrap"
                  active={value === suggestion}
                >
                  {first}
                  {second && (
                    <span
                      className={`bg-primary-second pointer-events-none ${
                        value === suggestion ? "text-white bg-opacity-0" : "text-dark bg-opacity-25"
                      }`}
                    >
                      {second}
                    </span>
                  )}
                  {third}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    )
  }
)

export default memo(AutofillInput)
